import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerApis } from 'src/app/api.constants';
import { TransportService } from '../transport/transport.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private transportService: TransportService, private httpClient: HttpClient) { }



  getAccountDetails() {
    return this.transportService.Read(`${ServerApis.accountUrl}`);
  }

  getAccountById(id: string) {
    return this.transportService.Read(`${ServerApis.accountUrl}/${id}`);
  }

  createAccount(req) {
    return this.transportService.Create(req, ServerApis.accountUrl);
  }

  updateAccount(id: string, data) {
    return this.httpClient.patch(`${ServerApis.accountUrl}/${id}`, data);
  }



  deleteAccount(id: string) {
    return this.transportService.Delete(`${ServerApis.accountUrl}/${id}`);
  }



}
