export class User {
    public token: string;
    public loggedInUserInfo: string;
    public role: string;
    public tokenExpirationDate: Date;


    constructor(token: string, loggedInUserInfo: string, role: string, tokenExpirationDate: Date) {
        this.token = token;
        this.loggedInUserInfo = loggedInUserInfo;
        this.role = role;
        this.tokenExpirationDate = tokenExpirationDate;
    }
}