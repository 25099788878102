import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/models/Customer';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { ExportTableDataService } from 'src/app/services/export-table-data/export-table-data.service';
import { PaymentMetohdType } from 'src/app/types/PaymentMethodType';
import Swal from 'sweetalert2';
import { AddEditBusinessPartyComponent } from '../business-party/add-edit-business-party/add-edit-business-party.component';
import { AddEditCustomerComponent } from './add-edit-customer/add-edit-customer.component';
import { AddModifyPayableComponent } from './add-modify-payable/add-modify-payable.component';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit {
  public customerDetails: Customer[] = [];
  page: number = 1;
  noOfRecordsSizesArr = [10, 20, 30];
  pageNo = 0;
  pageSize = 10;
  totalCount = 0;
  public searchedValues: string;
  displayLoader: boolean = true;
  dispalyNotFoundData: boolean = false;
  getPaymentMethodValue: string;

  constructor(
    private customerService: CustomerService,
    private exportTableDataService: ExportTableDataService,
    private toastr: ToastrService,
    private modalService: NgbModal,
  ) {}

  getAllCustomer() {
    this.customerService
      .getCustomerByPagination(this.pageSize, this.pageNo)
      .subscribe(
        (customerDatas: any) => {
          const paginationDetails = customerDatas.pagination;
          this.page = paginationDetails.page + 1;
          this.totalCount = this.pageSize * paginationDetails.lastPage;
          this.customerDetails = customerDatas.customers;
          if (this.customerDetails?.length === 0) {
            this.displayLoader = false;
            this.dispalyNotFoundData = true;
          }
        },
        (error) => {
          this.toastr.error(
            error.error.message || 'Something went wrong!',
            'Error',
            {
              progressBar: true,
            },
          );
          this.displayLoader = false;
          this.dispalyNotFoundData = true;
        },
      );
  }

  addCustomer() {
    const modalRef = this.modalService.open(AddEditCustomerComponent);
    modalRef.componentInstance.isAddMode = true;
    modalRef.result.then(() => {
      this.getAllCustomer();
    });
  }

  editCustomer(id: string) {
    const modalRef = this.modalService.open(AddEditCustomerComponent);
    modalRef.componentInstance.customerId = id;
    modalRef.componentInstance.isEditMode = true;
    modalRef.result.then(() => {
      this.getAllCustomer();
    });
  }

  modifyPayable(customerId: string) {
    const modifyPayableModalRef = this.modalService.open(
      AddModifyPayableComponent,
    );
    modifyPayableModalRef.componentInstance.payableCustomerId = customerId;
    modifyPayableModalRef.result.then(() => {
      this.getAllCustomer();
    });
  }

  deleteCustomer(customerId) {
    Swal.fire({
      title: 'Are you sure?',
      // text: 'You will not be able to revert this process!',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'DELETE',
      cancelButtonText: 'CANCEL',
    }).then((result) => {
      if (result.isConfirmed) {
        this.customerService.deleteCustomer(customerId).subscribe(
          (res: any) => {
            Swal.fire('Deleted!', res.message, 'success');
            this.getAllCustomer();
          },
          (error) => {
            Swal.fire('Error', error.error.message, 'error');
          },
        );
      }
    });
  }

  exportAsXLSX(): void {
    this.exportTableDataService.exportAsExcelFile(
      this.customerDetails,
      'customer-details',
    );
  }

  searchCustomerByPamentMethod(event: any) {
    this.getPaymentMethodValue = event.target.value;
    if (
      this.getPaymentMethodValue === PaymentMetohdType.All ||
      this.getPaymentMethodValue === PaymentMetohdType.Completed ||
      this.getPaymentMethodValue === PaymentMetohdType.Credit
    ) {
      this.customerService
        .searchCustomerData(
          this.pageSize,
          this.pageNo,
          this.searchedValues,
          this.getPaymentMethodValue,
        )
        .subscribe((searchedData: any) => {
          const paginationDetails = searchedData.pagination;
          this.page = paginationDetails.page + 1;
          this.totalCount = this.pageSize * paginationDetails.lastPage;
          this.customerDetails = searchedData.customers;
          if (this.customerDetails?.length === 0) {
            this.displayLoader = false;
            this.dispalyNotFoundData = true;
          }
        });
    } else {
      this.getAllCustomer();
    }
  }

  getValue(event: any) {
    this.searchedValues = event.target.value;
    if (this.searchedValues.length >= 2) {
      this.customerService
        .searchCustomerData(
          this.pageSize,
          this.pageNo,
          this.searchedValues,
          this.getPaymentMethodValue,
        )
        .subscribe((data: any) => {
          const paginationDetails = data.pagination;
          this.page = paginationDetails.page + 1;
          this.totalCount = this.pageSize * paginationDetails.lastPage;
          this.customerDetails = data.customers;
          if (this.customerDetails?.length === 0) {
            this.displayLoader = false;
            this.dispalyNotFoundData = true;
          }
        });
    } else if (this.searchedValues === '') {
      this.customerService
        .searchCustomerData(
          this.pageSize,
          this.pageNo,
          this.searchedValues,
          this.getPaymentMethodValue,
        )
        .subscribe((data: any) => {
          const paginationDetails = data.pagination;
          this.page = paginationDetails.page + 1;
          this.totalCount = this.pageSize * paginationDetails.lastPage;
          this.customerDetails = data.customers;
          if (this.customerDetails?.length === 0) {
            this.displayLoader = false;
            this.dispalyNotFoundData = true;
          }
        });
    }
  }
  noOfRecordsSizeChange(): void {
    this.getAllCustomer();
  }

  pageChange(event) {
    this.page = event;
    this.pageNo = this.page - 1;
    this.getAllCustomer();
  }

  ngOnInit(): void {
    this.getAllCustomer();
  }
}
