<div class="modal-section">
    <div class="modal-header bg-custom">
        <h5 class="modal-title text-white"><i class="fa fa-product-info"></i>
            <span>
                View Product Price
            </span>
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="modal-body mx-4">
            <div class="col-md-12 table-responsive card">
                <table class="table table-hover text-center table-striped table-vcenter text-nowrap mb-0">
                    <thead class="thead-dark">
                        <tr>
                            <th i18n="@@sn">S.N</th>
                            <th i18n="@@party">Party</th>
                            <th i18n="@@itemName">Rate</th>
                            <th i18n="@@itemPrice">Quantity</th>
                            <th i18n="@@billNo">Bill No.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let productPriceDetailsInfo of productPriceDetails; index as i">
                            <td>{{i+1}}</td>
                            <td>{{productPriceDetailsInfo.partyName}}</td>
                            <td>Rs. {{ productPriceDetailsInfo.rate ? productPriceDetailsInfo.rate: 'N/A'
                                }}
                            </td>
                            <td>{{ productPriceDetailsInfo.quantity ? productPriceDetailsInfo.quantity: 'N/A'}}
                            </td>
                            <td>{{productPriceDetailsInfo.billNo}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <div class="col-sm-12">
                    <button type="reset" class="w-100 btn btn-danger mt-2" i18n="@@Close" (click)="closeModal()">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>