<div class="container-fluid">
  <div class="row justify-content-between align-items-center" id="breadcumb-section-part">
    <div class="col-md-6 header-action d-flex">
      <h1 class="page-title" i18n="@@partyPurchse">Add Purchase Invoice</h1>
      <ol class="breadcrumb page-breadcrumb mt-2">
        <li class="breadcrumb-item">
          <a [routerLink]="['/pages/dashboard']">( Dashboard )</a>
        </li>
      </ol>
    </div>

    <div class="col-md-6 search-and-btns">
      <ul class="nav nav-tabs page-header-tab" id="page-header-update">
        <li class="nav-item">
          <a id="partyPurchase-all-Link" class="nav-link partyPurchaseListViewTab active" data-toggle="tab"
            [routerLink]="['/pages/partyPurchase/']" i18n="@@listOfPartyPurchase"><i class="fa fa-list"></i>List
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="section-body mt-2 mb-5 ml-4 mr-4">
  <div class="card">
    <form [formGroup]="partyPurchaseForm" (ngSubmit)="onSubmit()">
      <div class="card-body">
        <div class="row clearfix">
          <div class="form-group col-sm-4 mb-3">
            <label for="privilege" i18n="@@party"><b>Party:</b><span class="text-danger">*</span></label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-users" aria-hidden="true"></i>
                </div>
              </div>
              <select i18n-placeholder="@@party" class="form-control" name="bussinessParty"
                formControlName="bussinessParty">
                <option value="" disabled="true">Select Bussiness Party</option>
                <option *ngFor="let businessPartyInfos of businessParty" [id]="businessPartyInfos.id"
                  [value]="businessPartyInfos.id">
                  {{ businessPartyInfos.name }}
                </option>
              </select>
            </div>

            <small class="form-text text-danger"
              *ngIf="partyPurchaseForm.controls.bussinessParty.hasError('required') && partyPurchaseForm.controls.bussinessParty.touched"
              style="margin-top: -0.5rem;">
              Business party is required!
            </small>
          </div>

          <div class="form-group col-sm-4 mb-3">
            <label for="privilege" i18n="@@billNumber"><b>Bill Number:</b><span class="text-danger">*</span></label>
            <br>

            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-file-text" aria-hidden="true"></i>
                </div>
              </div>
              <input type="text" name="billNo" class="form-control" formControlName="billNo"
                placeholder="Enter bill number">
            </div>
            <small class="form-text text-danger"
              *ngIf="partyPurchaseForm.controls.billNo.hasError('required') && partyPurchaseForm.controls.billNo.touched"
              style="margin-top: -0.5rem;">
              Bill number is required!
            </small>

          </div>


          <div class="form-group col-sm-4 mb-3">
            <label for="privilege" i18n="@@bussinessDate"><b>Business Date:</b><span
                class="text-danger">*</span></label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i>
                </div>
              </div>
              <input type="date" class="form-control" name="bussinessDate" formControlName="bussinessDate"
                i18n-placeholder placeholder="Enter business Date">
            </div>
            <small class="form-text text-danger"
              *ngIf="partyPurchaseForm.controls.bussinessDate.hasError('required') && partyPurchaseForm.controls.bussinessDate.touched"
              style="margin-top: -0.5rem;">
              Business date is required!
            </small>
          </div>

          <hr>

          <div class="col-sm-12 mb-3">
            <div formGroupName="productPurchases">
              <div class="panel-heading text-bold">
                <strong>Details of Product Items</strong>
                <button type="button" class="btn btn-primary btn-sm float-right mb-1"
                  (click)="addProductInfo()"><b>Create
                    Product</b></button>

              </div>

              <div class="table-responsive" *ngIf="partyPurchaseForm.controls.productPurchases!=null">
                <table class="table table-bordered" id="tab_logic">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col" i18n="@@sn">S.N</th>
                      <th scope="col" i18n="@@selectProduct"><strong>Select Product </strong> <span
                          class="text-danger">*</span>
                      </th>
                      <th scope="col" i18n="@@rate"><strong>Rate</strong></th>

                      <th scope="col" i18n="@@quantity"><strong>Quantity</strong><span class="text-danger">*</span>
                      </th>
                      <th scope="col" i18n="@@totalPrice"><strong>Total Price</strong></th>
                      <th scope="col" colspan="2" style="padding: 5px !important;"><strong>Actions</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let itemRow of partyPurchaseForm.controls.productPurchases.controls; let i=index"
                      [formGroupName]="i" id='addr{{i}}'>
                      <td>{{i+1}}</td>
                      <td style="width: 28%;">
                        <div style="width:90%" (click)="refreshProductData()">
                          <ng-multiselect-dropdown [placeholder]="'Select products'" name="productsData[]"
                            id="productData{{i}}" formControlName="product" [settings]="product" [data]="products">
                          </ng-multiselect-dropdown>
                        </div>

                      </td>

                      <td style="width: 20%;">
                        <input class="form-control rate" data-type="rate" formControlName="rate" min="0" type="number"
                          id='rate_{{i}}' name="rate[]" for="{{i}}" placeholder="Enter rate" i18n-placeholder="@@rate"
                          (input)="getValues()">
                      </td>
                      <td style="width: 20%;">
                        <input class="form-control quantity" data-type="quantity" type="number" min="0"
                          id='quantity_{{i}}' name="quantity[]" for="{{i}}" i18n-placeholder="@@itemQuantity"
                          placeholder="Enter quantity" name="quantity" formControlName="quantity" (input)="getValues()"
                          required>
                      </td>


                      <td>
                        <input class="form-control total_cost" type='text' id='total_cost_{{i}}' name='total_cost[]'
                          for='{{i}}' readonly />
                      </td>

                      <td>
                        <button type="button" (click)="addNewRow()" class="btn btn-sm btn-primary">
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>
                      </td>

                      <td scope="col" i18n="@@deleteRow">
                        <button type="button" (click)="deleteRow(i)" class="btn btn-sm btn-danger">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>

                </table>

              </div>
            </div>
          </div>

          <div class="d-flex w-100" style="background: #e6e7e771;">
            <div class="row" style="padding: 15px">
              <div class="col-md-12">
                <label for="privilege" i18n="@@remarks"><b>Remarks:</b></label> <br>

                <div class="input-group mb-2">
                  <textarea id="remarks" class="form-control" formControlName="remarks" placeholder="Enter remarks"
                    name="remarks" rows="7" cols="100"></textarea>
                </div>
              </div>
            </div>

            <div class="row ml-auto" style="padding: 15px">
              <div class="col-md-8  ml-auto">
                <div class="form-group d-flex float-right">
                  <label class="mt-2 mr-2"><b>Sub Total: </b></label>
                  <td><input class="form-control subTotal" type='text' id='subTotal' formControlName="subTotalAmount"
                      name='subTotal' readonly style="background-color:#FFFFFF; cursor: not-allowed;"></td>
                </div>
              </div>

              <div class="col-md-8 ml-auto" id="vatAmount">
                <div class="form-group d-flex float-right">
                  <label class="mt-2 mr-2" style="margin-left: -8rem;"><b>Vat Amount:
                    </b></label>
                  <td><input class="form-control vatAmount" type='number' id='vatAMount' min="0"
                      formControlName="vatAmount" name='vatAMount' (input)="getTotalAmountAfterVat($event)" />

                    <small class="form-text text-danger"
                      *ngIf="partyPurchaseForm.controls.vatAmount.hasError('min') && partyPurchaseForm.controls.vatAmount.touched"
                      style="margin-top: 5px;">
                      Vat amount should be positive number!
                    </small>
                  </td>

                </div>
              </div>

              <div class="col-md-8  ml-auto">
                <div class="form-group d-flex float-right">
                  <label class="mt-2" style="margin-left: -20px; margin-right: 5px;"><b>Total Amount: </b></label>
                  <td><input class="form-control totalAmount" type='number' id='totalAmount'
                      formControlName="totalAmount" name='totalAmount' readonly
                      style="background-color:#FFFFFF; cursor: not-allowed;"></td>
                </div>
              </div>
            </div>
          </div>
          <hr>

          <!-- <h5>Purchase Transaction</h5> <br> -->
          <div class="form-group col-sm-6 mb-3 mt-3">
            <label for="privilege" i18n="@@billNumber"><b>Select Payment Status</b><span
                class="text-danger">*</span></label>
            <br>

            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-file-text" aria-hidden="true"></i>
                </div>
              </div>
              <select class="form-control" name="paymentStatus" formControlName="paymentStatus"
                (change)="getSelectedPaymentSatatus($event)">
                <option value="no_payment">No Payment</option>
                <option value="partial_payment">Partial Payment</option>
                <option value="full_payment">Full Payment</option>
              </select>
            </div>
          </div>

          <div class="form-group col-sm-6 mb-3 mt-3" *ngIf="showTransactionAmountInput">
            <label for="privilege" i18n="@@transactionAmount"><b>Transaction Amount:</b><span
                class="text-danger">*</span></label>
            <br>

            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-file-text" aria-hidden="true"></i>
                </div>
              </div>
              <input type="number" name="transactionAmount" class="form-control" formControlName="transactionAmount"
                placeholder="Enter transaction amount">
            </div>
            <small class="form-text text-danger"
              *ngIf="partyPurchaseForm.controls.transactionAmount.hasError('reuired') && partyPurchaseForm.controls.transactionAmount.touched"
              style="margin-top: -0.5rem;">
              Transaction amount required!
            </small>
            <small class="form-text text-danger"
              *ngIf="partyPurchaseForm.controls.transactionAmount.hasError('min') && partyPurchaseForm.controls.transactionAmount.touched"
              style="margin-top: -0.5rem;">
              Transaction amount should be at least 10!
            </small>
          </div>

          <div class="form-group col-sm-6 mb-3 mt-3">
            <label for="privilege" i18n="@@billNumber"><b>Select Payment Method</b><span
                class="text-danger">*</span></label>
            <br>

            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-file-text" aria-hidden="true"></i>
                </div>
              </div>
              <select class="form-control" name="paymentMethod" formControlName="paymentMethod">
                <option value="Cash">Cash</option>
                <option value="Web">Web</option>
                <option value="Check">Cheque</option>
              </select>
            </div>
            <small class="form-text text-danger"
              *ngIf="partyPurchaseForm.controls.paymentMethod.hasError('required') && partyPurchaseForm.controls.paymentMethod.touched"
              style="margin-top: -0.5rem;">
              Payment method is required!
            </small>

          </div>

          <div class="form-group col-sm-6 mb-3">
            <label for="privilege" i18n="@@transactionDate"><b>Transaction Date:</b><span
                class="text-danger">*</span></label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i>
                </div>
              </div>
              <input type="date" class="form-control" name="transactionDate" formControlName="transactionDate"
                i18n-placeholder placeholder="Enter transaction Date">
            </div>
            <small class="form-text text-danger"
              *ngIf="partyPurchaseForm.controls.transactionDate.hasError('required') && partyPurchaseForm.controls.transactionDate.touched"
              style="margin-top: -0.5rem;">
              Transaction date is required!
            </small>
          </div>

          <div class="col-md-12">
            <label for="privilege" i18n="@@remarks"><b>Remarks:</b></label> <br>

            <div class="input-group mb-2">
              <textarea id="transactionRemarks" class="form-control" formControlName="transactionRemarks"
                placeholder="Enter remarks" name="transactionRemarks" rows="7" cols="100"></textarea>
            </div>
          </div>


          <div class="col-sm-6">
            <button *ngIf="isAddMode" type="submit" class="w-100 btn btn-primary mr-2" i18n="@@submit"
              [disabled]="submitted || !partyPurchaseForm.valid">Submit <span class="spinner-grow spinner-grow-sm"
                aria-hidden="true" *ngIf="this.loading"></span></button>
            <button *ngIf="!isAddMode" type="submit" class="w-100 btn btn-primary mr-2" i18n="@@update"
              [disabled]="submitted || !partyPurchaseForm.valid">Update <span class="spinner-grow spinner-grow-sm"
                aria-hidden="true" *ngIf="this.loading"></span></button>
          </div>

          <div class="col-sm-6">
            <button type="reset" class="w-100 btn btn-danger" i18n="@@cancel" (click)="cancel()">Cancel</button>
          </div>
        </div>

      </div>



    </form>

  </div>
</div>