import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDistance, subDays, subMonths } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { BusinessParty } from 'src/app/models/BusinessParty';
import { PartyPurchase } from 'src/app/models/PartyPurchas';
import { PartyPurchaseTransaction } from 'src/app/models/PartyPurchaseTransaction';
import { BusinessPartyService } from 'src/app/services/business-party/business-party.service';
import { PartyPurchaseTransactionService } from 'src/app/services/party-purchase-transactions/party-purchase-transaction.service';
import { PartyPurchaseService } from 'src/app/services/party-purchase/party-purchase.service';
import { AddPartyTransactionComponent } from '../add-party-transaction/add-party-transaction.component';
import { ViewPartyTransactionByIdComponent } from '../view-party-transaction-by-id/view-party-transaction-by-id.component';

@Component({
  selector: 'app-view-business-party-transactions',
  templateUrl: './view-business-party-transactions.component.html',
  styleUrls: ['./view-business-party-transactions.component.scss']
})
export class ViewBusinessPartyTransactionsComponent implements OnInit {

  public businessPartyId: string;
  public businessPartyPayableData: BusinessParty;
  public partyPurchaseTransations: PartyPurchaseTransaction[] = [];
  public partyPurchaseInvoice: PartyPurchase[] = [];
  public transactionAmountOfPartyPurchase: number;
  public totalCalulatedAmountOfPartyInvoice: number;
  page: number = 1;
  noOfRecordsSizesArr = [10, 20, 30];
  pageNo = 0;
  pageSize = 10;
  totalCount = 0;
  public searchedTransactionValues: string;
  public searchedPartyInvoiceValues: string;
  public startDateValue: string;
  public endDateValue: string;
  currentDate = new Date().toISOString().substring(0, 10);
  previousDate = subMonths(new Date(), 1).toISOString().substring(0, 10);
  dateForm: FormGroup;
  displayLoader: boolean = true;
  dispalyNotFoundData: boolean = false;
  disableTranscationButton: boolean = false;

  constructor(private route: ActivatedRoute, private businessPartService: BusinessPartyService,
    private partyPurchaseTrasactionService: PartyPurchaseTransactionService,
    private FormBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private router: Router
  ) { }


  addPartyTransaction(id: string) {
    const partyTransactionRef = this.modalService.open(AddPartyTransactionComponent);
    partyTransactionRef.componentInstance.partyTransactionId = this.businessPartyId
    partyTransactionRef.result.then(() => {
      this.fetchPartyPaymentsDetails();
    })

  }





  // Party Invoice Detials
  getStartDateValueOfInvoice(event: any) {
    this.startDateValue = event.target.value;
    this.fetchPartyPurchaseInvoiceDetails();
  }
  getEndDateValueOfInvoice(event: any) {
    this.endDateValue = event.target.value
    this.fetchPartyPurchaseInvoiceDetails();
  }

  getValueOfPartyPurchaseInvoice(event: any) {
    this.searchedPartyInvoiceValues = event.target.value;
    this.fetchPartyPurchaseInvoiceDetails();
  }


  public async fetchPartyPurchaseInvoiceDetails(): Promise<void> {
    if (this.searchedPartyInvoiceValues == '' || this.searchedPartyInvoiceValues.length >= 2) {
      this.partyPurchaseTrasactionService.searchPartyPurchaseInvoiceByDate(this.pageSize, this.pageNo, this.searchedPartyInvoiceValues, this.startDateValue, this.endDateValue, this.businessPartyId).subscribe((partyPurhcaseInvoiceData: any) => {
        const paginationDetails = partyPurhcaseInvoiceData.pagination;
        this.page = paginationDetails.page + 1;
        this.totalCount = this.pageSize * paginationDetails.lastPage;
        this.partyPurchaseInvoice = partyPurhcaseInvoiceData.partyPurchaseInvoices;
        const filteredTotalAmountOfPartyInvoice = this.partyPurchaseInvoice.map((amountOfPartyInvoiceData: any) => {
          let totalFilteredAmountOfPartyInvoice = +amountOfPartyInvoiceData.totalAmount;
          return totalFilteredAmountOfPartyInvoice;
        })
        const arrayOfFilteredPartyInvoiceAmount = filteredTotalAmountOfPartyInvoice;
        this.totalCalulatedAmountOfPartyInvoice = arrayOfFilteredPartyInvoiceAmount.reduce((invoiceAccumulator, invoiceCurrent) => {
          return invoiceAccumulator + invoiceCurrent;
        }, 0)

        if (this.partyPurchaseInvoice.length === 0) {
          this.displayLoader = false;
          this.dispalyNotFoundData = true;

        }

      }, (error) => {
        this.toastr.error(error.error.message || 'Something went wrong!', 'Error', {
          progressBar: true,
        });
        this.displayLoader = false;
        this.dispalyNotFoundData = true;
      })
    }
  }


  // End of Invoice Details


  // Party Transactions Details
  getValueOfPartyPurchaseTransaction(event: any) {
    this.searchedTransactionValues = event.target.value;
    this.fetchPartyPaymentsDetails();
  }

  getStartDateValue(event: any) {
    this.startDateValue = event.target.value;
    this.fetchPartyPaymentsDetails();
  }

  getEndDateValue(event: any) {
    this.endDateValue = event.target.value
    this.fetchPartyPaymentsDetails();
  }

  public async fetchPartyPaymentsDetails(): Promise<void> {
    this.businessPartService.getBusinessPartyById(this.businessPartyId).subscribe((getBusinessPartyPayableAmount: any) => {
      this.businessPartyPayableData = getBusinessPartyPayableAmount;
    })

    if (this.searchedTransactionValues == '' || this.searchedTransactionValues.length >= 2) {
      this.partyPurchaseTrasactionService.searchPartyPurchaseTransactionsByDate(this.pageSize, this.pageNo, this.searchedTransactionValues, this.startDateValue, this.endDateValue, this.businessPartyId).subscribe((searchData: any) => {
        const paginationDetails = searchData.pagination;
        this.page = paginationDetails.page + 1;
        this.totalCount = this.pageSize * paginationDetails.lastPage;
        this.partyPurchaseTransations = searchData.purchaseTransactions;

        const transactionAmountInfos = this.partyPurchaseTransations.map((transcationAmountData) => {
          let transationAmountInNumber = +transcationAmountData.transactionAmount;
          return transationAmountInNumber;
        })
        let arrayOfTransactionAmounts = transactionAmountInfos;
        this.transactionAmountOfPartyPurchase = arrayOfTransactionAmounts.reduce((accumulator, current) => {
          return accumulator + current;
        }, 0)

        if (this.partyPurchaseTransations.length === 0) {
          this.displayLoader = false;
          this.dispalyNotFoundData = true;

        }
      }, (error: any) => {
        this.toastr.error(error.error.message || 'Something went wrong!', 'Error', {
          progressBar: true,
        });
        this.displayLoader = false;
        this.dispalyNotFoundData = true;
      })
    }
  }


  //  End of Party Transactions Details


  viewPartyInvoice(partyId: string) {
    this.router.navigateByUrl("/pages/partyPurchaseView/" + partyId)
  }

  viewBusineesPartyTrasactionById(stockBusinessPartyTransactionId: string) {
    const viewCustoemrTransactionByIdRef = this.modalService.open(ViewPartyTransactionByIdComponent);
    viewCustoemrTransactionByIdRef.componentInstance.businessPartyTransactionId = stockBusinessPartyTransactionId;
  }

  ngOnInit(): void {
    this.businessPartyId = this.route.snapshot.params['id'];
    this.searchedTransactionValues = '';
    this.searchedPartyInvoiceValues = '';
    this.startDateValue = formatDate(this.previousDate, 'yyyy-MM-dd', 'en');
    this.endDateValue = formatDate(this.currentDate, 'yyyy-MM-dd', 'en');
    this.dateForm = this.FormBuilder.group({
      startDate: new FormControl(formatDate(this.previousDate, 'yyyy-MM-dd', 'en')),
      endDate: new FormControl(formatDate(this.currentDate, 'yyyy-MM-dd', 'en')),
    })
    this.fetchPartyPaymentsDetails().then();
    this.fetchPartyPurchaseInvoiceDetails().then();



  }

}
