import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/models/Customer';
import { CustomerInvoiceService } from 'src/app/services/customer-invoice.service';
import { CustomerService } from 'src/app/services/customer/customer.service';

@Component({
  selector: 'app-add-customer-transaction',
  templateUrl: './add-customer-transaction.component.html',
  styleUrls: ['./add-customer-transaction.component.scss']
})
export class AddCustomerTransactionComponent implements OnInit {

  addCustomerTransaction: FormGroup;
  @Input() customerTransactionId: string;
  customerName: Customer[] = [];
  getAllCustomer: Customer[] = [];
  currentDate = new Date().toISOString().substring(0, 10);
  submitted: boolean = false;
  loading: boolean = false;
  public defaultTransactionCustomerAmount: number = 10;



  constructor(private activeModalService: NgbActiveModal,
    private customerService: CustomerService,
    private customerInvoiceService: CustomerInvoiceService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder) { }


  ngOnInit(): void {
    this.getAllCusotmerDetails().then(null)
    if (this.customerTransactionId !== null) {
      this.customerService.getCustomerById(this.customerTransactionId).subscribe((customerTransactionData: any) => {
        this.customerName = customerTransactionData.id;
      })

    }

    this.addCustomerTransaction = this.formBuilder.group({
      customer: new FormControl('', this.customerTransactionId !== null ? [] : Validators.required),
      invoiceNo: new FormControl(''),
      transactionAmount: new FormControl(this.defaultTransactionCustomerAmount, [Validators.required, Validators.min(10)]),
      paymentMethod: new FormControl('Cash', Validators.required),
      transactionDate: new FormControl(formatDate(this.currentDate, 'yyyy-MM-dd', 'en'), Validators.required),
      remarks: new FormControl(''),
    })
  }

  public async getAllCusotmerDetails(): Promise<void> {
    this.customerService.getAllCustomerDetails().subscribe((getCusomterDatas: any) => {
      this.getAllCustomer = getCusomterDatas;
    })
  }


  closeModal() {
    this.activeModalService.close();
  }

  onSubmit() {
    this.loading = true;
    if (this.customerTransactionId !== null) {
      this.addCustomerTransaction.value.customer = this.customerTransactionId
    }
    this.customerInvoiceService.createCustomerTransaction(this.addCustomerTransaction.value).subscribe((_) => {
      this.toastrService.success('Customer Transaction Created Successfully!', 'Success', {
        progressBar: true,
      });
      this.closeModal();
      this.loading = false;
    }, (error) => {
      this.toastrService.error(error.error.message || 'Something went wrong!', 'Error', {
      });
      this.loading = false
    })
  }


}
