<div class="section-body">
    <div class="container-fluid">
        <div class="d-flex justify-content-between align-items-center ">
            <div class="header-action d-flex">
                <h1 class="page-title" i18n="@@customer">{{this.customerName?.name}} - Transcations</h1>
                <ol class="breadcrumb page-breadcrumb mt-2">
                    <li class="breadcrumb-item" i18n="@@admin"> <a [routerLink]="['/pages/dashboard']">(
                            Dashboard</a>
                    </li>
                    <li class="breadcrumb-item"><a [routerLink]="['/pages/customers']" i18n="@@customer">Customer
                        </a></li>
                    <li class="breadcrumb-item active" aria-current="page" i18n="@@edit">View )</li>
                </ol>
            </div>

            <div class="add-customer-tansaction">
                <button type="button" class="btn btn-md btn-success mt-2" (click)="addCustomerTransaction()"
                    *ngIf="this.customerName?.payable != '0.0000'"><i class="fa fa-plus-circle"></i> Add
                    Transaction</button>
            </div>
        </div>
    </div>
</div>

<div class="section-body mt-4">
    <div class="row">
        <div class="col-md-6" style="margin-bottom: 4rem;">
            <h6 class="text-center"><b>Invoice Listing</b></h6>
            <hr>
            <div class="filters-section">
                <div class="date-filter-section d-flex">
                    <!-- <label>Start Date</label> <br> -->
                    <form [formGroup]="dateForm" class="w-100 d-flex">

                        <div class="input-group mb-1 w-50 mr-1">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i>
                                </div>
                            </div>
                            <input type="date" class="form-control" name="startDate" i18n-placeholder
                                placeholder="Enter start Date" formControlName="startDate"
                                (change)="getStartDateValueOfInvoice($event)">
                        </div>

                        <!-- <label>End Date</label> -->
                        <div class="input-group mb-1 w-50 ml-1">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i>
                                </div>
                            </div>
                            <input type="date" class="form-control" name="endDate" i18n-placeholder
                                placeholder="Enter end Date" formControlName="endDate"
                                (change)="getEndDateValueOfInvoice($event)">
                        </div>
                    </form>

                </div>



            </div>

            <div class="party-payable-amount-header px-1 py-1 mt-3">
                <div class="party-payable-amount-section d-flex">
                    <div class="input-group border ml-1 mt-1 mb-1" id="search-bar">
                        <div class="input-group-prepend">
                            <button id="button-addon4" type="button" class="btn btn-link text-info border-0"><i
                                    class="fa fa-search"></i></button>
                        </div>
                        <input type="search" placeholder="Search here" class="form-control bg-none border-0"
                            (keyup)="getValueOfCusotomerPUrchaseInvoice($event)">
                    </div>
                    <h5 class="mt-auto mb-auto ml-auto calculatedAmount">Payable : </h5>
                    <h5 class="mt-auto mb-auto mr-3 ml-3 calculatedAmount">Rs. {{this.customerName?.payable
                        |
                        currency:' ':'symbol':''}}</h5>
                </div>
            </div>

            <div class="invoice-table mt-2 pt-1">
                <table class="table table-hover">
                    <thead class="thead splitForPrint">
                        <tr>
                            <th scope="col gray-ish">S.N</th>
                            <th scope="col gray-ish" style="width: 55%;">Bill No.</th>
                            <th scope="col gray-ish" style="width: 35%;">Date</th>
                            <th scope="col gray-ish" style="width: 10%;">
                                <span class="float-right" style="margin-right: 35px;">Amount</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="customerInvoiceDetails.length === 0">
                            <td class="text-center" colspan="4" *ngIf="this.displayLoader">
                                <div class="spinner-grow text-secondary mt-3" role="status">
                                </div>
                                <div class="spinner-grow text-secondary mt-3" role="status">
                                </div>
                                <div class="spinner-grow text-secondary mt-3" role="status">
                                </div>
                                <div class="spinner-grow text-secondary mt-3" role="status">
                                </div>
                                <div class="mt-2">
                                    <h6 scope="col gray-ish">Please Wait</h6>
                                </div>
                            </td>

                            <td class="text-center" colspan="4" *ngIf="this.dispalyNotFoundData">
                                <div class="mt-2">
                                    <h6 scope="col gray-ish">No data found...</h6>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="customerInvoiceDetails.length !== 0">
                            <td colspan="4">
                                <div class="alert alert-danger" role="alert">
                                    Double click the row to view the invoice!!
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let customerInvoiceDetailsInfos of customerInvoiceDetails | paginate : {
                                itemsPerPage: pageSize,
                                currentPage: page,totalItems:totalCount
                                }; index as i" (dblclick)="viewCustomerInvoice(customerInvoiceDetailsInfos.id)">
                            <th scope="row" style="border-top: none ;">{{i + 1 }}</th>
                            <td class="item" style="border-top: none ;">{{ customerInvoiceDetailsInfos.invoiceNumber
                                }}</td>
                            <td style="border-top: none ;">
                                {{ customerInvoiceDetailsInfos.bussinessDate | date }}
                            </td>
                            <td style="border-top: none ;">
                                <span class="float-right mr-5 ">{{ customerInvoiceDetailsInfos.totalAmount |
                                    currency:' ':'symbol':'' }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="party-payable-amount-header px-1 py-1"
                style="border-top-right-radius: 0px; border-top-left-radius: 0px;">
                <div class="party-payable-amount-section d-flex">
                    <h5 class="mt-2 ml-auto mr-1 calculatedAmount">Total Amount : </h5>

                    <h5 class="mt-2 mr-3 ml-1 calculatedAmount">Rs. {{ this.totalCustomerInvoiceAmount |
                        currency:' ':'symbol':'' }}</h5>
                </div>
            </div>
        </div>

        <div class="col-md-6" style="margin-bottom: 4rem;">
            <h6 class="text-center"><b>Transaction Listing</b></h6>
            <hr>
            <div class="filters-section">
                <div class="date-filter-section d-flex">
                    <!-- <label>Start Date</label> <br> -->
                    <form [formGroup]="dateForm" class="w-100 d-flex">
                        <div class="input-group mb-1 w-50 mr-1">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i>
                                </div>
                            </div>
                            <input type="date" class="form-control" name="startDate" i18n-placeholder
                                placeholder="Enter start Date" formControlName="startDate"
                                (change)="getStartDateValue($event)">
                        </div>

                        <!-- <label>End Date</label> -->
                        <div class="input-group mb-1 w-50 ml-1">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i>
                                </div>
                            </div>
                            <input type="date" class="form-control" name="endDate" i18n-placeholder
                                placeholder="Enter end Date" formControlName="endDate"
                                (change)="getEndDateValue($event)">
                        </div>
                    </form>

                </div>



            </div>

            <div class="party-payable-amount-header px-1 py-1 mt-3">
                <div class="party-payable-amount-section d-flex">
                    <div class="input-group border ml-1 mt-1 mb-1" id="search-bar">
                        <div class="input-group-prepend">
                            <button id="button-addon4" type="button" class="btn btn-link text-info border-0"><i
                                    class="fa fa-search"></i></button>
                        </div>
                        <input type="search" placeholder="Search here" class="form-control bg-none border-0"
                            (keyup)="getValueOfCustomerTransaction($event)">
                    </div>
                </div>
            </div>

            <div class="invoice-table mt-2 pt-1">
                <table class="table table-hover">
                    <thead class="thead splitForPrint">
                        <tr>
                            <th scope="col gray-ish">S.N</th>
                            <th scope="col gray-ish">Payment Method</th>
                            <th scope="col gray-ish">Date</th>
                            <th>
                                <span class="float-right" style="margin-right: 36px;">Amount</span>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="customerPurchaseTransations.length === 0">
                            <td class="text-center" colspan="4" *ngIf="this.displayLoader">
                                <div class="spinner-grow text-secondary mt-3" role="status">
                                </div>
                                <div class="spinner-grow text-secondary mt-3" role="status">
                                </div>
                                <div class="spinner-grow text-secondary mt-3" role="status">
                                </div>
                                <div class="spinner-grow text-secondary mt-3" role="status">
                                </div>
                                <div class="mt-2">
                                    <h6 scope="col gray-ish">Please Wait</h6>
                                </div>
                            </td>

                            <td class="text-center" colspan="4" *ngIf="this.dispalyNotFoundData">
                                <div class="mt-2">
                                    <h6 scope="col gray-ish">No data found...</h6>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="customerPurchaseTransations.length !== 0">
                            <td colspan="4">
                                <div class="alert alert-danger" role="alert">
                                    Double click the row to view the transaction!!
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let filteredCusotmerTransactionsByBusinessPartyIdInfos of customerPurchaseTransations | paginate : {
                                itemsPerPage: pageSize,
                                currentPage: page,totalItems:totalCount
                                }; index as i"
                            (dblclick)="viewCustomerTrasactionById(filteredCusotmerTransactionsByBusinessPartyIdInfos.id)">
                            <th scope="row" style="border-top: none ;">{{i + 1 }}</th>
                            <td class="item" style="border-top: none ;">{{
                                filteredCusotmerTransactionsByBusinessPartyIdInfos.paymentMethod
                                === 'Check' ? 'Cheque' :
                                filteredCusotmerTransactionsByBusinessPartyIdInfos.paymentMethod
                                }}</td>
                            <td style="border-top: none ;">{{
                                filteredCusotmerTransactionsByBusinessPartyIdInfos.transactionDate | date }}
                            </td>
                            <td style="border-top: none ;">
                                <span class="float-right mr-5">{{
                                    filteredCusotmerTransactionsByBusinessPartyIdInfos.transactionAmount |
                                    currency:' ':'symbol':'' }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="party-payable-amount-header px-1 py-1"
                style="border-top-right-radius: 0px; border-top-left-radius: 0px;">
                <div class="party-payable-amount-section d-flex">
                    <h5 class="mt-2 ml-auto mr-1 calculatedAmount">Paid Amount :</h5>
                    <!-- <pagination-controls class="mt-2" (pageChange)="pageChange($event)">
                    </pagination-controls> -->
                    <h5 class="mt-2 ml-1 mr-3 calculatedAmount">Rs. {{ this.transactionAmountOfCustomer |
                        currency:' ':'symbol':'' }}</h5>
                </div>
            </div>
        </div>
    </div>



</div>