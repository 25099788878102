import { formatDate } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/models/Customer';
import { ProductDetail } from 'src/app/models/ProductDetails';
import { CustomerInvoiceService } from 'src/app/services/customer-invoice.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { ProductService } from 'src/app/services/product/product.service';
import { TransactionCategory } from 'src/app/types/party-purchase.type';
import Swal from 'sweetalert2';
import { AddEditCustomerComponent } from '../add-edit-customer/add-edit-customer.component';
declare var $: any;

@Component({
  selector: 'app-add-customer-invoice',
  templateUrl: './add-customer-invoice.component.html',
  styleUrls: ['./add-customer-invoice.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddCustomerInvoiceComponent implements OnInit {
  public customerDetails: Customer[] = [];
  public getAvailableStockDetails = <any[]>[];
  public someData: Customer[] = [];
  public productDetails: ProductDetail[] = [];
  submitted: boolean = false;
  loading: boolean = false;
  customerInvoiceForm: FormGroup;
  id: string;
  @Output()
  notify = new EventEmitter();
  currentDate = new Date().toISOString().substring(0, 10);
  initailValue: number = 0;
  calculatedTotalAmount: number = 0;
  calculatedSubTotalAmount: number = 0;
  disableAddButton: boolean = false;
  totalRow: number;
  valueOfVatAmount: number = 0;
  valueOfDiscountAmount: number = 0;
  getSelectedProductId: string;
  public defaultTransactionCustomerAmount: number = 10;
  public selectedAmountStatus: TransactionCategory;
  public showTransactionAmountInput: boolean = false;
  public bootstrapMdValue: number = 3;
  product: IDropdownSettings = {};
  public productRateData: number = 0;
  public productQuantityData: number = 0;

  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    private router: Router,
    private customerInvoiceService: CustomerInvoiceService,
    private render: Renderer2,
    private productService: ProductService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getAllCustoemr().then(null);
    this.getAllProducts().then(null);
    this.selectedAmountStatus = TransactionCategory.NoPayment;

    this.product = {
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      singleSelection: true,
      closeDropDownOnSelection: true,
      noDataAvailablePlaceholderText: 'Not data available',
    };

    this.customerInvoiceForm = this.formBuilder.group({
      customer: new FormControl('0', Validators.required),
      vatAmount: new FormControl(this.valueOfVatAmount, Validators.min(0)),
      discountAmount: new FormControl(
        this.valueOfDiscountAmount,
        Validators.min(0),
      ),
      transactionAmount: new FormControl(
        this.defaultTransactionCustomerAmount,
        [Validators.required, Validators.min(10)],
      ),
      transactionDate: new FormControl(
        formatDate(this.currentDate, 'yyyy-MM-dd', 'en'),
        Validators.required,
      ),
      paymentMethod: new FormControl('Cash', Validators.required),
      customerTransactionRemarks: new FormControl(''),
      paymentStatus: new FormControl(
        this.selectedAmountStatus,
        Validators.required,
      ),
      invoiceNo: new FormControl(''),
      subTotalAmount: new FormControl('0.00'),
      totalAmount: new FormControl('0.00'),
      remarks: new FormControl(''),
      bussinessDate: new FormControl(
        formatDate(this.currentDate, 'yyyy-MM-dd', 'en'),
      ),
      products: this.formBuilder.array([this.initItemRow()]),
    });
  }

  public async getAllCustoemr(): Promise<void> {
    this.customerService.getAllCustomerDetails().subscribe(
      (customerData: any) => {
        this.customerDetails = customerData;
      },
      (error) => {
        this.toastr.error(
          'Error',
          error.error.message || 'Something went wrong!',
          {
            progressBar: true,
          },
        );
      },
    );
  }

  public async getAllProducts(): Promise<void> {
    this.productService.getAllProductDetails().subscribe(
      (productsData: any) => {
        this.productDetails = productsData;
      },
      (error) => {
        this.toastr.error(
          'Error',
          error.error.message || 'Something went wrong!',
          {
            progressBar: true,
          },
        );
      },
    );
  }
  refreshProductData() {
    this.getAllProducts().then(null);
  }

  addCustomerInfo() {
    const modalRef = this.modalService.open(AddEditCustomerComponent);
    modalRef.componentInstance.isAddMode = true;

    modalRef.result.then(() => {
      this.getAllCustoemr().then(null);
    });
  }

  cancel() {
    this.router.navigate(['/pages/customers/']);
  }

  initItemRow() {
    return this.formBuilder.group({
      product: ['', Validators.required],
      productCount: [''],
      quantity: ['', Validators.required],
      rate: ['', Validators.required],
    });
  }

  addNewRow() {
    const control = <FormArray>this.customerInvoiceForm.controls['products'];
    control.push(this.initItemRow());
    this.initailValue++;
  }

  get products() {
    return this.customerInvoiceForm.get('products') as FormArray;
  }

  deleteRow(index: number) {
    const control = <FormArray>this.customerInvoiceForm.controls['products'];
    if (control != null) {
      index = control.value.length;
    }

    if (index > 1) {
      control.removeAt(index - 1);
      $('#addr' + (index - 1)).html('');
      index--;
      this.getTotalCost();
      return true;
    } else {
      this.toastr.error(
        'One record is mendatory!',
        'Error',
        {
          progressBar: true,
        },
      );
      return false;
    }
  }

  getValues() {
    this.getTotalCost();
  }

  getTotalAmountAfterVat(event: any) {
    this.calculateSubTotal();
  }

  getValueOfDiscountAmount(event: any) {
    this.calculateSubTotal();
  }

  onItemSelect(indexNum, event: any) {
    this.productService.getProductStock(event.id).subscribe(
      (productStock: any) => {
        this.getAvailableStockDetails[indexNum] = productStock.availableCount;
      },
      (error) => {
        this.toastr.error(
          error.error.message || 'Something went wrong!',
          'Error',
          {
            progressBar: true,
          },
        );
      },
    );
  }

  getTotalCost() {
    $('#tab_logic tbody tr').each(function (i, element) {
      var html = $(this).html();
      if (html != '') {
        this.productRateData = $(this).find('.rate').val();
        let productStockCount = $(this).find('.productCount').val();
        this.productQuantityData = $(this).find('.quantity').val();
        if (
          parseInt(this.productQuantityData) > parseInt(productStockCount) ||
          parseInt(this.productQuantityData) < 0
        ) {
          alert(
            'Product quantity should not be greater than product stock or less than 0',
          );
          $(this).find('.quantity').val(0);
          $(this).find('.total_cost').val(0);
        } else {
          $(this)
            .find('.total_cost')
            .val(this.productRateData * this.productQuantityData);
        }
      }
    });
    this.calculateSubTotal();
  }

  private calculateSubTotal() {
    var totalProductAmount = 0;
    $('.total_cost').each(function () {
      totalProductAmount += parseFloat($(this).val());
    });

    $('#subTotal').val(totalProductAmount.toFixed(3));
    this.calculatedSubTotalAmount = totalProductAmount;
    this.valueOfVatAmount = parseFloat($('#vatAMount').val());
    this.valueOfDiscountAmount = parseFloat($('#discountAmount').val());
    const totalAmountAfterVat =
      this.calculatedSubTotalAmount + this.valueOfVatAmount;
    this.calculatedTotalAmount =
      totalAmountAfterVat - this.valueOfDiscountAmount;

    $('#totalAmount').val(this.calculatedTotalAmount.toFixed(3));
  }

  getCustomerSelectedPaymentSatatus(event) {
    this.selectedAmountStatus = event.target.value;
    if (this.selectedAmountStatus === TransactionCategory.PartialPayment) {
      this.bootstrapMdValue = 0;
      this.showTransactionAmountInput = true;
    } else if (this.selectedAmountStatus === TransactionCategory.NoPayment) {
      this.bootstrapMdValue = 3;
      this.showTransactionAmountInput = false;
    } else if (this.selectedAmountStatus === TransactionCategory.FullPayment) {
      this.bootstrapMdValue = 3;
      this.showTransactionAmountInput = false;
    } else {
      return;
    }
  }

  public async onSubmit() {
    try {
      if (this.customerInvoiceForm.invalid) {
        return;
      }

      let productPurchasesDatas =
        this.customerInvoiceForm.controls.products.value.map((mapData: any) => {
          return {
            product: mapData.product[0].id,
            rate: mapData.rate,
            quantity: mapData.quantity,
          };
        });
      this.customerInvoiceForm.value.products = productPurchasesDatas;
      this.customerInvoiceForm.value.totalAmount = this.calculatedTotalAmount;
      this.customerInvoiceForm.value.subTotalAmount =
        this.calculatedSubTotalAmount;

      if (this.selectedAmountStatus === TransactionCategory.PartialPayment) {
        this.customerInvoiceForm.value.transactionAmount;
      } else if (
        this.selectedAmountStatus === TransactionCategory.FullPayment
      ) {
        this.customerInvoiceForm.value.transactionAmount =
          this.calculatedTotalAmount;
      }

      let customerPurchaseDetials = {
        customer: this.customerInvoiceForm.value.customer,
        discountAmount: this.customerInvoiceForm.value.discountAmount,
        vatAmount: this.customerInvoiceForm.value.vatAmount,
        bussinessDate: this.customerInvoiceForm.value.bussinessDate,
        products: this.customerInvoiceForm.value.products,
        remarks: this.customerInvoiceForm.value.remarks,
      };

      let customerTransactionDetails = {
        customer: this.customerInvoiceForm.value.customer,
        transactionAmount: this.customerInvoiceForm.value.transactionAmount,
        transactionDate: this.customerInvoiceForm.value.transactionDate,
        invoiceNo: this.customerInvoiceForm.value.invoiceNo,
        paymentMethod: this.customerInvoiceForm.value.paymentMethod,
        remarks: this.customerInvoiceForm.value.customerTransactionRemarks,
      };

      const customerPurchaseInvoiceResponse = await this.customerInvoiceService
        .createCustomerInvoice(customerPurchaseDetials)
        .toPromise();
      const customerTransactionDetailsResponse =
        this.selectedAmountStatus != TransactionCategory.NoPayment &&
        customerPurchaseInvoiceResponse &&
        (await this.customerInvoiceService
          .createCustomerTransaction(customerTransactionDetails)
          .toPromise());

      if (
        customerPurchaseInvoiceResponse ||
        (customerPurchaseInvoiceResponse && customerTransactionDetailsResponse)
      ) {
        this.toastr.success(
          'Customer invoice and transaction created successfully!',
          'Success',
          {
            progressBar: true,
          },
        );
        this.router.navigate(['/pages/customerInvoice']);
      }
    } catch (error) {
      this.toastr.error(
        error.error.message || 'Something went wrong!',
        'Error',
        {
          progressBar: true,
        },
      );
    }
  }
}
