import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BranchService } from 'src/app/services/branch/branch.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { exit } from 'process';
import { BranchDetail } from 'src/app/models/BranchDetail';


@Component({
  selector: 'app-add-edit-branch',
  templateUrl: './add-edit-branch.component.html',
  styleUrls: ['./add-edit-branch.component.scss']
})
export class AddEditBranchComponent implements OnInit {
  submitted: boolean = false;
  @Input() isEditMode: boolean;
  @Input() isAddMode: boolean;
  branchForm: FormGroup;
  totalRow: number;
  @Input() branchId: string;
  public branchDetails: BranchDetail[] = [];
  public loading: boolean = true;


  public codeErrorValidation = [
    {
      errType: 'required',
      errorMsg: 'Code is Required!'
    },
    {
      errType: 'minlength',
      errorMsg: 'Code must be more than or equal to 5 characters long!'
    },
    {
      errType: 'maxlength',
      errorMsg: 'Code must be less than or equal to 10 characters long!'
    }

  ]

  public locationErrorValidation = [
    {
      errType: 'required',
      errorMsg: 'Location is Required!'
    },
    {
      errType: 'minlength',
      errorMsg: 'Location must be more than or equal to 5 characters long!'
    },
    {
      errType: 'maxlength',
      errorMsg: 'Location must be less than or equal to 30 characters long!'
    }
  ]

  constructor(
    private formBuilder: FormBuilder,
    private branchService: BranchService,
    private activeModalService: NgbActiveModal,
    private toastr: ToastrService) {
  }

  onSubmit() {
    if (this.branchForm.invalid) {
      return;
    }
    if (this.isAddMode === true) {
      this.createBranchDetail();
    } else if (this.isEditMode === true) {
      this.updateBranchDetail();
    } else {
      exit(0);
    }
  }


  private createBranchDetail() {
    this.loading = true;
    this.branchService.createBranch(this.branchForm.value).subscribe(() => {
      this.toastr.success('Branch Created Successfully!', 'Success', {
        progressBar: true,
      })
      this.closeModal();
    }, (error) => {
      this.toastr.error(error.error.message || "Something went wrong!", 'Error', {
        progressBar: true,
      });
    })
  }



  private updateBranchDetail() {
    this.branchService
      .updateBranch(this.branchId, this.branchForm.value)
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          this.toastr.success(res.message, 'Success', {
            progressBar: true,
          });
          this.closeModal();
        },
        error: (error) => {
          this.toastr.error(error.error.message || "Something went wrong!", 'Error', {
            progressBar: true,
          });
        },
      });

  }

  closeModal() {
    this.activeModalService.close();
  }


  ngOnInit(): void {
    this.branchForm = this.formBuilder.group({
      code: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(5)]),
      location: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(30)]),
    })

    if (this.isEditMode === true) {
      this.branchService
        .getBranchById(this.branchId)
        .pipe(first())
        .subscribe((data: any) => {
          this.branchForm.patchValue(data)
        }

        );

    }
  }






}
