import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BusinessParty } from 'src/app/models/BusinessParty';
import { BusinessPartyService } from 'src/app/services/business-party/business-party.service';
import { PartyPurchaseTransactionService } from 'src/app/services/party-purchase-transactions/party-purchase-transaction.service';

@Component({
  selector: 'app-add-party-transaction',
  templateUrl: './add-party-transaction.component.html',
  styleUrls: ['./add-party-transaction.component.scss']
})
export class AddPartyTransactionComponent implements OnInit {
  addPartyTransaction: FormGroup;
  @Input() partyTransactionId: string;
  businessPartyName: BusinessParty[] = [];
  allBusinessParty: BusinessParty[] = [];
  currentDate = new Date().toISOString().substring(0, 10);
  submitted: boolean = false;
  loading: boolean = false;
  public defaultTransactionCustomerAmount: number = 10;




  constructor(private activeModalService: NgbActiveModal,
    private businessPartyService: BusinessPartyService,
    private PartyPurchaseTransactionService: PartyPurchaseTransactionService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder) { }


  ngOnInit(): void {
    this.getAllBusinessParty().then(null);
    if (this.partyTransactionId !== null) {
      this.businessPartyService.getBusinessPartyById(this.partyTransactionId).subscribe((partyTransactionData: any) => {
        this.businessPartyName = partyTransactionData.id;
      })
    }


    this.addPartyTransaction = this.formBuilder.group({
      bussinessParty: new FormControl('', this.partyTransactionId !== null ? [] : Validators.required),
      billNo: new FormControl(''),
      transactionAmount: new FormControl(this.defaultTransactionCustomerAmount, [Validators.required, Validators.min(10)]),
      paymentMethod: new FormControl('Cash', Validators.required),
      transactionDate: new FormControl(formatDate(this.currentDate, 'yyyy-MM-dd', 'en'), Validators.required),
      remarks: new FormControl(''),
    })
  }

  public async getAllBusinessParty(): Promise<void> {
    this.businessPartyService.getBusinessPartyDetails().subscribe((allBusinessPartyDatas: any) => {
      this.allBusinessParty = allBusinessPartyDatas;

    })
  }

  closeModal() {
    this.activeModalService.close();
  }

  onSubmit() {
    this.loading = true;
    if (this.partyTransactionId !== null) {
      this.addPartyTransaction.value.bussinessParty = this.partyTransactionId
    }
    this.PartyPurchaseTransactionService.createPartyPurchaseTransaction(this.addPartyTransaction.value).subscribe((transactionDatas: any) => {
      this.toastrService.success('Party Transaction Created Successfully!', 'Success', {
        progressBar: true,
      });
      this.closeModal();
      this.loading = false;
    }, (error) => {
      this.toastrService.error(error.error.message || 'Something went wrong!', 'Error', {
      });
      this.loading = false
    })
  }


}