<div class="section-body" id="page_top">
    <div class="container-fluid">
        <div class="page-header d-flex">
            <div class=" left">
                <a href="javascript:void(0)" id="toggle-navbar" class="nav-link icon menu_toggle"><i
                        class="fe fe-align-center"></i></a>

            </div>
            <div class="float-right ml-auto d-flex align-items-center">
                <div id="internet-module">
                    <a href="javascript:void(0)" (click)="openInternetModule()" id="toggle-navbar-module">
                        <button class="btn btn-light btn-sm">Internet Module</button>
                    </a>
                </div>
                <div id="my-account">
                    <div class="dropdown d-flex">
                        <a href="javascript:void(0)" class="chip ml-3" data-toggle="dropdown">
                            <i class="fa fa-caret-down mt-2 mr-2" aria-hidden="true"></i>
                            <span>
                                {{ this.name ? this.name :
                                'Anonymous'}}
                            </span></a>
                        <div class="dropdown-menu dropdown-menu-arrow">
                            <a *ngIf="this.userRole === 'admin'" class="dropdown-item"
                                (click)="viewLoggedInUserProfile()"><i class="dropdown-icon fe fe-user"></i>
                                Profile</a>
                            <a class="dropdown-item" [routerLink]="" (click)="logout()"><i
                                    class="dropdown-icon fe fe-log-out"></i> Sign
                                out</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>