import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { LoggedInUserProfileComponent } from './logged-in-user-profile/logged-in-user-profile.component';

const COMPONENTS = [
  LoginComponent
];

const MODULES = [
  CommonModule,
  RouterModule,
  ReactiveFormsModule,
  FormsModule,
  HttpClientModule,
  ToastrModule,
];

@NgModule({
  declarations: [...COMPONENTS, LoggedInUserProfileComponent],
  imports: [...MODULES],
})
export class AuthModule {}
