import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartyPurchase } from 'src/app/models/PartyPurchas';
import { PartyPurchaseService } from 'src/app/services/party-purchase/party-purchase.service';

@Component({
  selector: 'app-view-party-purchase-invoice',
  templateUrl: './view-party-purchase-invoice.component.html',
  styleUrls: ['./view-party-purchase-invoice.component.scss']
})
export class ViewPartyPurchaseInvoiceComponent implements OnInit {
  id: string;
  public partyPurchaseInvoiceData: PartyPurchase;



  constructor(private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private partyPurchaseService: PartyPurchaseService,) { }


  public async getPartyPurchaseInvoiceById(): Promise<void> {
    this.partyPurchaseService.getPartyPurchaseById(this.id).subscribe((invoiceData: any) => {
      this.partyPurchaseInvoiceData = invoiceData;

    })
  }


  printPage() {
    window.print();
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getPartyPurchaseInvoiceById().then(null);
  }

}
