import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PartyPurchase } from 'src/app/models/PartyPurchas';
import { ExportTableDataService } from 'src/app/services/export-table-data/export-table-data.service';
import { PartyPurchaseService } from 'src/app/services/party-purchase/party-purchase.service';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-party-purchase',
  templateUrl: './party-purchase.component.html',
  styleUrls: ['./party-purchase.component.scss']
})
export class PartyPurchaseComponent implements OnInit {

  public partyPurchaseDetails: PartyPurchase[] = [];
  page: number = 1;
  noOfRecordsSizesArr = [10, 20, 30];
  pageNo = 0;
  pageSize = 10;
  totalCount = 0;
  public searchedValues: string;
  displayLoader: boolean = true;
  dispalyNotFoundData: boolean = false;


  constructor(private partyPurchaseService: PartyPurchaseService, private toastr: ToastrService,
    private exportTableDataService: ExportTableDataService) { }

  ngOnInit(): void {
    this.getAllPartyPurchases().then(null);
  }

  public async getAllPartyPurchases(): Promise<void> {
    this.partyPurchaseService.getPartyPurchaseDetails(this.pageSize, this.pageNo).subscribe((partyPurchaseDatas: any) => {
      const paginationDetails = partyPurchaseDatas.pagination;
      this.page = paginationDetails.page + 1;
      this.totalCount = this.pageSize * paginationDetails.lastPage;
      this.partyPurchaseDetails = partyPurchaseDatas.partyPurchaseInvoices;

      if (this.partyPurchaseDetails?.length === 0) {
        this.displayLoader = false;
        this.dispalyNotFoundData = true;

      }
    }, (error) => {
      this.toastr.error(error.error.message || "Something went wrong!", 'Error', {
        progressBar: true,
      });

    });
  }

  getValue(event: any) {
    this.searchedValues = event.target.value;


    if (this.searchedValues.length >= 2) {
      this.partyPurchaseService.searchPartyPurchaseInvoice(this.pageSize, this.pageNo, this.searchedValues).subscribe((data: any) => {
        const paginationDetails = data.pagination;
        this.page = paginationDetails.page + 1;
        this.totalCount = this.pageSize * paginationDetails.lastPage;
        this.partyPurchaseDetails = data.partyPurchaseInvoices;
        if (this.partyPurchaseDetails?.length === 0) {
          this.displayLoader = false;
          this.dispalyNotFoundData = true;

        }
      })
    } else if (this.searchedValues == '') {
      this.getAllPartyPurchases();
    }
  }


  deletePartyPurchase(partyPurchaseId) {
    Swal.fire({
      title: 'Are you sure?',
      // text: 'You will not be able to revert this process!',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'Yes, delete it! ',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        this.partyPurchaseService.deletePartyPurchase(partyPurchaseId).subscribe(
          (res: any) => {
            Swal.fire('Deleted!', res.message, 'success');
            this.getAllPartyPurchases();
          },
          (error) => {
            Swal.fire('Error', error.error.message, 'error');
          }
        );
      }
    });
  }

  noOfRecordsSizeChange(): void {
    this.getAllPartyPurchases();
  }

  pageChange(event) {
    this.page = event;
    this.pageNo = this.page - 1;
    this.getAllPartyPurchases();
  }

  exportAsXLSX(): void {
    this.exportTableDataService.exportAsExcelFile(this.partyPurchaseDetails, 'party-purchase-invoice');
  }





}
