import { environment } from './../environments/environment';

export const ServerApis = {
  /*
  *
  * Authentication EndPoints
  * 
  */
  userLoginURL: `${environment.api}/auth/login`,
  loggedInProfileUrl: `${environment.api}/account/loggedInUserDetails`,

  /*
 *
 * Pages EndPoints
 * 
 */
  proudctDetailsUrl: `${environment.api}/product`,
  productDetailsBasicListingUrl: `${environment.api}/product/basic-list`,
  productStockUrl: `${environment.api}/product-stock`,
  branchDetailUrl: `${environment.api}/branch`,
  businessPartyUrl: `${environment.api}/bussiness-party`,
  businessPartyBasicListingUrl: `${environment.api}/bussiness-party/basic-list`,
  partyPurchaseUrl: `${environment.api}/party-purchase-invoice`,
  productPriceUrl: `${environment.api}/party-purchase-invoice/product-price`,
  partyPurchaseTransactionUrl: `${environment.api}/party-purchase-transaction`,
  customerUrl: `${environment.api}/customer`,
  customersDetailsBasicListingUrl: `${environment.api}/customer/basic-list`,

  customerInvoiceUrl: `${environment.api}/customer-invoice`,
  modifyCustomerPayable: `${environment.api}/customer/modify-payable`,
  customerInvoiceTransactionUrl: `${environment.api}/customer-invoice-transaction`,
  accountUrl: `${environment.api}/account`
};
