import { formatDate, Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { BusinessParty } from 'src/app/models/BusinessParty';
import { PartyPurchase } from 'src/app/models/PartyPurchas';
import { ProductDetail } from 'src/app/models/ProductDetails';
import { BusinessPartyService } from 'src/app/services/business-party/business-party.service';
import { PartyPurchaseTransactionService } from 'src/app/services/party-purchase-transactions/party-purchase-transaction.service';
import { PartyPurchaseService } from 'src/app/services/party-purchase/party-purchase.service';
import { ProductService } from 'src/app/services/product/product.service';
import { TransactionCategory } from 'src/app/types/party-purchase.type';
import { AddEditProductComponent } from '../../product/add-edit-product/add-edit-product.component';
declare var $: any;

@Component({
  selector: 'app-add-edit-party-purchase',
  templateUrl: './add-edit-party-purchase.component.html',
  styleUrls: ['./add-edit-party-purchase.component.scss']
})
export class AddEditPartyPurchaseComponent implements OnInit {
  submitted: boolean = false;
  loading: boolean = false;
  isAddMode: boolean;
  partyPurchaseForm: FormGroup;
  id: string;
  public partyPurchase: PartyPurchase[];
  public businessParty: BusinessParty[];
  public products: ProductDetail[];
  currentDate = new Date().toISOString().substring(0, 10);
  initailValue: number;
  calculatedTotalAmount: number = 0;
  calculatedSubTotalAmount: number = 0;
  valueOfVatAmount: any;
  public selectedAmountStatus: TransactionCategory;
  public showTransactionAmountInput: boolean = false;
  public defaultTransactionAmount: number = 10;
  public defaultTransactionVatAmount: number = 0;
  page: number = 1;
  noOfRecordsSizesArr = [10, 20, 30];
  pageNo = 0;
  pageSize = 10;
  totalCount = 0;
  product: IDropdownSettings = {};


  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private location: Location,
    private businessPartyService: BusinessPartyService,
    private productService: ProductService,
    private partyPurchaseService: PartyPurchaseService,
    private modalService: NgbModal,
    private partyPurchaseTransactionService: PartyPurchaseTransactionService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getAllBusinessParties().then(null);
    this.getAllProducts().then(null);
    this.selectedAmountStatus = TransactionCategory.NoPayment;
    this.product = {
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      singleSelection: true,
      closeDropDownOnSelection: true,
      noDataAvailablePlaceholderText: 'Not data available'
    };
    this.isAddMode = !this.id;
    this.partyPurchaseForm = this.formBuilder.group({
      bussinessParty: new FormControl('', Validators.required),
      billNo: new FormControl('', Validators.required),
      vatAmount: new FormControl(this.defaultTransactionVatAmount, Validators.min(0)),
      transactionAmount: new FormControl(this.defaultTransactionAmount, [Validators.required, Validators.min(10)]),
      paymentStatus: new FormControl(this.selectedAmountStatus, Validators.required),
      paymentMethod: new FormControl('Cash', Validators.required),
      transactionDate: new FormControl(formatDate(this.currentDate, 'yyyy-MM-dd', 'en'), Validators.required),
      transactionRemarks: new FormControl(''),
      subTotalAmount: new FormControl('0.00'),
      totalAmount: new FormControl('0.00'),
      remarks: new FormControl(''),
      bussinessDate: new FormControl(
        formatDate(this.currentDate, 'yyyy-MM-dd', 'en'), Validators.required
      ),
      productPurchases: this.formBuilder.array([this.initItemRow()]),
    });
  }

  public async getAllProducts(): Promise<void> {
    this.productService.getAllProductDetails().subscribe((allProductDetails: any) => {
      this.products = allProductDetails;
    }, (error) => {
      this.toastr.error(error.error.message || "Something went wrong!", 'Error', {
        progressBar: true,
      });
    });
  }

  refreshProductData() {
    this.getAllProducts().then(null);
  }

  addProductInfo() {
    const modalRef = this.modalService.open(AddEditProductComponent);
    modalRef.componentInstance.isAddMode = true;
  }

  cancel() {
    this.router.navigate(['/pages/partyPurchase/']);
  }

  initItemRow() {
    return this.formBuilder.group({
      product: [''],
      quantity: [''],
      rate: [''],
    });
  }

  addNewRow() {
    const control = <FormArray>this.partyPurchaseForm.controls['productPurchases'];
    control.push(this.initItemRow());
    this.initailValue++
  }

  deleteRow(index: number) {
    const control = <FormArray>this.partyPurchaseForm.controls['productPurchases'];
    if (control != null) {
      index = control.value.length;
    }

    if (index > 1) {
      control.removeAt(index - 1);
      $("#addr" + (index - 1)).html('');
      index--;
      this.getTotalCost();
      return true;
    } else {
      this.toastr.error(
        'One record is mendatory!',
        'Error',
        {
          progressBar: true,
        },
      );
      return false;
    }
  }

  getValues() {
    this.getTotalCost();
  }

  getTotalAmountAfterVat(event: any) {
    this.calculateSubTotal();
  }

  getTotalCost() {
    $('#tab_logic tbody tr').each(function (i, element) {
      var html = $(this).html();
      if (html != '') {
        var productRate = $(this).find('.rate').val();
        var productQuantity = $(this).find('.quantity').val();
        $(this).find('.total_cost').val(productRate * productQuantity);
      }
    });
    this.calculateSubTotal();
  }

  private calculateSubTotal() {
    var totalProductAmount = 0;
    $('.total_cost').each(function () {
      totalProductAmount += parseFloat($(this).val());
    });
    $('#subTotal').val(totalProductAmount.toFixed(3));
    this.calculatedSubTotalAmount = totalProductAmount;
    this.valueOfVatAmount = parseFloat($('#vatAMount').val())
    this.calculatedTotalAmount = this.calculatedSubTotalAmount + this.valueOfVatAmount;
    $('#totalAmount').val(this.calculatedTotalAmount.toFixed(3));
  }

  public async getAllBusinessParties(): Promise<void> {
    this.businessPartyService.getBusinessPartyDetails().subscribe(
      (businessPartyDatas: any) => {
        this.businessParty = businessPartyDatas;
      },
      (error) => {
        this.toastr.error(error.error.message || "Something went wrong!", 'Error');
      }
    );
  }

  getSelectedPaymentSatatus(event) {
    this.selectedAmountStatus = event.target.value;
    if (this.selectedAmountStatus === TransactionCategory.PartialPayment) {
      this.showTransactionAmountInput = true;
    } else if (this.selectedAmountStatus === TransactionCategory.NoPayment) {
      this.showTransactionAmountInput = false;
    } else if (this.selectedAmountStatus === TransactionCategory.FullPayment) {
      this.showTransactionAmountInput = false;
    } else {
      return;
    }
  }

  async onSubmit() {
    try {
      this.loading = true;
      if (this.partyPurchaseForm.invalid) {
        return;
      }

      if (this.isAddMode) {
        let productPurchasesDatas = this.partyPurchaseForm.controls.productPurchases.value.map((mapData: any) => {
          return {
            product: mapData.product[0].id,
            rate: mapData.rate,
            quantity: mapData.quantity
          }
        });
        this.partyPurchaseForm.value.productPurchases = productPurchasesDatas;
        this.partyPurchaseForm.value.totalAmount = this.calculatedTotalAmount;
        this.partyPurchaseForm.value.subTotalAmount = this.calculatedSubTotalAmount;


        if (this.selectedAmountStatus === TransactionCategory.PartialPayment) {
          this.partyPurchaseForm.value.transactionAmount;
        } else if (this.selectedAmountStatus === TransactionCategory.FullPayment) {
          this.partyPurchaseForm.value.transactionAmount = this.calculatedTotalAmount
        }

        let partyPurchaseInvoiceData = {
          bussinessParty: this.partyPurchaseForm.value.bussinessParty,
          billNo: this.partyPurchaseForm.value.billNo,
          bussinessDate: this.partyPurchaseForm.value.bussinessDate,
          productPurchases: this.partyPurchaseForm.value.productPurchases,
          subTotalAmount: this.partyPurchaseForm.value.subTotalAmount,
          vatAmount: this.partyPurchaseForm.value.vatAmount,
          remarks: this.partyPurchaseForm.value.remarks,
          totalAmount: this.partyPurchaseForm.value.totalAmount,
        }

        let partyPurchassTransactionDatas = {
          bussinessParty: this.partyPurchaseForm.value.bussinessParty,
          transactionAmount: this.partyPurchaseForm.value.transactionAmount,
          transactionDate: this.partyPurchaseForm.value.transactionDate,
          billNo: this.partyPurchaseForm.value.billNo,
          paymentMethod: this.partyPurchaseForm.value.paymentMethod,
          remarks: this.partyPurchaseForm.value.transactionRemarks
        }
        const invoiceCreatedResponse = await this.partyPurchaseService.createPartyPurchase(partyPurchaseInvoiceData).toPromise();
        const transactionCreatedResponse = (this.selectedAmountStatus != TransactionCategory.NoPayment) &&
          invoiceCreatedResponse &&
          await this.partyPurchaseTransactionService.createPartyPurchaseTransaction(partyPurchassTransactionDatas).toPromise();

        if (invoiceCreatedResponse || (invoiceCreatedResponse && transactionCreatedResponse)) {
          this.toastr.success('Party invoice and transaction created successfully!', 'Success', {
            progressBar: true,
          });
          this.partyPurchaseForm.reset();
          this.showTransactionAmountInput = false;
          this.router.navigate(['/pages/partyPurchase']);
          this.loading = false;
        }
      }
    } catch (error) {
      this.toastr.error(error.error.message || "Something went wrong!", 'Error', {
        progressBar: true,
      });
      this.loading = false;
    }
  }








}
