<div class="auth option2">
  <div class="auth_left">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="signinForm" (ngSubmit)="onSubmit()">
          <div class="text-center">
            <a class="header-brand" href="#">
              <img src="../../../assets/images/genuine-computer-login-icon.png" alt="genuine-computer-logo" height="50">
            </a>
            <div class="card-title mt-3" i18n="@@loginToYourAccount"><b>Login to your account</b></div>
          </div>

          <div class="form-group">
            <label class="form-label" i18n="@@username">Username</label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-envelope" aria-hidden="true"></i>
                </div>
              </div>
              <input type="username" class="form-control" formControlName="username" id="login_username"
                aria-describedby="usernameHelp" i18n-placeholder placeholder="Enter username">
            </div>
          </div>



          <div class="form-group">
            <label class="form-label" i18n="@@password">Password</label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="{{ hide ? 'fa fa-eye' : 'fa fa-eye-slash' }}" aria-hidden="true"
                    (click)="hide = !hide"></i>
                </div>
              </div>
              <input type="password" class="form-control" formControlName="password" id="login_password"
                i18n-placeholder placeholder="Password" [type]="hide ? 'password': 'text' ">
            </div>
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-success btn-block" title="" i18n="@@signIn">Sign in</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>