import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerApis } from 'src/app/api.constants';
import { BranchDetail } from 'src/app/models/BranchDetail';
import { TransportService } from '../transport/transport.service';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(private transportService: TransportService, private httpClient: HttpClient) { }

  getBranchDetails(){
    return this.transportService.Read(`${ServerApis.branchDetailUrl}`);
  }

  getBranchById(id:string){
    return this.transportService.Read(`${ServerApis.branchDetailUrl}/${id}`);
  }

  createBranch(req){
    return this.transportService.Create(req,ServerApis.branchDetailUrl);
  }

  updateBranch(id: string, data){
    return this.httpClient.patch(`${ServerApis.branchDetailUrl}/${id}`, data);
  }


  
  deleteBranch(id:string){
    return this.transportService.Delete(`${ServerApis.branchDetailUrl}/${id}`);
  }
}
