<div class="modal-section">
    <div class="modal-header bg-custom">
        <h5 class="modal-title text-white"><i class="fa fa-money"></i>
            <span>
                Modify Payable Details
            </span>
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="modifyPayableAmountForm" (ngSubmit)="onSubmit()">
            <div class="card-body">
                <div class="clearfix row">
                    <div class="form-group col-sm-6 mb-3">
                        <label for="privilege" i18n="@@modifyPayableAmount"><b>Amount:</b><span
                                class="text-danger">*</span></label>
                        <br>

                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-file-text" aria-hidden="true"></i>
                                </div>
                            </div>
                            <input type="number" name="amount" class="form-control" formControlName="amount" min="0"
                                placeholder="Enter amount">
                        </div>
                        <small class="form-text text-danger"
                            *ngIf="modifyPayableAmountForm.controls.amount.hasError('required') && modifyPayableAmountForm.controls.amount.touched"
                            style="margin-top: -0.5rem;">
                            Amount is required!
                        </small>
                    </div>

                    <div class="form-group col-sm-6 mb-3">
                        <label for="privilege" i18n="@@billNumber"><b>Select Action</b><span
                                class="text-danger">*</span></label>
                        <br>

                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-file-text" aria-hidden="true"></i>
                                </div>
                            </div>
                            <select class="form-control" name="action" formControlName="action">
                                <option value="debit">Debit</option>
                                <option value="credit">Credit</option>
                            </select>
                        </div>
                        <small class="form-text text-danger"
                            *ngIf="modifyPayableAmountForm.controls.action.hasError('required') && modifyPayableAmountForm.controls.action.touched"
                            style="margin-top: -0.5rem;">
                            Action is required!
                        </small>

                    </div>


                </div>
            </div>
            <div class="modal-footer">
                <div class="col-sm-6">
                    <button type="submit" class="w-100 mt-2 btn btn-success" i18n="@@submit"
                        [disabled]="submitted || !modifyPayableAmountForm.valid">Submit <span
                            class="spinner-grow spinner-grow-sm" aria-hidden="true"
                            *ngIf="this.loading"></span></button>
                </div>

                <div class="col-sm-6">
                    <button type="reset" class="w-100 btn btn-danger mt-2" i18n="@@Close"
                        (click)="closeModal()">Close</button>
                </div>
            </div>
        </form>
    </div>

</div>