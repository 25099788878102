import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-headertop',
  templateUrl: './headertop.component.html',
  styleUrls: ['./headertop.component.scss']
})
export class HeadertopComponent implements OnInit {
  constructor(private authService:AuthService) { }

  ngOnInit(): void {
 
    
    
  }

  logout(){
    this.authService.logout();
  }

}
