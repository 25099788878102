import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductDetail } from 'src/app/models/ProductDetails';
import { ProductService } from 'src/app/services/product/product.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-add-edit-product',
  templateUrl: './add-edit-product.component.html',
  styleUrls: ['./add-edit-product.component.scss']
})
export class AddEditProductComponent implements OnInit {

  submitted: boolean = false;
  loading: boolean = false;
  @Input() isEditMode: boolean;
  @Input() isAddMode: boolean;
  @Input() productId: string;
  addEditProductDetail: FormGroup;
  public productDetail: ProductDetail[];
  public isVatApplicatbleData = [
    {
      name: "Yes",
      value: true
    },
    {
      name: "No",
      value: false
    }
  ]

  constructor(
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private activeModalService: NgbActiveModal, private toastr: ToastrService) {
  }


  ngOnInit(): void {
    this.addEditProductDetail = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      unitType: new FormControl('units'),
      isVatApplicable: new FormControl(false),
    });


    if (this.isEditMode === true) {
      this.productService
        .getProductById(this.productId)
        .pipe(first())
        .subscribe((data: any) =>
          this.addEditProductDetail.patchValue(data)
        );

    }
  }



  onSubmit() {
    this.loading = true;
    if (this.addEditProductDetail.invalid) {
      return;
    }
    if (this.isAddMode) {
      this.createProductDetail();
    } else {
      this.updateProductDetail();
    }
  }

  private createProductDetail() {
    let isVatApplicableData = (JSON.parse(this.addEditProductDetail.controls.isVatApplicable.value));
    this.addEditProductDetail.value.isVatApplicable = isVatApplicableData
    this.productService
      .createProduct(this.addEditProductDetail.value)
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          this.toastr.success('Product Detail Created Successfully!', 'Success', {
            progressBar: true,
          });
          this.addEditProductDetail.reset();
          this.closeModal();
          this.loading = false;

        },
        error: (error) => {
          this.toastr.error(error.error.message || "Something went wrong!", 'Error', {
            progressBar: true,
          });
          this.loading = false;

        },
      });

  }

  private updateProductDetail() {

    let editIsVatApplicableData = (JSON.parse(this.addEditProductDetail.controls.isVatApplicable.value));
    this.addEditProductDetail.value.isVatApplicable = editIsVatApplicableData
    this.productService
      .updateProduct(this.productId, this.addEditProductDetail.value)
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          this.toastr.success(res.message, 'Success', {
            progressBar: true,
          });
          this.closeModal();
          this.loading = false;

        },
        error: (error) => {
          this.toastr.error(error.error.message || "Something went wrong!", 'Error', {
            progressBar: true,
          });
          this.loading = false;

        },
      });
  }

  closeModal() {
    this.activeModalService.close();
  }


}
