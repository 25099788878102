import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { first } from 'rxjs/operators';
import { BranchDetail } from 'src/app/models/BranchDetail';
import { User } from 'src/app/models/User';
import { AccountService } from 'src/app/services/account/account.service';
import { BranchService } from 'src/app/services/branch/branch.service';
import { RoleType } from 'src/app/types/RoleType.type';

@Component({
  selector: 'app-add-edit-account',
  templateUrl: './add-edit-account.component.html',
  styleUrls: ['./add-edit-account.component.scss'],
})
export class AddEditAccountComponent implements OnInit {
  submitted: boolean = false;
  hide: boolean = true;
  @Input() isEditMode: boolean;
  @Input() isAddMode: boolean;
  addEditAccount: FormGroup;
  totalRow: number;
  @Input() businessPartyId: string;
  public account: User[] = [];
  public branchDetails: BranchDetail;
  public predefinedRoles: RoleType;

  public rolesData: Array<any> = [
    {
      id: 'admin',
      name: 'admin',
    },
    {
      id: 'staff',
      name: 'staff',
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private activeModalService: NgbActiveModal,
    private branchService: BranchService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.getAllBranch().then(null);
    this.addEditAccount = this.formBuilder.group({
      fullname: new FormControl('', [Validators.required]),
      branch: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
      contactNumber: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(20),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(20),
      ]),
      roles: this.formBuilder.array([]),
    });

    // if(this.isEditMode === true){
    //   this.accountService
    //     .getAccountById(this.businessPartyId)
    //     .pipe(first())
    //     .subscribe((data: any) =>{
    //       this.addEditAccount.patchValue(data)
    //     }

    //     );

    // }
  }

  public async getAllBranch(): Promise<void> {
    this.branchService.getBranchDetails().subscribe((BranchDatas: any) => {
      this.branchDetails = BranchDatas;
    });
  }

  // onCheckBoxChange(event) {
  //   const roles: FormArray = this.addEditAccount.get('roles') as FormArray;
  //   if (event.target.checked) {
  //     roles.push(new FormControl(event.target.value));
  //   }
  // }

  onSubmit() {
    if (this.addEditAccount.invalid) {
      return;
    }
    if (this.isAddMode === true) {
      this.createAccount();
    } else if (this.isEditMode === true) {
      // this.updateBusinessParty();
    } else {
      exit(0);
    }
  }

  private createAccount() {
    // this.accountService.createAccount(this.addEditAccount.value).pipe(first()).subscribe(({
    //   next: (_) => {
    //     this.toastr.success('Account created successfully!', 'Success', {
    //       progressBar: true,
    //     });
    //     this.closeModal();
    //   },
    //   error: (error) => {
    //     this.toastr.error(error.error.message || 'Something went wrong!', 'Error', {
    //     });
    //   },
    // }))
  }

  // private updateBusinessParty(){
  //   this.businessPartyService
  //   .updateBusinessParty(this.businessPartyId, this.addEditAccount.value)
  //   .pipe(first())
  //   .subscribe({
  //     next: (res: any) => {
  //       this.toastr.success(res.message, 'Success', {
  //         progressBar: true,
  //       });
  //       this.getAllBusinessParty();
  //       this.closeModal();
  //     },
  //     error: (error) => {
  //       this.toastr.error(error.error.message, 'Error', {
  //       });
  //     },
  //   });

  // }

  closeModal() {
    this.activeModalService.close();
  }
}
