<div class="modal-section">
    <div class="modal-header bg-custom">
        <h5 class="modal-title text-white"><i class="fa fa-briefcase"></i>
            <span>
                Add Customer Transaction
            </span>
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="addCustomerTransaction" (ngSubmit)="onSubmit()">
            <div class="card-body">
                <div class="clearfix row">

                    <div class="form-group col-sm-12 mb-3" *ngIf="this.customerTransactionId === null">
                        <label for="privilege" i18n="@@party"><b>Customer:</b><span class="text-danger">*</span></label>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-users" aria-hidden="true"></i>
                                </div>
                            </div>
                            <select i18n-placeholder="@@customer" class="form-control" name="customer"
                                formControlName="customer">
                                <option value="" selected="true" disabled="true">Select Customer</option>
                                <option *ngFor="let getAllCustomerInfos of getAllCustomer" [id]="getAllCustomerInfos.id"
                                    [value]="getAllCustomerInfos.id">
                                    {{ getAllCustomerInfos.name }}
                                </option>
                            </select>
                        </div>

                        <small class="form-text text-danger"
                            *ngIf="addCustomerTransaction.controls.customer.hasError('required') && addCustomerTransaction.controls.customer.touched"
                            style="margin-top: -0.5rem;">
                            Customer is required!
                        </small>
                    </div>

                    <div class="form-group col-sm-6 mb-3">
                        <label for="privilege" i18n="@@invoiceNo"><b>Invoice Number:</b></label>
                        <br>

                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-file-text" aria-hidden="true"></i>
                                </div>
                            </div>
                            <input type="text" name="invoiceNo" class="form-control" formControlName="invoiceNo"
                                placeholder="Enter invoice number">
                        </div>

                    </div>

                    <div class="form-group col-sm-6 mb-3">
                        <label for="privilege" i18n="@@transactionDate"><b>Transaction Date:</b><span
                                class="text-danger">*</span></label>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i>
                                </div>
                            </div>
                            <input type="date" class="form-control" name="transactionDate"
                                formControlName="transactionDate" i18n-placeholder placeholder="Enter transaction Date">
                        </div>
                        <small class="form-text text-danger"
                            *ngIf="addCustomerTransaction.controls.transactionDate.hasError('required') && addCustomerTransaction.controls.transactionDate.touched"
                            style="margin-top: -0.5rem;">
                            Transaction date is required!
                        </small>
                    </div>

                    <div class="form-group col-sm-6 mb-3">
                        <label for="privilege" i18n="@@billNumber"><b>Select Payment Method</b><span
                                class="text-danger">*</span></label>
                        <br>

                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-file-text" aria-hidden="true"></i>
                                </div>
                            </div>
                            <select class="form-control" name="paymentMethod" formControlName="paymentMethod">
                                <option value="Cash">Cash</option>
                                <option value="Web">Web</option>
                                <option value="Check">Cheque</option>
                            </select>
                        </div>
                        <small class="form-text text-danger"
                            *ngIf="addCustomerTransaction.controls.paymentMethod.hasError('required') && addCustomerTransaction.controls.paymentMethod.touched"
                            style="margin-top: -0.5rem;">
                            Payment method is required!
                        </small>

                    </div>

                    <div class="form-group col-sm-6 mb-3">
                        <label for="privilege" i18n="@@transactionAmount"><b>Transaction Amount:</b><span
                                class="text-danger">*</span></label>
                        <br>

                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-file-text" aria-hidden="true"></i>
                                </div>
                            </div>
                            <input type="number" name="transactionAmount" class="form-control"
                                formControlName="transactionAmount" placeholder="Enter transaction amount">
                        </div>


                        <small class="form-text text-danger"
                            *ngIf="addCustomerTransaction.controls.transactionAmount.hasError('required') && addCustomerTransaction.controls.transactionAmount.touched"
                            style="margin-top: -0.5rem;">
                            Transaction amount is required!
                        </small>

                        <small class="form-text text-danger"
                            *ngIf="addCustomerTransaction.controls.transactionAmount.hasError('min') && addCustomerTransaction.controls.transactionAmount.touched"
                            style="margin-top: -0.5rem;">
                            Transaction amount should be at least 10!
                        </small>


                    </div>





                    <div class="col-md-12">
                        <label for="privilege" i18n="@@remarks"><b>Remarks:</b></label> <br>

                        <div class="input-group mb-2">
                            <textarea id="remarks" class="form-control" formControlName="remarks"
                                placeholder="Enter remarks" name="remarks" rows="4" cols="20"></textarea>
                        </div>

                    </div>


                </div>
            </div>
            <div class="modal-footer">
                <div class="col-sm-6">
                    <button type="submit" class="w-100 mt-2 btn btn-success" i18n="@@submit"
                        [disabled]="submitted || !addCustomerTransaction.valid">Submit <span
                            class="spinner-grow spinner-grow-sm" aria-hidden="true"
                            *ngIf="this.loading"></span></button>
                </div>

                <div class="col-sm-6">
                    <button type="reset" class="w-100 btn btn-danger mt-2" i18n="@@Close"
                        (click)="closeModal()">Close</button>
                </div>
            </div>
        </form>
    </div>

</div>