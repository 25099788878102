import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PartyPurchaseTransaction } from 'src/app/models/PartyPurchaseTransaction';
import { PartyPurchaseTransactionService } from 'src/app/services/party-purchase-transactions/party-purchase-transaction.service';

@Component({
  selector: 'app-view-party-transaction-by-id',
  templateUrl: './view-party-transaction-by-id.component.html',
  styleUrls: ['./view-party-transaction-by-id.component.scss']
})
export class ViewPartyTransactionByIdComponent implements OnInit {

  @Input() businessPartyTransactionId: string;
  public businessPartyPurchaseTransaction: PartyPurchaseTransaction;

  constructor(private partyPurchaseTransactionService: PartyPurchaseTransactionService, private activeModalService: NgbActiveModal, toastrService: ToastrService) { }

  ngOnInit(): void {
    this.getInterentBusinessPartyTransactionById().then(null)
  }

  public async getInterentBusinessPartyTransactionById(): Promise<void> {
    this.partyPurchaseTransactionService.getBusinessPartyTransactionById(this.businessPartyTransactionId).subscribe((getPartyTransactionData: any) => {
      this.businessPartyPurchaseTransaction = getPartyTransactionData;
    })
  }

  closeModal() {
    this.activeModalService.close();
  }

}
