import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-logged-in-user-profile',
  templateUrl: './logged-in-user-profile.component.html',
  styleUrls: ['./logged-in-user-profile.component.scss']
})
export class LoggedInUserProfileComponent implements OnInit {
  public loggedInUserDetail: User;
  constructor(private authService: AuthService, private activeModalService: NgbActiveModal,
  ) { }

  closeModal() {
    this.activeModalService.close();
  }

  ngOnInit(): void {
    this.authService.loggedInUserDetails().subscribe((getLoggedInUserData: any) => {
      this.loggedInUserDetail = getLoggedInUserData;

    })
  }

}
