<div class="modal-section">
  <div class="modal-header bg-custom">
    <h5 class="modal-title text-white"><i class="fa fa-briefcase"></i>
      <span *ngIf="isAddMode">
        Add Business Party
      </span>
      <span *ngIf="isEditMode">
        Edit Business Party
      </span>
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="addEditBusinessParty" (ngSubmit)="onSubmit()">
      <div class="card-body">
        <div class="clearfix row">

          <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold"> Name:<span class="text-danger">*</span></span>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-user" aria-hidden="true"></i>
                </div>
              </div>
              <input type="text" class="form-control" i18n-placeholder="@@code" placeholder="Enter name" name="name"
                formControlName="name" required>
            </div>
            <div *ngFor="let codeErrorValidationDatas of codeErrorValidation">
              <small class="form-text text-danger"
                *ngIf="addEditBusinessParty.controls.name.hasError(codeErrorValidationDatas.errType) && addEditBusinessParty.controls.name.touched"
                style="margin-top: -5px">
                {{codeErrorValidationDatas.errorMsg }}
              </small>
            </div>
          </div>

          <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold"> Location:<span
                class="text-danger">*</span></span>

            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-map-marker" aria-hidden="true"></i>
                </div>
              </div>
              <input type="text" class="form-control" i18n-placeholder="@@location" placeholder="Enter location"
                name="location" formControlName="location" required>
            </div>


            <div *ngFor="let locationErrorValidationDatas of locationErrorValidation">
              <small class="form-text text-danger"
                *ngIf="addEditBusinessParty.controls.location.hasError(locationErrorValidationDatas.errType) && addEditBusinessParty.controls.location.touched"
                style="margin-top: -5px;">
                {{locationErrorValidationDatas.errorMsg }}
              </small>
            </div>
          </div>

          <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold"> Contact Person:<span
                class="text-danger">*</span></span>

            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-user" aria-hidden="true"></i>
                </div>
              </div>
              <input type="text" class="form-control" i18n-placeholder="@@contactPerson"
                placeholder="Enter contact person" name="contactPerson" formControlName="contactPerson" required>
            </div>


            <small class="form-text text-danger"
              *ngIf="addEditBusinessParty.controls.contactPerson.hasError('required') && addEditBusinessParty.controls.contactPerson.touched"
              style="margin-top: -5px;">
              Contact person is required!
            </small>
          </div>

          <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold"> Contact Number:<span
                class="text-danger">*</span></span>

            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-phone" aria-hidden="true"></i>
                </div>
              </div>
              <input type="text" class="form-control" i18n-placeholder="@@contactNumber"
                placeholder="Enter contact number" name="contactNumber" formControlName="contactNumber" required>
            </div>
            <small class="form-text text-danger"
              *ngIf="addEditBusinessParty.controls.contactNumber.hasError('required') && addEditBusinessParty.controls.contactNumber.touched"
              style="margin-top: -5px;">
              Contact number is required!
            </small>
          </div>


        </div>
      </div>
      <div class="modal-footer">
        <div class="col-sm-6">
          <button *ngIf="isAddMode" type="submit" class="w-100 mt-2 btn btn-success" i18n="@@submit"
            [disabled]="submitted || !addEditBusinessParty.valid">Submit <span class="spinner-grow spinner-grow-sm"
              aria-hidden="true" *ngIf="this.loading"></span></button>
          <button *ngIf="isEditMode" type="submit" class="w-100 mt-2 btn btn-success" i18n="@@update"
            [disabled]="submitted || !addEditBusinessParty.valid">Update <span class="spinner-grow spinner-grow-sm"
              aria-hidden="true" *ngIf="this.loading"></span></button>
        </div>

        <div class="col-sm-6">
          <button type="reset" class="w-100 btn btn-danger mt-2" i18n="@@Close" (click)="closeModal()">Close</button>
        </div>
      </div>
    </form>
  </div>

</div>