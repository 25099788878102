<div class="section-body main-no-table">
  <div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center" id="breadcumb-section-part">
      <div class="header-action d-flex">
        <h1 class="page-title" i18n="@@partyPurchse">Purchase Invoice</h1>
        <ol class="breadcrumb page-breadcrumb mt-2">
          <li class="breadcrumb-item">
            <a [routerLink]="['/pages/dashboard']">( Dashboard )</a>
          </li>
        </ol>
      </div>

      <div class="search-and-btns d-flex float-right">
        <div class="row mr-3" id="search-bar-sec">
          <div class="input-group border ml-2 mt-1" id="search-bar">
            <div class="input-group-prepend">
              <button id="button-addon4" type="button" class="btn btn-link text-info border-0"><i
                  class="fa fa-search"></i></button>
            </div>
            <input type="search" placeholder="Search here" class="form-control bg-none border-0"
              (keyup)="getValue($event)">
          </div>
        </div>


        <ul class="nav nav-tabs page-header-tab" id="page-header-update">
          <li class="nav-item">
            <a id="partyPurchase-all-Link" class="nav-link partyPurchaseListViewTab active" data-toggle="tab"
              href="#partyPurchase-all" i18n="@@listOfPartyPurchase"><i class="fa fa-list"></i>List
            </a>
          </li>
          <li class="nav-item">
            <a id="partyPurchase-add-Link" type="button" class="nav-link partyPurchaseAddTab" data-toggle="tab"
              [routerLink]="['/pages/addPartyPurchase']" i18n="@@addPartyPurchase"><i class="fa fa-plus-circle"></i>
              Add</a>
          </li>
          <li class="nav-item">
            <button type="button" class="btn btn-md btn-primary" (click)="exportAsXLSX()" style="margin-top: 5px;"><i
                class="fa fa-file-excel-o"></i> Export</button>
          </li>
        </ul>
      </div>


    </div>
  </div>
  <div class="container-fluid">
    <div class="tab-content">
      <div class="tab-pane active" id="partyPurchase-all">
        <div class="row">
        </div>
        <div class="table-responsive mt-2">
          <div class="table-responsive card">



            <table class="table text-center table-vcenter text-nowrap mb-0">
              <thead class="thead-dark">
                <tr>
                  <th i18n="@@sn">S.N</th>
                  <th i18n="@@businessParty">Business Party</th>
                  <th i18n="@@billNumber">Bill Number</th>
                  <th i18n="@@businessDate">Business Date</th>
                  <th i18n="@@totalAmount">Total Amount</th>
                  <th i18n="@@action">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="partyPurchaseDetails.length == 0">
                  <td class="text-center" colspan="6" *ngIf="this.displayLoader">
                    <div class="spinner-grow text-secondary mt-3" role="status">
                    </div>
                    <div class="spinner-grow text-secondary mt-3" role="status">
                    </div>
                    <div class="spinner-grow text-secondary mt-3" role="status">
                    </div>
                    <div class="spinner-grow text-secondary mt-3" role="status">
                    </div>
                    <div class="mt-2">
                      <h6 scope="col gray-ish">Please Wait</h6>
                    </div>
                  </td>

                  <td class="text-muted text-center" colspan="6" *ngIf="this.dispalyNotFoundData">
                    No Party Purchase Details Found.
                  </td>
                </tr>
                <tr *ngFor="let partyPurchaseDetailsDatas of partyPurchaseDetails | paginate : {
                    itemsPerPage: pageSize,
                    currentPage: page,totalItems:totalCount
                    }; index as i">
                  <td>{{ (pageNo * pageSize) + i + 1 }}</td>
                  <td>{{ partyPurchaseDetailsDatas.bussinessParty.name ? partyPurchaseDetailsDatas.bussinessParty.name :
                    '----'}}</td>
                  <td>{{ partyPurchaseDetailsDatas.billNo ? partyPurchaseDetailsDatas.billNo : '----' }}</td>
                  <td>{{ partyPurchaseDetailsDatas.bussinessDate | date }}</td>
                  <td>{{ partyPurchaseDetailsDatas.totalAmount |
                    currency:' ':'symbol':''}}</td>

                  <td>
                    <button [routerLink]="['/pages/partyPurchaseView/' + partyPurchaseDetailsDatas.id]" type="button"
                      class="btn btn-success btn-sm rounded-circle mr-1">
                      <i class="fa fa-eye"></i>
                    </button>
                    <button (click)="deletePartyPurchase(partyPurchaseDetailsDatas.id)" type="button"
                      class="btn btn-danger btn-sm rounded-circle js-sweetalert" data-type="confirm">
                      <i class="fa fa-trash-o"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6" style="margin-right: auto;">
            <pagination-controls (pageChange)="pageChange($event)">
            </pagination-controls>
          </div>

          <div class="col-md-3" style="margin-left: auto;">
            <select (change)="noOfRecordsSizeChange()" class="form-control" [(ngModel)]="pageSize">
              <option *ngFor="let size of noOfRecordsSizesArr" [ngValue]="size">
                {{ size }}
              </option>
            </select>
          </div>
        </div>

      </div>
    </div>
  </div>


</div>