import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerApis } from '../api.constants';
import { TransportService } from './transport/transport.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerInvoiceService {

  constructor(private transportService: TransportService, private httpClient: HttpClient) { }

  getCustomerInvoicePagination(pageSize: number, pageNumber: number) {
    return this.transportService.Read(`${ServerApis.customerInvoiceUrl + `?size=${pageSize}&page=${pageNumber}`}`);

  }

  getCustomerInvoiceById(id: string) {
    return this.transportService.Read(`${ServerApis.customerInvoiceUrl}/${id}`);
  }

  createCustomerInvoice(req) {
    return this.transportService.Create(req, ServerApis.customerInvoiceUrl);
  }

  createCustomerTransaction(req) {
    return this.transportService.Create(req, ServerApis.customerInvoiceTransactionUrl);
  }

  getCustomerTransactionById(id: string) {
    return this.transportService.Read(`${ServerApis.customerInvoiceTransactionUrl}/${id}`);
  }

  updateCustomerInvoice(id: string, data) {
    return this.httpClient.patch(`${ServerApis.customerInvoiceUrl}/${id}`, data);
  }



  deleteCustomerInvoice(id: string) {
    return this.transportService.Delete(`${ServerApis.customerInvoiceUrl}/${id}`);
  }


  searchCustomerInviceData(pageSize: number, pageNo: number, query: string) {
    return this.transportService.Read(
      ServerApis.customerInvoiceUrl +
      `?size=${pageSize}&page=${pageNo}&search=${query}`
    );
  }

  searchCustomerInvoiceByDate(pageSize: number, pageNo: number, query: string = '', startDate: string = '', endDate: string = '', customer: string) {
    return this.transportService.Read(
      ServerApis.customerInvoiceUrl +
      `?size=${pageSize}&page=${pageNo}&search=${query}&startDate=${startDate}&endDate=${endDate}&customer=${customer}`
    );
  }

  searchCustomerTransactions(pageSize: number, pageNo: number, query: string = '') {
    return this.transportService.Read(
      ServerApis.customerInvoiceTransactionUrl +
      `?size=${pageSize}&page=${pageNo}&search=${query}`
    );
  }

  searchCustomerTransactionsByDate(pageSize: number, pageNo: number, query: string = '', startDate: string = '', endDate: string = '', customer: string) {
    return this.transportService.Read(
      ServerApis.customerInvoiceTransactionUrl +
      `?size=${pageSize}&page=${pageNo}&search=${query}&startDate=${startDate}&endDate=${endDate}&customer=${customer}`
    );
  }

}
