export const ConstantFile = {
  internetModuleUrl: 'http://internet.pradipbhattarai.com.np',

  //  for products
  isVatApplicatbleData: [
    {
      name: 'Yes',
      value: true,
    },
    {
      name: 'No',
      value: false,
    },
  ],

  //   for purchase transaction
  paymentMethods: [
    {
      name: 'Cash',
      value: 'Cash',
    },
    {
      name: 'Web',
      value: 'Web',
    },
    {
      name: 'Cheque',
      value: 'check',
    },
  ],

  // For Customer Types
  customerType: [{}],
};
