<div class="modal-section">
    <div class="modal-header bg-custom">
        <h5 class="modal-title text-white">
            <i class="fa fa-money"></i>
            <span> Customer Transaction </span>
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="modal-body mx-4">
            <div class="row m-0 mb-3"><span class="font-weight-bold col-md-6">Name:</span>
                <span class="col">{{this.customerTransactionData?.customer.name ?
                    this.customerTransactionData?.customer.name : "- - -
                    - "}}</span>
            </div>

            <div class="row m-0 mb-3"><span class="font-weight-bold col-md-6"> Address:</span>
                <span class="col">{{this.customerTransactionData?.customer.address ?
                    this.customerTransactionData?.customer.address : "- - -
                    - "}}</span>
            </div>

            <div class="row m-0 mb-3"><span class="font-weight-bold col-md-6"> Number:</span>
                <span class="col">{{this.customerTransactionData?.customer.contactNumber ?
                    this.customerTransactionData?.customer.contactNumber : "- - -
                    - "}}</span>
            </div>

            <div class="row m-0 mb-3"><span class="font-weight-bold col-md-6"> Invoice Number:</span>
                <span class="col">{{this.customerTransactionData?.invoiceNo ?
                    this.customerTransactionData?.invoiceNo : "- - -
                    - "}}</span>
            </div>

            <div class="row m-0 mb-3"><span class="font-weight-bold col-md-6"> Payment Method:</span>
                <span class="col">{{this.customerTransactionData?.paymentMethod ?
                    this.customerTransactionData?.paymentMethod : "- - -
                    - "}}</span>
            </div>

            <div class="row m-0 mb-3"><span class="font-weight-bold col-md-6"> Transaction Amount:</span>
                <span class="col">{{this.customerTransactionData?.transactionAmount ?
                    this.customerTransactionData?.transactionAmount : "- - -
                    - "}}</span>
            </div>

            <div class="row m-0 mb-3"><span class="font-weight-bold col-md-6"> Transaction Date:</span>
                <span class="col">{{this.customerTransactionData?.transactionDate | date}}</span>
            </div>

            <div class="row m-0 mb-3"><span class="font-weight-bold col-md-6"> Remarks:</span>
                <span class="col">{{this.customerTransactionData?.remarks ?
                    this.customerTransactionData?.remarks : "- - -
                    - "}}</span>
            </div>

            <div class="modal-footer">
                <div class="col-sm-12">
                    <button type="reset" class="w-100 btn btn-danger mt-2" i18n="@@Close" (click)="closeModal()">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>