<div class="section-body main-no-table">
  <div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center">
      <div class="header-action d-flex">
        <h1 class="page-title" i18n="@@branchDetails">Branch</h1>
        <ol class="breadcrumb page-breadcrumb mt-2">
          <li class="breadcrumb-item">
            <a [routerLink]="['/pages/dashboard']">( Dashboard</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page" i18n="@@branchDetails">
            Branch Details )
          </li>
        </ol>
      </div>
      <ul class="nav nav-tabs page-header-tab" id="page-header-update">
        <!-- <div class="row ">
          <div class="input-group border mt-2" id="search-bar">
            <div class="input-group-prepend">
              <button id="button-addon4" type="button" class="btn btn-link text-info border-0"><i
                  class="fa fa-search"></i></button>
            </div>
            <input type="search" placeholder="Search here" class="form-control bg-none border-0">
          </div>
        </div> -->
      </ul>

      <div class="row export-and-add-btns">
        <button type="button" class="btn btn-md btn-success mt-2 mr-1" (click)="exportAsXLSX()"><i
            class="fa fa-file-excel-o"></i> Export</button>

        <button type="button" class="btn btn-md btn-primary mt-2" (click)="addBranchDetail()"><i
            class="fa fa-plus-circle"></i> Add</button>
      </div>

    </div>
  </div>
  <div class="container-fluid">
    <div class="tab-content">
      <div class="tab-pane active" id="branchDetails-all">
        <div class="table-responsive mt-2">
          <div class="table-responsive card">
            <table class="table text-center text-nowrap mb-0">
              <thead class="thead-dark">
                <tr>
                  <th i18n="@@sn">S.N</th>
                  <th i18n="@@code">Code</th>
                  <th i18n="@@location">Location</th>
                  <th i18n="@@action">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="branchDetails.length == 0">
                  <td class="text-center" colspan="4" *ngIf="this.displayLoader">
                    <div class="spinner-grow text-secondary mt-3" role="status">
                    </div>
                    <div class="spinner-grow text-secondary mt-3" role="status">
                    </div>
                    <div class="spinner-grow text-secondary mt-3" role="status">
                    </div>
                    <div class="spinner-grow text-secondary mt-3" role="status">
                    </div>
                    <div class="mt-2">
                      <h6 scope="col gray-ish">Please Wait</h6>
                    </div>
                  </td>

                  <td class="text-muted text-center" colspan="4" *ngIf="this.dispalyNotFoundData">
                    No Branch Details Found.
                  </td>
                </tr>
                <tr *ngFor="let branchDetailsDatas of branchDetails; index as i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ branchDetailsDatas.code }}</td>
                  <td>{{ branchDetailsDatas.location }}</td>
                  <td>
                    <button (click)="editBranchDetail(branchDetailsDatas.id)" type="button"
                      class="btn btn-secondary btn-sm rounded-circle mr-1">
                      <i class="fa fa-edit"></i>
                    </button>
                    <button (click)="deleteBranch(branchDetailsDatas.id)" type="button"
                      class="btn btn-danger rounded-circle btn-sm js-sweetalert" data-type="confirm">
                      <i class="fa fa-trash-o"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>