<div class="modal-section">
    <div class="modal-header bg-custom">
        <h5 class="modal-title text-white"><i class="fa fa-user"></i>
            <span *ngIf="isAddMode">
                Add Customer
            </span>
            <span *ngIf="isEditMode">
                Edit Customer
            </span>
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="customerForm" (ngSubmit)="onSubmit()">
            <div class="card-body">
                <div class="clearfix row">
                    <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold"> Name:<span
                                class="text-danger">*</span></span>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-user" aria-hidden="true"></i>
                                </div>
                            </div>
                            <input type="text" class="form-control" i18n-placeholder="@@name"
                                placeholder="Enter customer name" name="name" formControlName="name" required>
                        </div>
                        <small class="form-text text-danger"
                            *ngIf="customerForm.controls.name.hasError('required') && customerForm.controls.name.touched"
                            style="margin-top: -5px;">
                            Customer name is required!
                        </small>

                    </div>

                    <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold">Contact Number:<span
                                class="text-danger">*</span></span>

                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-phone" aria-hidden="true"></i>
                                </div>
                            </div>
                            <input type="text" class="form-control" i18n-placeholder="@@contactNumber"
                                placeholder="Enter contactNumber" name="contactNumber" formControlName="contactNumber"
                                required>
                        </div>

                        <small class="form-text text-danger"
                            *ngIf="customerForm.controls.contactNumber.hasError('required') && customerForm.controls.contactNumber.touched"
                            style="margin-top: -5px;">
                            Contact number is required!
                        </small>
                    </div>

                    <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold"> Address:<span
                                class="text-danger">*</span></span>

                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-map-marker" aria-hidden="true"></i>
                                </div>
                            </div>
                            <input type="text" class="form-control" i18n-placeholder="@@address"
                                placeholder="Enter address" name="address" formControlName="address" required>
                        </div>


                        <small class="form-text text-danger"
                            *ngIf="customerForm.controls.address.hasError('required') && customerForm.controls.address.touched"
                            style="margin-top: -5px;">
                            Address is required!
                        </small>
                    </div>

                    <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold">Customer Type:<span
                                class="text-danger">*</span></span>

                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-user" aria-hidden="true"></i>
                                </div>
                            </div>
                            <select class="form-control" name="customerType" formControlName="customerType">
                                <option disabled="true">Select customer type</option>
                                <option value="Individual">Individual </option>
                                <option value="Organization">Organization </option>
                            </select>
                        </div>
                        <small class="form-text text-danger"
                            *ngIf="customerForm.controls.customerType.hasError('required') && customerForm.controls.customerType.touched"
                            style="margin-top: -5px;">
                            Customer type is required!
                        </small>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-sm-6">
                        <button *ngIf="isAddMode" type="submit" class="w-100 mt-2 btn btn-success" i18n="@@submit"
                            [disabled]="submitted || !customerForm.valid">Submit <span
                                class="spinner-grow spinner-grow-sm" aria-hidden="true"
                                *ngIf="this.loading"></span></button>
                        <button *ngIf="isEditMode" type="submit" class="w-100 mt-2 btn btn-success" i18n="@@update"
                            [disabled]="submitted || !customerForm.valid">Update <span
                                class="spinner-grow spinner-grow-sm" aria-hidden="true"
                                *ngIf="this.loading"></span></button>
                    </div>

                    <div class="col-sm-6">
                        <button type="reset" class="w-100 btn btn-danger mt-2" i18n="@@Close"
                            (click)="closeModal()">Close</button>
                    </div>
                </div>
            </div>
        </form>
    </div>

</div>