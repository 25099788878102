import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ProductDetail } from 'src/app/models/ProductDetails';
import { ExportTableDataService } from 'src/app/services/export-table-data/export-table-data.service';
import { ProductService } from 'src/app/services/product/product.service';
import Swal from 'sweetalert2';
import { AddEditProductComponent } from './add-edit-product/add-edit-product.component';
import { ViewProductPriceComponent } from './view-product-price/view-product-price.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  public productDetails: ProductDetail[] = [];
  page: number = 1;
  noOfRecordsSizesArr = [10, 20, 30];
  pageNo = 0;
  pageSize = 10;
  totalCount = 0;
  public searchedValues: string;
  displayLoader: boolean = true;
  dispalyNotFoundData: boolean = false;

  constructor(
    private productService: ProductService,
    private toastr: ToastrService,
    private exportTableDataService: ExportTableDataService,
    private modalService: NgbModal,
  ) {}

  public async getAllProducts(): Promise<void> {
    this.productService
      .getProductDetailsByPagination(this.pageSize, this.pageNo)
      .subscribe(
        (productDatas: any) => {
          const paginationDetails = productDatas.pagination;
          this.page = paginationDetails.page + 1;
          this.totalCount = this.pageSize * paginationDetails.lastPage;
          this.productDetails = productDatas.products;
          if (this.productDetails?.length === 0) {
            this.displayLoader = false;
            this.dispalyNotFoundData = true;
          }
        },
        (error) => {
          this.toastr.error(
            error.error.message || 'Something went wrong!',
            'Error',
            {
              progressBar: true,
            },
          );
          this.displayLoader = false;
          this.dispalyNotFoundData = true;
        },
      );
  }

  addProductDetail() {
    const modalRef = this.modalService.open(AddEditProductComponent);
    modalRef.componentInstance.isAddMode = true;
    modalRef.result.then(() => {
      this.getAllProducts();
    });
  }

  editProductDetail(id: string) {
    const modalRef = this.modalService.open(AddEditProductComponent);
    modalRef.componentInstance.productId = id;
    modalRef.componentInstance.isEditMode = true;
    modalRef.result.then(() => {
      this.getAllProducts();
    });
  }

  viewProductPrice(id: string) {
    const modalRef = this.modalService.open(ViewProductPriceComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.productPriceId = id;
  }

  deleteProduct(productId) {
    Swal.fire({
      title: 'Are you sure?',
      // text: 'You will not be able to revert this process!',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'DELETE',
      cancelButtonText: 'CANCEL',
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.deleteProduct(productId).subscribe(
          (res: any) => {
            Swal.fire('Deleted!', res.message, 'success');
            this.getAllProducts();
          },
          (error) => {
            Swal.fire('Error', error.error.message, 'error');
          },
        );
      }
    });
  }

  getValue(event: any) {
    this.searchedValues = event.target.value;

    if (this.searchedValues.length >= 2) {
      this.productService
        .searchProduct(this.pageSize, this.pageNo, this.searchedValues)
        .subscribe((data: any) => {
          const paginationDetails = data.pagination;
          this.page = paginationDetails.page + 1;
          this.totalCount = this.pageSize * paginationDetails.lastPage;
          this.productDetails = data.products;
          if (this.productDetails?.length === 0) {
            this.displayLoader = false;
            this.dispalyNotFoundData = true;
          }
        });
    } else if (this.searchedValues == '') {
      this.getAllProducts();
    }
  }

  exportAsXLSX(): void {
    this.exportTableDataService.exportAsExcelFile(
      this.productDetails,
      'product-details',
    );
  }

  noOfRecordsSizeChange(): void {
    this.getAllProducts();
  }

  pageChange(event) {
    this.page = event;
    this.pageNo = this.page - 1;
    this.getAllProducts();
  }

  ngOnInit(): void {
    this.getAllProducts().then(null);
  }
}
