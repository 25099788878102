import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerApis } from 'src/app/api.constants';
import { TransportService } from '../transport/transport.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessPartyService {

  constructor(private transportService: TransportService, private httpClient: HttpClient) { }

  getBusinessPartyByPagination(pageSize: number, pageNumber: number) {
    return this.transportService.Read(`${ServerApis.businessPartyUrl + `?size=${pageSize}&page=${pageNumber}`}`);
  }

  getBusinessPartyDetails() {
    return this.transportService.Read(`${ServerApis.businessPartyBasicListingUrl}`);
  }

  getBusinessPartyById(id: string) {
    return this.transportService.Read(`${ServerApis.businessPartyUrl}/${id}`);
  }

  createBusinessParty(req) {
    return this.transportService.Create(req, ServerApis.businessPartyUrl);
  }

  updateBusinessParty(id: string, data) {
    return this.httpClient.patch(`${ServerApis.businessPartyUrl}/${id}`, data);
  }



  deleteBusinessParty(id: string) {
    return this.transportService.Delete(`${ServerApis.businessPartyUrl}/${id}`);
  }


  searchBusinessParty(pageSize: number, pageNo: number, query: string) {
    return this.transportService.Read(
      ServerApis.businessPartyUrl +
      `?size=${pageSize}&page=${pageNo}&search=${query}`
    );
  }

}
