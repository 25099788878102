import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HasRoleGuard } from '../helpers/has-role.guard';
import { AccountComponent } from './account/account.component';
import { AddEditBranchComponent } from './branch/add-edit-branch/add-edit-branch.component';
import { BranchComponent } from './branch/branch.component';
import { PartyPurchaseTransactionComponent } from './business-party/business-party-transaction/party-purchase-transaction/party-purchase-transaction.component';
import { BusinessPartyComponent } from './business-party/business-party.component';
import { ViewBusinessPartyTransactionsComponent } from './business-party/view-business-party-transactions/view-business-party-transactions.component';
import { AddCustomerInvoiceComponent } from './customer/add-customer-invoice/add-customer-invoice.component';
import { CustomerInvoiceComponent } from './customer/customer-invoice/customer-invoice.component';
import { ViewCustomerInvoiceComponent } from './customer/customer-invoice/view-customer-invoice/view-customer-invoice.component';
import { ViewCustomerTransactionsComponent } from './customer/customer-invoice/view-customer-transactions/view-customer-transactions.component';
import { CustomerInvoiceTransactionComponent } from './customer/customer-transaction/customer-invoice-transaction/customer-invoice-transaction.component';
import { CustomerComponent } from './customer/customer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AddEditPartyPurchaseComponent } from './party-purchase/add-edit-party-purchase/add-edit-party-purchase.component';
import { PartyPurchaseComponent } from './party-purchase/party-purchase.component';
import { ViewPartyPurchaseInvoiceComponent } from './party-purchase/view-party-purchase-invoice/view-party-purchase-invoice.component';
import { AddEditProductComponent } from './product/add-edit-product/add-edit-product.component';
import { ProductComponent } from './product/product.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'products',
        component: ProductComponent,
      },
      {
        path: 'branch',
        component: BranchComponent,
      },
      {
        // canActivate: [HasRoleGuard],
        path: 'businessParty',
        component: BusinessPartyComponent,
        // data: {
        //   role: 'admin',
        // },
      },
      {
        canActivate: [HasRoleGuard],
        path: 'businessPartyTransactions/:id',
        component: ViewBusinessPartyTransactionsComponent,
        data: {
          role: 'admin',
        },
      },
      {
        // canActivate: [HasRoleGuard],
        // data: {
        //   role: 'admin',
        // },
        path: 'partyPurchase',
        component: PartyPurchaseComponent,
      },
      {
        path: 'addPartyPurchase',
        component: AddEditPartyPurchaseComponent,
      },
      {
        // canActivate: [HasRoleGuard],
        // data: {
        //   role: 'admin',
        // },
        path: 'partyPurchaseView/:id',
        component: ViewPartyPurchaseInvoiceComponent,
      },
      {
        canActivate: [HasRoleGuard],
        path: 'partyInvoiceTransaction',
        component: PartyPurchaseTransactionComponent,
        data: {
          role: 'admin',
        },
      },

      {
        path: 'customers',
        component: CustomerComponent,
      },
      {
        path: 'customerInvoice',
        component: CustomerInvoiceComponent,
      },
      {
        path: 'viewCustomerTransactions/:id',
        component: ViewCustomerTransactionsComponent,
      },
      {
        path: 'addCustomerInvoice',
        component: AddCustomerInvoiceComponent,
      },
      {
        path: 'viewCusotmerInvoice/:id',
        component: ViewCustomerInvoiceComponent,
      },
      {
        path: 'customerInvoiceTransaction',
        component: CustomerInvoiceTransactionComponent,
      },
      {
        path: 'account',
        component: AccountComponent,
      },
      {
        path: '404-not-found',
        component: PageNotFoundComponent,
      },
      {
        path: '**',
        redirectTo: '404-not-found',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
