import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { subMonths } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/models/Customer';
import { CustomerTransaction } from 'src/app/models/CustomerTransaction';
import { CustomerInvoiceService } from 'src/app/services/customer-invoice.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { AddCustomerTransactionComponent } from '../../add-customer-transaction/add-customer-transaction.component';
import { ViewCustomerInvoiceTransactionByIdComponent } from '../../customer-transaction/view-customer-invoice-transaction-by-id/view-customer-invoice-transaction-by-id.component';

@Component({
  selector: 'app-view-customer-transactions',
  templateUrl: './view-customer-transactions.component.html',
  styleUrls: ['./view-customer-transactions.component.scss']
})
export class ViewCustomerTransactionsComponent implements OnInit {
  public customerId: string;
  public customerName: Customer;
  public customerInvoiceDetails: Customer[] = [];
  public filteredCustomerInvoiceDetails: Customer[] = [];
  public customerPurchaseTransations: CustomerTransaction[] = [];
  page: number = 1;
  noOfRecordsSizesArr = [10, 20, 30];
  pageNo = 0;
  pageSize = 10;
  totalCount = 0;
  public searchedTransactionValues: string;
  public searchedCustomerInvoiceValues: string;
  public startDateValue: string;
  public endDateValue: string;
  currentDate = new Date().toISOString().substring(0, 10);
  previousDate = subMonths(new Date(), 1).toISOString().substring(0, 10);
  customerInvoiceForm: FormGroup;
  displayLoader: boolean = true;
  dispalyNotFoundData: boolean = false;
  disableTranscationButton: boolean = false;
  totalCustomerInvoiceAmount: any;
  public transactionAmountOfCustomer: number;
  dateForm: FormGroup;
  model: NgbDateStruct;



  constructor(private customerService: CustomerService, private route: ActivatedRoute, private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private customerInvoiceService: CustomerInvoiceService, private toastr: ToastrService) { }


  // Customer Invoice Detials
  getStartDateValueOfInvoice(event: any) {
    this.startDateValue = event.target.value;
    this.customerPurchaseInvoiceDetails();
  }
  getEndDateValueOfInvoice(event: any) {
    this.endDateValue = event.target.value
    this.customerPurchaseInvoiceDetails();
  }

  getValueOfCusotomerPUrchaseInvoice(event: any) {
    this.searchedCustomerInvoiceValues = event.target.value;
    this.customerPurchaseInvoiceDetails();
  }


  public async customerPurchaseInvoiceDetails(): Promise<void> {
    if (this.searchedCustomerInvoiceValues == '' || this.searchedCustomerInvoiceValues.length >= 2) {
      this.customerInvoiceService.searchCustomerInvoiceByDate(this.pageSize, this.pageNo, this.searchedCustomerInvoiceValues, this.startDateValue, this.endDateValue, this.customerId).subscribe((getCustomerInvoice: any) => {
        const paginationDetails = getCustomerInvoice.pagination;
        this.page = paginationDetails.page + 1;
        this.totalCount = this.pageSize * paginationDetails.lastPage;
        this.customerInvoiceDetails = getCustomerInvoice.customerInvoices;
        if (this.customerInvoiceDetails.length === 0) {
          this.displayLoader = false;
          this.dispalyNotFoundData = true;

        }
        let invoiceAmountInfos = this.customerInvoiceDetails.map((invoiceAMountData: any) => {
          let transationAmountInNumber = +invoiceAMountData.totalAmount;
          return transationAmountInNumber;
        })

        let arrayOfTransactionAmounts = invoiceAmountInfos;
        this.totalCustomerInvoiceAmount = arrayOfTransactionAmounts.reduce((accumulator, current) => {
          return accumulator + current;
        }, 0)
      }, (error: any) => {
        this.toastr.error(error.error.message || 'Something went wrong!', 'Error', {
          progressBar: true,
        });
        this.displayLoader = false;
        this.dispalyNotFoundData = true;
      })
    }
  }

  // End of customer invoice details



  // End of Invoice Details


  // Customer Transactions Details
  getValueOfCustomerTransaction(event: any) {
    this.searchedTransactionValues = event.target.value;
    this.fetchCustomerTransactionDetails();
  }

  getStartDateValue(event: any) {
    this.startDateValue = event.target.value;
    this.fetchCustomerTransactionDetails();
  }

  getEndDateValue(event: any) {
    this.endDateValue = event.target.value
    this.fetchCustomerTransactionDetails();
  }

  public async fetchCustomerTransactionDetails(): Promise<void> {

    this.customerService.getCustomerById(this.customerId).subscribe((customerDetails: any) => {
      this.customerName = customerDetails;

    }, (error) => {
      this.toastr.error(error.error.message || 'Something went wrong!', 'Error', {
        progressBar: true,
      });
    })


    if (this.searchedTransactionValues == '' || this.searchedTransactionValues.length >= 2) {
      this.customerInvoiceService.searchCustomerTransactionsByDate(this.pageSize, this.pageNo, this.searchedTransactionValues, this.startDateValue, this.endDateValue, this.customerId).subscribe((searchCustoemrTransactionData: any) => {
        const paginationDetails = searchCustoemrTransactionData.pagination;
        this.page = paginationDetails.page + 1;
        this.totalCount = this.pageSize * paginationDetails.lastPage;
        this.customerPurchaseTransations = searchCustoemrTransactionData.invoiceTransactions;

        const transactionAmountInfos = this.customerPurchaseTransations.map((transcationAmountData) => {
          let transationAmountInNumber = +transcationAmountData.transactionAmount;
          return transationAmountInNumber;
        })
        let arrayOfTransactionAmounts = transactionAmountInfos;
        this.transactionAmountOfCustomer = arrayOfTransactionAmounts.reduce((accumulator, current) => {
          return accumulator + current;
        }, 0)

        if (this.customerPurchaseTransations.length === 0) {
          this.displayLoader = false;
          this.dispalyNotFoundData = true;

        }
      }, (error: any) => {
        this.toastr.error(error.error.message || 'Something went wrong!', 'Error', {
          progressBar: true,
        });
        this.displayLoader = false;
        this.dispalyNotFoundData = true;
      })
    }
  }


  //  End of Party Transactions Details

  addCustomerTransaction() {
    const customerTransactionRef = this.modalService.open(AddCustomerTransactionComponent);
    customerTransactionRef.componentInstance.customerTransactionId = this.customerId
    customerTransactionRef.result.then(() => {
      this.fetchCustomerTransactionDetails();
    })

  }

  viewCustomerInvoice(customerId: string) {
    this.router.navigateByUrl("/pages/viewCusotmerInvoice/" + customerId)

  }

  viewCustomerTrasactionById(customerTransactionId: string) {
    const viewStockCustoemrTransactionByIdRef = this.modalService.open(ViewCustomerInvoiceTransactionByIdComponent);
    viewStockCustoemrTransactionByIdRef.componentInstance.stockCustomerTransactionId = customerTransactionId;
  }



  ngOnInit(): void {
    this.customerId = this.route.snapshot.params['id'];
    this.searchedTransactionValues = '';
    this.searchedCustomerInvoiceValues = '';
    this.startDateValue = formatDate(this.previousDate, 'yyyy-MM-dd', 'en');
    this.endDateValue = formatDate(this.currentDate, 'yyyy-MM-dd', 'en');
    this.dateForm = this.formBuilder.group({
      startDate: new FormControl(formatDate(this.previousDate, 'yyyy-MM-dd', 'en')),
      endDate: new FormControl(formatDate(this.currentDate, 'yyyy-MM-dd', 'en')),
    })
    this.customerPurchaseInvoiceDetails().then(null);
    this.fetchCustomerTransactionDetails().then(null)

  }
}
