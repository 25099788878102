import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CustomerInvoice } from 'src/app/models/CusotmerInvoice';
import { CustomerInvoiceService } from 'src/app/services/customer-invoice.service';

@Component({
  selector: 'app-view-customer-invoice-transaction-by-id',
  templateUrl: './view-customer-invoice-transaction-by-id.component.html',
  styleUrls: ['./view-customer-invoice-transaction-by-id.component.scss']
})
export class ViewCustomerInvoiceTransactionByIdComponent implements OnInit {
  @Input() stockCustomerTransactionId: string;
  public customerTransactionData: CustomerInvoice;

  constructor(private customerInvoiceService: CustomerInvoiceService, private activeModalService: NgbActiveModal, toastrService: ToastrService) { }

  ngOnInit(): void {
    this.getInterentCustomerTransactionById().then(null)
  }

  public async getInterentCustomerTransactionById(): Promise<void> {
    this.customerInvoiceService.getCustomerTransactionById(this.stockCustomerTransactionId).subscribe((getCustomerTransactionData: any) => {
      this.customerTransactionData = getCustomerTransactionData;
    })
  }

  closeModal() {
    this.activeModalService.close();
  }
}
