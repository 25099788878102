import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BusinessParty } from 'src/app/models/BusinessParty';
import { BusinessPartyService } from 'src/app/services/business-party/business-party.service';
import { ExportTableDataService } from 'src/app/services/export-table-data/export-table-data.service';
import Swal from 'sweetalert2';
import { AddEditBusinessPartyComponent } from './add-edit-business-party/add-edit-business-party.component';

@Component({
  selector: 'app-business-party',
  templateUrl: './business-party.component.html',
  styleUrls: ['./business-party.component.scss']
})
export class BusinessPartyComponent implements OnInit {

  public businessPartyDetails: BusinessParty[] = [];
  page: number = 1;
  noOfRecordsSizesArr = [10, 20, 30];
  pageNo = 0;
  pageSize = 10;
  totalCount = 0;
  public searchedValues: string;
  displayLoader: boolean = true;
  dispalyNotFoundData: boolean = false;


  constructor(private businessPartyService: BusinessPartyService, private exportTableDataService: ExportTableDataService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  getALlBusinessPartyDetail() {
    this.businessPartyService.getBusinessPartyByPagination(this.pageSize, this.pageNo).subscribe((businessPartyDatas: any) => {
      const paginationDetails = businessPartyDatas.pagination;
      this.page = paginationDetails.page + 1;
      this.totalCount = this.pageSize * paginationDetails.lastPage;
      this.businessPartyDetails = businessPartyDatas.bussinessParties;
      if (this.businessPartyDetails?.length === 0) {
        this.displayLoader = false;
        this.dispalyNotFoundData = true;

      }

    }, (error) => {
      this.toastr.error(error.error.message || "Something went wrong!", 'Error', {
        progressBar: true,
      });

    });
  }

  addBusinessParty() {
    const modalRef = this.modalService.open(AddEditBusinessPartyComponent);
    modalRef.componentInstance.isAddMode = true;
    modalRef.result.then(() => {
      this.getALlBusinessPartyDetail();
    })

  }



  editbusinessParty(id: string) {
    const modalRef = this.modalService.open(AddEditBusinessPartyComponent);
    modalRef.componentInstance.businessPartyId = id;
    modalRef.componentInstance.isEditMode = true;
    modalRef.result.then(() => {
      this.getALlBusinessPartyDetail();
    })
  }


  ngOnInit(): void {
    this.getALlBusinessPartyDetail();

  }


  deleteBusinessParty(businessPartyId) {
    Swal.fire({
      title: 'Are you sure?',
      // text: 'You will not be able to revert this process!',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'Yes, delete it! ',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        this.businessPartyService.deleteBusinessParty(businessPartyId).subscribe(
          (res: any) => {
            Swal.fire('Deleted!', res.message, 'success');
            this.getALlBusinessPartyDetail();
          },
          (error) => {
            Swal.fire('Error', error.error.message, 'error');
          }
        );
      }
    });
  }

  exportAsXLSX(): void {
    this.exportTableDataService.exportAsExcelFile(this.businessPartyDetails, 'business-party-details');
  }

  getValue(event: any) {
    this.searchedValues = event.target.value;


    if (this.searchedValues.length >= 2) {
      this.businessPartyService.searchBusinessParty(this.pageSize, this.pageNo, this.searchedValues).subscribe((data: any) => {
        const paginationDetails = data.pagination;
        this.page = paginationDetails.page + 1;
        this.totalCount = this.pageSize * paginationDetails.lastPage;
        this.businessPartyDetails = data.bussinessParties;
        if (this.businessPartyDetails?.length === 0) {
          this.displayLoader = false;
          this.dispalyNotFoundData = true;
        }
      })
    } else if (this.searchedValues == '') {
      this.getALlBusinessPartyDetail();
    }
  }
  noOfRecordsSizeChange(): void {
    this.getALlBusinessPartyDetail();
  }

  pageChange(event) {
    this.page = event;
    this.pageNo = this.page - 1;
    this.getALlBusinessPartyDetail();
  }


}
