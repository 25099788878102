import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductPrice } from 'src/app/models/ProductPrice';
import { PartyPurchaseService } from 'src/app/services/party-purchase/party-purchase.service';

@Component({
  selector: 'app-view-product-price',
  templateUrl: './view-product-price.component.html',
  styleUrls: ['./view-product-price.component.scss'],
})
export class ViewProductPriceComponent implements OnInit {
  @Input() productPriceId: string;
  productPriceDetails: ProductPrice[] = [];

  constructor(
    private activeModalService: NgbActiveModal,
    private partyPurchaseService: PartyPurchaseService,
  ) {}

  ngOnInit(): void {
    this.getProductPrice().then(null);
  }

  public async getProductPrice(): Promise<void> {
    this.partyPurchaseService
      .getProductPrice(this.productPriceId)
      .subscribe((productPriceData: any) => {
        this.productPriceDetails = productPriceData;
      });
  }

  closeModal() {
    this.activeModalService.close();
  }
}
