import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BranchDetail } from 'src/app/models/BranchDetail';
import { BranchService } from 'src/app/services/branch/branch.service';
import { ExportTableDataService } from 'src/app/services/export-table-data/export-table-data.service';
import Swal from 'sweetalert2';
import { AddEditBranchComponent } from './add-edit-branch/add-edit-branch.component';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})
export class BranchComponent implements OnInit {

  public branchDetails: BranchDetail[] = [];
  displayLoader: boolean = true;
  dispalyNotFoundData: boolean = false;


  constructor(private branchService: BranchService,
    private toastr: ToastrService,
    private exportTableDataService: ExportTableDataService,
    private modalService: NgbModal) { }

  public async getAllBranch(): Promise<void> {
    this.branchService.getBranchDetails().subscribe((branchDatas: any) => {
      this.branchDetails = branchDatas;
      if (this.branchDetails?.length === 0) {
        this.displayLoader = false;
        this.dispalyNotFoundData = true;

      }
    }, (error) => {
      this.toastr.error(error.error.message || "Something went wrong!", 'Error', {
        progressBar: true,
      });
      this.displayLoader = false;
      this.dispalyNotFoundData = true;

    });
  }

  addBranchDetail() {
    const modalRef = this.modalService.open(AddEditBranchComponent);
    modalRef.componentInstance.isAddMode = true;
    modalRef.result.then(() => {
      this.getAllBranch();
    })
  }

  editBranchDetail(id: string) {
    const modalRef = this.modalService.open(AddEditBranchComponent);
    modalRef.componentInstance.branchId = id;
    modalRef.componentInstance.isEditMode = true;
    modalRef.result.then(() => {
      this.getAllBranch();
    })
  }




  deleteBranch(branchId) {
    Swal.fire({
      title: 'Are you sure?',
      // text: 'You will not be able to revert this process!',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'Yes, delete it! ',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        this.branchService.deleteBranch(branchId).subscribe(
          (res: any) => {
            Swal.fire('Deleted!', res.message, 'success');
            this.getAllBranch();
          },
          (error) => {
            Swal.fire('Error', error.error.message, 'error');
          }
        );
      }
    });
  }

  exportAsXLSX(): void {
    this.exportTableDataService.exportAsExcelFile(this.branchDetails, 'branch-details');
  }


  ngOnInit(): void {
    this.getAllBranch().then(null);
  }
}
