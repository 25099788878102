import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerApis } from 'src/app/api.constants';
import { TransportService } from '../transport/transport.service';

@Injectable({
  providedIn: 'root'
})
export class PartyPurchaseTransactionService {
  constructor(private transportService: TransportService, private httpClient: HttpClient) { }

  // PartyPurchaseTransactionsDetails
  getPartyPurchaseTransaction(pageSize: number, pageNumber: number) {
    return this.transportService.Read(`${ServerApis.partyPurchaseTransactionUrl + `?size=${pageSize}&page=${pageNumber}`}`)
  }

  createPartyPurchaseTransaction(data) {
    return this.transportService.Create(data, ServerApis.partyPurchaseTransactionUrl);
  }

  getBusinessPartyTransactionById(id: string) {
    return this.transportService.Read(`${ServerApis.partyPurchaseTransactionUrl}/${id}`);

  }


  searchPartyPurchaseTransactions(pageSize: number, pageNo: number, query: string = '') {
    return this.transportService.Read(
      ServerApis.partyPurchaseTransactionUrl +
      `?size=${pageSize}&page=${pageNo}&search=${query}`
    );
  }

  searchPartyPurchaseTransactionsByDate(pageSize: number, pageNo: number, query: string = '', startDate: string = '', endDate: string = '', party: string) {
    return this.transportService.Read(
      ServerApis.partyPurchaseTransactionUrl +
      `?size=${pageSize}&page=${pageNo}&search=${query}&startDate=${startDate}&endDate=${endDate}&party=${party}`
    );
  }

  searchPartyPurchaseInvoiceByDate(pageSize: number, pageNo: number, query: string = '', startDate: string = '', endDate: string = '', party: string) {
    return this.transportService.Read(
      ServerApis.partyPurchaseUrl +
      `?size=${pageSize}&page=${pageNo}&search=${query}&startDate=${startDate}&endDate=${endDate}&party=${party}`
    );
  }
}
