import { Component, OnInit } from '@angular/core';
import { subMonths } from 'date-fns';
import { CustomerInvoice } from 'src/app/models/CusotmerInvoice';
import { CustomerInvoiceService } from 'src/app/services/customer-invoice.service';
import { ExportTableDataService } from 'src/app/services/export-table-data/export-table-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-customer-invoice',
  templateUrl: './customer-invoice.component.html',
  styleUrls: ['./customer-invoice.component.scss']
})
export class CustomerInvoiceComponent implements OnInit {

  public customerInvoiceDetails: CustomerInvoice[] = [];
  page: number = 1;
  noOfRecordsSizesArr = [10, 20, 30];
  pageNo = 0;
  pageSize = 10;
  totalCount = 0;
  public searchedValuesOfCustomerInvoice: string;
  displayLoader: boolean = true;
  dispalyNotFoundData: boolean = false;

  constructor(private customerInvoiceService: CustomerInvoiceService, private exportTableDataService: ExportTableDataService) { }

  ngOnInit(): void {
    this.getAllCustomerInvoices().then(null);
  }

  public async getAllCustomerInvoices(): Promise<void> {
    this.customerInvoiceService.getCustomerInvoicePagination(this.pageSize, this.pageNo).subscribe((customerInvoiceData: any) => {
      const cusotmerInvoiceDetailsInfo = customerInvoiceData.pagination;
      this.page = cusotmerInvoiceDetailsInfo.page + 1;
      this.totalCount = this.pageSize * cusotmerInvoiceDetailsInfo.lastPage;
      this.customerInvoiceDetails = customerInvoiceData.customerInvoices;
      if (this.customerInvoiceDetails?.length === 0) {
        this.displayLoader = false;
        this.dispalyNotFoundData = true;

      }

    })
  }


  getValueOfcustomerInvoice(event: any) {
    this.searchedValuesOfCustomerInvoice = event.target.value;
    if (this.searchedValuesOfCustomerInvoice.length >= 2 || this.searchedValuesOfCustomerInvoice === '') {
      this.customerInvoiceService.searchCustomerInviceData(this.pageSize, this.pageNo, this.searchedValuesOfCustomerInvoice).subscribe((searchedData: any) => {
        const paginationDetails = searchedData.pagination;
        this.page = paginationDetails.page + 1;
        this.totalCount = this.pageSize * paginationDetails.lastPage;
        this.customerInvoiceDetails = searchedData.customerInvoices;
        if (this.customerInvoiceDetails?.length === 0) {
          this.displayLoader = false;
          this.dispalyNotFoundData = true;

        }
      })
    } else {
      return
    }
  }


  deleteCustomerInvoice(customerInvoiceId) {
    Swal.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'Yes, delete it! ',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        this.customerInvoiceService.deleteCustomerInvoice(customerInvoiceId).subscribe(
          (res: any) => {
            Swal.fire('Deleted!', res.message, 'success');
            this.getAllCustomerInvoices();
          },
          (error) => {
            Swal.fire('Error', error.error.message, 'error');
          }
        );
      }
    });
  }

  noOfRecordsSizeChange(): void {
    this.getAllCustomerInvoices();
  }

  pageChange(event) {
    this.page = event;
    this.pageNo = this.page - 1;
    this.getAllCustomerInvoices();
  }

  exportAsXLSX(): void {
    this.exportTableDataService.exportAsExcelFile(this.customerInvoiceDetails, 'customer-invoice');
  }


}
