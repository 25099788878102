import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { Customer } from 'src/app/models/Customer';
import { CustomerService } from 'src/app/services/customer/customer.service';

@Component({
  selector: 'app-add-edit-customer',
  templateUrl: './add-edit-customer.component.html',
  styleUrls: ['./add-edit-customer.component.scss'],
})
export class AddEditCustomerComponent implements OnInit {
  submitted: boolean = false;
  loading: boolean = false;
  @Input() isEditMode: boolean;
  @Input() isAddMode: boolean;
  @Input() customerId: string;
  customerForm: FormGroup;
  public customerDetail: Customer[];

  constructor(
    private customerService: CustomerService,
    private formBuilder: FormBuilder,
    private activeModalService: NgbActiveModal,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      contactNumber: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      customerType: new FormControl('Individual', Validators.required),
    });

    if (this.isEditMode === true) {
      this.customerService
        .getCustomerById(this.customerId)
        .pipe(first())
        .subscribe((data: any) => this.customerForm.patchValue(data));
    }
  }

  onSubmit() {
    this.loading = true;
    if (this.customerForm.invalid) {
      return;
    }
    if (this.isAddMode) {
      this.createCustomer();
    } else {
      this.updateCustomer();
    }
  }

  private createCustomer() {
    this.customerService
      .createCustomer(this.customerForm.value)
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          this.toastr.success('Customer added successfully!', 'Success', {
            progressBar: true,
          });
          this.customerForm.reset();
          this.closeModal();
          this.loading = false;
        },
        error: (error) => {
          this.toastr.error(
            error.error.message || 'Something went wrong!',
            'Error',
            {
              progressBar: true,
            },
          );
          this.loading = false;
        },
      });
  }

  private updateCustomer() {
    this.customerService
      .updateCustomer(this.customerId, this.customerForm.value)
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          this.toastr.success(res.message, 'Success', {
            progressBar: true,
          });
          this.closeModal();
          this.loading = false;
        },
        error: (error) => {
          this.toastr.error(
            error.error.message || 'Something went wrong!',
            'Error',
            {
              progressBar: true,
            },
          );
          this.loading = false;
        },
      });
  }

  closeModal() {
    this.activeModalService.close();
  }
}
