import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  signinForm: FormGroup;
  hide: boolean = true;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private storageService: TokenStorageService
  ) {
    authService.autoLogin();
  }

  ngOnInit(): void {
    this.signinForm = this.formBuilder.group({
      username: [
        this.storageService.getRemember(),
        [Validators.required, Validators.email],
      ],
      password: [null, Validators.required],
    });
  }

  get form() {
    return this.signinForm.controls;
  }

  onSubmit() {
    // if (this.form.remember.value) {
    //   this.storageService.remember(this.form.email.value);
    // }
    this.authService.login(this.form.username.value, this.form.password.value);
  }
}
