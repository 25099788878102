import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggedInUserProfileComponent } from 'src/app/auth/logged-in-user-profile/logged-in-user-profile.component';
import { ConstantFile } from 'src/app/constants/ConstantFile';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userRole: string;
  name: string;
  internetModuleUrl: string;
  myWindow = null;

  constructor(
    private authService: AuthService,
    private storageService: TokenStorageService,
    private modalService: NgbModal,
  ) { }

  viewLoggedInUserProfile() {
    const modalRef = this.modalService.open(LoggedInUserProfileComponent);
  }

  ngOnInit(): void {
    this.name = this.storageService.getUser().loggedInUserInfo;
    this.internetModuleUrl = ConstantFile.internetModuleUrl;
    this.userRole = this.storageService.getUser().role;

  }

  logout() {
    this.authService.logout();
  }

  openInternetModule() {
    if (this.myWindow == null || this.myWindow.closed)
      this.myWindow = window.open(this.internetModuleUrl, 'internetWindow');
    else this.myWindow.focus();
  }
}
