<div id="left-sidebar" class="sidebar" (click)="hideSidebar()">
  <h5 class="brand-name"> <a class="header-brand mr-3"><i class="fa fa-list brand-logo" aria-hidden="true"
        style="font-size: 15px;"></i></a>
    Stock MS
  </h5>
  <div class="tab-content mt-3">
    <div class="tab-pane fade show active" id="menu-uni" role="tabpanel">
      <nav class="sidebar-nav">
        <ul class="metismenu">
          <li *ngFor="let universityMenu of universityMenuItemsList" [routerLinkActive]="'active'">
            <a routerLink="{{ universityMenu.link }}"><i class="{{ universityMenu.icon }}"></i><span>{{
                universityMenu.title }}</span></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>