<div class="section-body">
    <div class="container-fluid">
        <div class="d-flex justify-content-between align-items-center ">
            <div class="header-action d-flex">
                <h1 class="page-title" i18n="@@partyPurchase">Party Purchase Invoice</h1>
                <ol class="breadcrumb page-breadcrumb mt-2">
                    <li class="breadcrumb-item" i18n="@@admin"> <a [routerLink]="['/pages/dashboard']">(
                            Dashboard</a>
                    </li>
                    <li class="breadcrumb-item"><a [routerLink]="['/pages/partyPurchase']" i18n="@@partyPurchase">Party
                            Purchase Invoice</a></li>
                    <li class="breadcrumb-item active" aria-current="page" i18n="@@edit">View )</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="container" style="width: 95%">
    <div class="row invoice-header px-3 py-2">
        <div class="col-4">
            <h5 class="mt-1">INVOICE</h5>
        </div>
        <div class="col-4 text-right mt-auto mb-auto  ml-auto">
            <button class="btn btn-light btn-md" id="print-button" type="button" (click)="printPage()">Print
                Invoice</button>
        </div>
    </div>

    <div class="invoice-content row px-3 pt-5">
        <div class="col-3">
            <h5 class="almost-gray mb-3">Invoiced to:</h5>
            <p class="gray-ish">{{this.partyPurchaseInvoiceData?.bussinessParty.name ?
                this.partyPurchaseInvoiceData?.bussinessParty.name : "- -- -"}}</p>
            <p class="gray-ish">{{this.partyPurchaseInvoiceData?.bussinessParty.location ?
                this.partyPurchaseInvoiceData?.bussinessParty.location : "- -- -"}}</p>

            <p class="gray-ish">{{this.partyPurchaseInvoiceData?.bussinessParty.contactNumber ?
                this.partyPurchaseInvoiceData?.bussinessParty.contactNumber : "- -- -"}}</p>
        </div>
        <div class="col-3">
            <h5 class="almost-gray">Invoice Bill Number:</h5>
            <p class="gray-ish">{{this.partyPurchaseInvoiceData?.billNo ? this.partyPurchaseInvoiceData?.billNo : "- - -
                -"}}</p>

            <h5 class="almost-gray">Date of Issue:</h5>
            <p class="gray-ish">{{this.partyPurchaseInvoiceData?.bussinessDate | date}} </p>

        </div>
        <div class="col-6 text-right total-field">
            <h4 class="almost-gray">Invoice Total</h4>
            <h4 class="gray-ish">Rs. <span class="curency">{{this.partyPurchaseInvoiceData?.totalAmount |
                    currency:' ':'symbol':''}}</span></h4>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-12  invoice-table pt-1">
            <table class="table table-hover">
                <thead class="thead splitForPrint">
                    <tr>
                        <th scope="col gray-ish">S.N</th>
                        <th scope="col gray-ish">Product</th>
                        <th scope="col gray-ish">Rate</th>
                        <th scope="col gray-ish">Quantity</th>
                        <th scope="col gray-ish">Total Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let invoiceDatas of partyPurchaseInvoiceData?.productPurchases; index as i">
                        <th scope="row">{{i+1}}</th>
                        <td class="item">{{ invoiceDatas.product.name? invoiceDatas.product.name: 'N/A' }}</td>
                        <td>{{ invoiceDatas.rate ? invoiceDatas.rate: 'N/A' }}</td>
                        <td>{{ invoiceDatas.quantity ? invoiceDatas.quantity: 'N/A'}}</td>
                        <td>{{ (invoiceDatas.rate * invoiceDatas.quantity) | currency:' ':'symbol':'' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row invoice_details">
        <!-- invoiced to details -->
        <div class="col-4 pt-3">
            <h4 class="gray-ish">Invoice Summary & Notes</h4>
            <p class="pt-3 almost-gray">{{this.partyPurchaseInvoiceData?.remarks ?
                this.partyPurchaseInvoiceData?.remarks : "N/A "}}</p>
        </div>
        <!-- Invoice assets and total -->
        <div class="col-5 mb-3 pr-4 sub-table ml-auto">
            <table class="table table-borderless">
                <tbody>
                    <tr>
                        <th scope="row gray-ish">Subtotal</th>
                        <td class="text-right">Rs. <span
                                class="currency ">{{this.partyPurchaseInvoiceData?.subTotalAmount |
                                currency:' ':'symbol':''}}</span></td>
                    </tr>
                    <tr>
                        <th scope="row gray-ish">VAT</th>
                        <td class="text-right">Rs. <span class="currency">{{this.partyPurchaseInvoiceData?.vatAmount |
                                currency:' ':'symbol':''}}</span></td>
                    </tr>
                    <tr class="last-row">
                        <th scope="row">
                            <h5 class="text-light">Total</h5>
                        </th>
                        <td class="text-right">
                            <h5><span class="currency pr-1">Rs. {{this.partyPurchaseInvoiceData?.totalAmount |
                                    currency:' ':'symbol':''}}</span> </h5>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <p class="text-center pb-3"><em> Taxes will be calculated in <i>Rs</i> regarding transport and other taxable
            services.</em></p>
</div>