<div class="page-loader-wrapper">
  <div class="loader">
  </div>
</div>

<div id="main_content">
  <app-headertop></app-headertop>
  <app-sidenav></app-sidenav>
  <div class="page">
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</div>