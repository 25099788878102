<div class="modal-section">
  <div class="modal-header bg-custom">
    <h4 class="modal-title text-white"><i class="fa fa-code-fork"></i>
      <span *ngIf="isAddMode">
        Add Branch
      </span>
      <span *ngIf="isEditMode">
        Edit Branch
      </span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="branchForm" (ngSubmit)="onSubmit()">
      <div class="card-body">
        <div class="clearfix row">

          <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold"> <b>Code:</b><span
                class="text-danger">*</span></span>


            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-lock" aria-hidden="true"></i>
                </div>
              </div>
              <input type="text" class="form-control" i18n-placeholder="@@code" autocomplete="off"
                placeholder="Enter code" name="code" formControlName="code" required>
            </div>

            <div *ngFor="let codeErrorValidationDatas of codeErrorValidation">
              <small class="form-text text-danger"
                *ngIf="branchForm.controls.code.hasError(codeErrorValidationDatas.errType) && branchForm.controls.code.touched"
                style="margin-top: 0rem;">
                {{codeErrorValidationDatas.errorMsg }}
              </small>
            </div>
          </div>

          <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold"> <b>Location:</b><span
                class="text-danger">*</span></span>

            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-map-marker" aria-hidden="true"></i>
                </div>
              </div>
              <input type="text" class="form-control" i18n-placeholder="@@location" autocomplete="off"
                placeholder="Enter location" name="location" formControlName="location" required>
            </div>


            <div *ngFor="let locationErrorValidationDatas of locationErrorValidation">
              <small class="form-text text-danger"
                *ngIf="branchForm.controls.location.hasError(locationErrorValidationDatas.errType) && branchForm.controls.location.touched"
                style="margin-top: 0rem;">
                {{locationErrorValidationDatas.errorMsg }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-sm-6">
          <button *ngIf="isAddMode" type="submit" class="w-100 mt-2 btn btn-success" i18n="@@submit"
            [disabled]="submitted || !branchForm.valid">Submit</button>
          <button *ngIf="isEditMode" type="submit" class="w-100 mt-2 btn btn-success" i18n="@@update"
            [disabled]="submitted || !branchForm.valid">Update</button>
        </div>

        <div class="col-sm-6">
          <button type="reset" class="w-100 btn btn-danger mt-2" i18n="@@Close" (click)="closeModal()">Close</button>
        </div>
      </div>
    </form>
  </div>

</div>