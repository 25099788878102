import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/User';
import { AccountService } from 'src/app/services/account/account.service';
import { ExportTableDataService } from 'src/app/services/export-table-data/export-table-data.service';
import Swal from 'sweetalert2';
import { AddEditAccountComponent } from './add-edit-account/add-edit-account.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  public accountDetails: User[] = [];
  displayLoader: boolean = true;
  dispalyNotFoundData: boolean = false;

  constructor(private accountService: AccountService, private exportTableDataService: ExportTableDataService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getAllAccounts().then(null);
  }

  public async getAllAccounts(): Promise<void> {
    this.accountService.getAccountDetails().subscribe((accountDetails: any) => {
      this.accountDetails = accountDetails;
      if (this.accountDetails?.length === 0) {
        this.displayLoader = false;
        this.dispalyNotFoundData = true;

      }

    }, (error) => {
      this.toastr.error(error.error.message || "Something went wrong!", 'Error', {
        progressBar: true,
      });
    })
  }

  addAccount() {
    const modalRef = this.modalService.open(AddEditAccountComponent);
    modalRef.componentInstance.isAddMode = true;
    modalRef.result.then(() => {
      this.getAllAccounts();
    })

  }



  editAccount(id: string) {
    const modalRef = this.modalService.open(AddEditAccountComponent);
    modalRef.componentInstance.businessPartyId = id;
    modalRef.componentInstance.isEditMode = true;
    modalRef.result.then(() => {
      this.getAllAccounts();
    })
  }



  deleteAccount(accountId: string) {
    Swal.fire({
      title: 'Are you sure?',
      // text: 'You will not be able to revert this process!',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'DELETE',
      cancelButtonText: 'CANCEL',
    }).then((result) => {
      if (result.isConfirmed) {
        this.accountService.deleteAccount(accountId).subscribe(
          (res: any) => {
            Swal.fire('Deleted!', res.message, 'success');
            this.getAllAccounts();
          },
          (error) => {
            Swal.fire('Error', error.error.message, 'error');
          }
        );
      }
    });
  }

  exportAsXLSX(): void {
    this.exportTableDataService.exportAsExcelFile(this.accountDetails, 'account-details');
  }


}
