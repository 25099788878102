<div class="section-body">
    <div class="container-fluid">
        <div class="d-flex justify-content-between align-items-center">
            <div class="header-action d-flex">
                <h1 class="page-title" i18n="@@customerInvoice">Customer Invoice</h1>
                <ol class="breadcrumb page-breadcrumb mt-2">
                    <li class="breadcrumb-item" i18n="@@admin">
                        <a [routerLink]="['/pages/dashboard']">( Dashboard</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/pages/customerInvoice']" i18n="@@customerInvoice">Customer Invoice</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page" i18n="@@edit">
                        View )
                    </li>
                </ol>
            </div>

            <div class="search-and-btns d-flex">
                <div class="row export-and-add-btns">
                    <button type="button" class="btn btn-md btn-primary mt-2 mr-1" (click)="printPage()">
                        <i class="fa fa-print"></i> Print Invoice
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container mb-5" style="width: 95%">
    <div class="row invoice-header px-3 py-2">
        <div class="col-sm-6">
            <h4 class="mt-1 mb-3">Sainamaina Network and IT Solution</h4>
            <h6 class="d-flex">
                Pan No:&nbsp;&nbsp;
                <p class="mr-1">6</p>
                <p class="mr-1">0</p>
                <p class="mr-1">8</p>
                <p class="mr-1">8</p>
                <p class="mr-1">3</p>
                <p class="mr-1">8</p>
                <p class="mr-1">2</p>
                <p class="mr-1">0</p>
                <p class="mr-1">5</p>
            </h6>
        </div>
        <div class="col-sm-6 text-right mt-auto mb-auto">
            <h6>
                Invoice #{{
                this.cusotmerInvoiceData?.invoiceNumber
                ? this.cusotmerInvoiceData?.invoiceNumber
                : '-- -
                -'
                }}
            </h6>
            <h6>{{ this.cusotmerInvoiceData?.bussinessDate | date }}</h6>
        </div>
    </div>

    <div class="invoice-content row px-3 pt-5">
        <div class="col-3">
            <h5 class="almost-gray mb-3">
                Invoiced to:
                <p class="gray-ish">
                    {{
                    this.cusotmerInvoiceData?.customer.name
                    ? this.cusotmerInvoiceData?.customer.name
                    : '- -- -'
                    }}
                </p>
            </h5>

            <p class="gray-ish">
                {{
                this.cusotmerInvoiceData?.customer.address
                ? this.cusotmerInvoiceData?.customer.address
                : '- -- -'
                }}
            </p>

            <p class="gray-ish">
                {{
                this.cusotmerInvoiceData?.customer.contactNumber
                ? this.cusotmerInvoiceData?.customer.contactNumber
                : '- -- -'
                }}
            </p>
        </div>
        <div class="col-3"></div>
        <div class="col-6 text-right total-field">
            <h4 class="almost-gray">Invoice Total</h4>
            <h4 class="gray-ish">
                Rs.
                <span class="curency">{{
                    this.cusotmerInvoiceData?.totalAmount | currency: ' ':'symbol':''
                    }}</span>
            </h4>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-12 invoice-table pt-1">
            <table class="table table-hover">
                <thead class="thead splitForPrint">
                    <tr>
                        <th scope="col gray-ish">S.N</th>
                        <th scope="col gray-ish">Product</th>
                        <th scope="col gray-ish">Rate</th>
                        <th scope="col gray-ish">Quantity</th>
                        <th scope="col gray-ish">Total Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="
              let invoiceDatas of cusotmerInvoiceData?.products;
              index as i
            ">
                        <th scope="row">{{ i + 1 }}</th>
                        <td class="item">
                            {{
                            invoiceDatas.productDetails.name
                            ? invoiceDatas.productDetails.name
                            : '- - -
                            -'
                            }}
                        </td>
                        <td>{{ invoiceDatas.rate ? invoiceDatas.rate : 'N/A' }}</td>
                        <td>
                            {{ invoiceDatas.quantity ? invoiceDatas.quantity : 'N/A' }}
                        </td>
                        <td>
                            {{ invoiceDatas.rate * invoiceDatas.quantity | currency: ' ':'' }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row invoice_details">
        <!-- invoiced to details -->
        <div class="col-4 pt-3">
            <h4 class="gray-ish">Invoice Summary & Notes</h4>
            <p class="pt-3 almost-gray">
                {{
                this.cusotmerInvoiceData?.remarks
                ? this.cusotmerInvoiceData?.remarks
                : 'N/A '
                }}
            </p>
        </div>
        <!-- Invoice assets and total -->
        <div class="col-5 mb-3 pr-4 sub-table ml-auto">
            <table class="table table-borderless">
                <tbody>
                    <tr>
                        <th scope="row gray-ish">VAT</th>
                        <td class="text-right">
                            Rs.
                            <span class="currency">{{
                                this.cusotmerInvoiceData?.vatAmount
                                | currency: ' ':'symbol':'0.0-1'
                                }}</span>
                        </td>
                    </tr>

                    <tr>
                        <th scope="row gray-ish">Discount</th>
                        <td class="text-right">
                            Rs.
                            <span class="currency">{{
                                this.cusotmerInvoiceData?.discountAmount
                                | currency: ' ':'symbol':'0.0-1'
                                }}</span>
                        </td>
                    </tr>
                    <tr class="last-row">
                        <th style="color: #485058">
                            <h5><b>Total</b></h5>
                        </th>
                        <td class="text-right">
                            <h5>
                                <span class="currency pr-1"><b>Rs.
                                        {{
                                        this.cusotmerInvoiceData?.totalAmount
                                        | currency: ' ':'symbol':''
                                        }}</b></span>
                            </h5>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>