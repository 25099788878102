<div class="section-body main-no-table">
    <div class="container-fluid">
        <div class="d-flex justify-content-between  align-items-center " id="breadcumb-section-part">
            <div class="header-action d-flex">
                <h1 class="page-title" i18n="@@customerInvoiceTransactions">Customers</h1>
                <ol class="breadcrumb page-breadcrumb mt-2">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/pages/dashboard']">( Dashboard</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page" i18n="@@transactions">
                        Transactions )
                    </li>
                </ol>
            </div>

            <div class="search-and-btns d-flex">
                <ul class="nav nav-tabs page-header-tab" id="page-header-update">
                    <div class="row ">
                        <div class="input-group border mt-2" id="search-bar">
                            <div class="input-group-prepend">
                                <button id="button-addon4" type="button" class="btn btn-link text-info border-0"><i
                                        class="fa fa-search"></i></button>
                            </div>
                            <input type="search" placeholder="Search here" class="form-control bg-none border-0"
                                (keyup)="getValueOfCustomerTransaction($event)">
                        </div>
                    </div>
                </ul>

                <div class="row export-and-add-btns">
                    <button type="button" class="btn btn-md btn-success mt-2 mr-2" (click)="addCustomerTransaction()"><i
                            class="fa fa-plus-circle"></i> Add
                        Transaction</button>
                    <button type="button" class="btn btn-md btn-primary mt-2 mr-1" (click)="exportAsXLSX()"><i
                            class="fa fa-file-excel-o"></i> Export</button>
                </div>
            </div>

        </div>
    </div>
    <div class="container-fluid">
        <div class="tab-content">
            <div class="tab-pane active">
                <div class="table-responsive mt-2">
                    <div class="table-responsive card">
                        <table class="table  text-center table-vcenter text-nowrap mb-0">
                            <thead class="thead-dark">
                                <tr>
                                    <th i18n="@@sn">S.N</th>
                                    <th i18n="@@name">Name</th>
                                    <th i18n="@@contactNumber">Invoice No</th>
                                    <th i18n="@@amount">Amount</th>
                                    <th i18n="@@date">Date</th>
                                    <th i18n="@@paymentType">Payment Method</th>
                                    <th i18n="@@registeredBy">Registered By</th>
                                    <th i18n="@@action">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="customerTransactionDetails?.length === 0">
                                    <td class="text-center" colspan="7" *ngIf="this.displayLoader">
                                        <div class="spinner-grow text-secondary mt-3" role="status">
                                        </div>
                                        <div class="spinner-grow text-secondary mt-3" role="status">
                                        </div>
                                        <div class="spinner-grow text-secondary mt-3" role="status">
                                        </div>
                                        <div class="spinner-grow text-secondary mt-3" role="status">
                                        </div>
                                        <div class="mt-2">
                                            <h6 scope="col gray-ish">Please Wait</h6>
                                        </div>
                                    </td>

                                    <td class="text-muted text-center" colspan="7" *ngIf="this.dispalyNotFoundData">
                                        No Customer Transaction Details Found.
                                    </td>
                                </tr>
                                <tr *ngFor="let customerTransactionDetailsInfos of customerTransactionDetails | paginate : {
                    itemsPerPage: pageSize,
                    currentPage: page,totalItems:totalCount
                    }; index as i">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ customerTransactionDetailsInfos.customer.name ?
                                        customerTransactionDetailsInfos.customer.name : '----' }}</td>
                                    <td>{{ customerTransactionDetailsInfos.invoiceNo ?
                                        customerTransactionDetailsInfos.invoiceNo :
                                        '----' }}</td>
                                    <td>{{ customerTransactionDetailsInfos.transactionAmount|
                                        currency:' ':'symbol':'' }}
                                    </td>
                                    <td>{{ customerTransactionDetailsInfos.transactionDate | date}}
                                    </td>

                                    <td>
                                        {{ customerTransactionDetailsInfos.paymentMethod
                                        === 'Check' ? 'Cheque' :
                                        customerTransactionDetailsInfos.paymentMethod
                                        }}

                                    </td>
                                    <td>{{ customerTransactionDetailsInfos.registeredBy ?
                                        customerTransactionDetailsInfos.registeredBy.fullname : 'N/A' }}</td>
                                    <td>
                                        <button
                                            (click)="viewCustomerTransactionById(customerTransactionDetailsInfos.id)"
                                            type="button" class="btn btn-success btn-sm rounded-circle">
                                            <i class="fa fa-eye"></i>
                                        </button>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6" style="margin-right: auto;">
                        <pagination-controls (pageChange)="pageChange($event)">
                        </pagination-controls>
                    </div>

                    <div class="col-md-3" style="margin-left: auto;">
                        <select (change)="noOfRecordsSizeChange()" class="form-control" [(ngModel)]="pageSize">
                            <option *ngFor="let size of noOfRecordsSizesArr" [ngValue]="size">
                                {{ size }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>