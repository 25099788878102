import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CustomerInvoice } from 'src/app/models/CusotmerInvoice';
import { CustomerInvoiceService } from 'src/app/services/customer-invoice.service';
import { ExportTableDataService } from 'src/app/services/export-table-data/export-table-data.service';
import Swal from 'sweetalert2';
import { AddCustomerTransactionComponent } from '../../add-customer-transaction/add-customer-transaction.component';
import { AddEditCustomerComponent } from '../../add-edit-customer/add-edit-customer.component';
import { ViewCustomerInvoiceTransactionByIdComponent } from '../view-customer-invoice-transaction-by-id/view-customer-invoice-transaction-by-id.component';

@Component({
  selector: 'app-customer-invoice-transaction',
  templateUrl: './customer-invoice-transaction.component.html',
  styleUrls: ['./customer-invoice-transaction.component.scss']
})
export class CustomerInvoiceTransactionComponent implements OnInit {

  public customerTransactionDetails: CustomerInvoice[] = [];
  page: number = 1;
  noOfRecordsSizesArr = [10, 20, 30];
  pageNo = 0;
  pageSize = 10;
  totalCount = 0;
  public searchedValues: string;
  displayLoader: boolean = true;
  dispalyNotFoundData: boolean = false;

  constructor(private customerTransactionService: CustomerInvoiceService, private exportTableDataService: ExportTableDataService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }



  ngOnInit(): void {
    this.getAllCustomerTransaction();

  }

  getAllCustomerTransaction() {
    this.customerTransactionService.searchCustomerTransactions(this.pageSize, this.pageNo).subscribe((customerTransactionDatas: any) => {
      const paginationDetails = customerTransactionDatas.pagination;
      this.page = paginationDetails.page + 1;
      this.totalCount = this.pageSize * paginationDetails.lastPage;
      this.customerTransactionDetails = customerTransactionDatas.invoiceTransactions;
      if (this.customerTransactionDetails?.length === 0) {
        this.displayLoader = false;
        this.dispalyNotFoundData = true;

      }

    }, (error) => {
      this.toastr.error(error.error.message || "Something went wrong!", 'Error', {
        progressBar: true,
      });
      this.displayLoader = false;
      this.dispalyNotFoundData = true;
    });
  }

  // addCustomer() {
  //   const modalRef = this.modalService.open(AddEditCustomerComponent);
  //   modalRef.componentInstance.isAddMode = true;
  //   modalRef.result.then(() => {
  //     this.getAllCustomer();
  //   })

  // }


  exportAsXLSX(): void {
    this.exportTableDataService.exportAsExcelFile(this.customerTransactionDetails, 'customer-transaction-details');
  }

  getValueOfCustomerTransaction(event: any) {
    this.searchedValues = event.target.value;
    if (this.searchedValues.length >= 2) {
      this.customerTransactionService.searchCustomerTransactions(this.pageSize, this.pageNo, this.searchedValues).subscribe((data: any) => {
        const paginationDetails = data.pagination;
        this.page = paginationDetails.page + 1;
        this.totalCount = this.pageSize * paginationDetails.lastPage;
        this.customerTransactionDetails = data.invoiceTransactions;
        if (this.customerTransactionDetails?.length === 0) {
          this.displayLoader = false;
          this.dispalyNotFoundData = true;;

        }
      })
    } else if (this.searchedValues == '') {
      this.getAllCustomerTransaction();
    }
  }

  addCustomerTransaction() {
    const customerTransactionRef = this.modalService.open(AddCustomerTransactionComponent);
    customerTransactionRef.componentInstance.customerTransactionId = null
    customerTransactionRef.result.then(() => {
      this.getAllCustomerTransaction();
    })

  }

  viewCustomerTransactionById(id: string) {
    const viewCustoemrTransactionByIdRef = this.modalService.open(ViewCustomerInvoiceTransactionByIdComponent);
    viewCustoemrTransactionByIdRef.componentInstance.stockCustomerTransactionId = id;
  }



  noOfRecordsSizeChange(): void {
    this.getAllCustomerTransaction();
  }

  pageChange(event) {
    this.page = event;
    this.pageNo = this.page - 1;
    this.getAllCustomerTransaction();
  }




}
