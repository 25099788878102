import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerApis } from 'src/app/api.constants';
import { TransportService } from '../transport/transport.service';

@Injectable({
  providedIn: 'root'
})
export class PartyPurchaseService {

  constructor(private transportService: TransportService, private httpClient: HttpClient) { }

  getPartyPurchaseDetails(pageSize: number, pageNumber: number) {
    return this.transportService.Read(`${ServerApis.partyPurchaseUrl + `?size=${pageSize}&page=${pageNumber}`}`);
  }

  getPartyPurchaseById(id: string) {
    return this.transportService.Read(`${ServerApis.partyPurchaseUrl}/${id}`);
  }

  getProductPrice(id: string) {
    return this.transportService.Read(`${ServerApis.productPriceUrl}/${id}`);
  }

  createPartyPurchase(req) {
    return this.transportService.Create(req, ServerApis.partyPurchaseUrl);
  }

  updatePartyPurchase(id: string, data) {
    return this.httpClient.patch(`${ServerApis.partyPurchaseUrl}/${id}`, data);
  }

  deletePartyPurchase(id: string) {
    return this.transportService.Delete(`${ServerApis.partyPurchaseUrl}/${id}`);
  }

  searchPartyPurchaseInvoice(pageSize: number, pageNo: number, query: string) {
    return this.transportService.Read(
      ServerApis.partyPurchaseUrl +
      `?size=${pageSize}&page=${pageNo}&search=${query}`
    );
  }



}
