<div class="modal-section">
  <div class="modal-header bg-custom">
    <h5 class="modal-title text-white"><i class="fa fa-product-hunt"></i>
      <span *ngIf="isAddMode">
        Add Product
      </span>
      <span *ngIf="isEditMode">
        Edit Product
      </span>
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="addEditProductDetail" (ngSubmit)="onSubmit()">
      <div class="card-body">
        <div class="clearfix row">
          <div class="form-group col-sm-12 mb-3">
            <label for="privilege" i18n="@@name"><b>Product Name:</b><span class="text-danger">*</span></label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-product-hunt" aria-hidden="true"></i>
                </div>
              </div>
              <input type="text" class="form-control" name="name" formControlName="name" i18n-placeholder
                placeholder="Enter product name">
            </div>
            <small class="form-text text-danger"
              *ngIf="addEditProductDetail.controls.name.hasError('required') && addEditProductDetail.controls.name.touched"
              style="margin-top: -5px;">
              Product name is required!</small>
          </div>

          <div class="form-group col-sm-12 mb-3">
            <label for="privilege" i18n="@@selectUnitType"><b>Select Unit Type:</b><span
                class="text-danger">*</span></label>
            <br>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-balance-scale" aria-hidden="true"></i>
                </div>
              </div>
              <select class="form-control" name="unitType" formControlName="unitType">
                <option value="" disabled="true">Select Unit Type</option>
                <option value="meters">Meters</option>
                <option value="units">Units</option>
              </select>
            </div>
          </div>


          <div class="form-group col-sm-12 mb-3">
            <label for="privilege" i18n="@@isVatApplicable"><b>IsVatApplicable:</b></label>
            <br>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-percent" aria-hidden="true"></i>
                </div>
              </div>
              <select class="form-control" name="isVatApplicable" formControlName="isVatApplicable">
                <option *ngFor="let itemDataInfos of isVatApplicatbleData" [value]="itemDataInfos.value">{{
                  itemDataInfos.name }}</option>
              </select>
            </div>

          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-sm-6">
          <button *ngIf="isAddMode" type="submit" class="w-100 mt-2 btn btn-success" i18n="@@submit"
            [disabled]="submitted || !addEditProductDetail.valid">
            Submit <span class="spinner-grow spinner-grow-sm" aria-hidden="true" *ngIf="this.loading"></span> </button>
          <button *ngIf="isEditMode" type="submit" class="w-100 mt-2 btn btn-success" i18n="@@update"
            [disabled]="submitted || !addEditProductDetail.valid">Update <span class="spinner-grow spinner-grow-sm"
              aria-hidden="true" *ngIf="this.loading"></span></button>
        </div>

        <div class="col-sm-6">
          <button type="reset" class="w-100 btn btn-danger mt-2" i18n="@@Close" (click)="closeModal()">Close</button>
        </div>
      </div>
    </form>
  </div>

</div>