<div class="section-body main-no-table">
    <div class="container-fluid">
        <div class="d-flex justify-content-between  align-items-center" id="breadcumb-section-part">
            <div class="header-action d-flex">
                <h1 class="page-title" i18n="@@account">Account</h1>
                <ol class="breadcrumb page-breadcrumb mt-2">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/pages/dashboard']">( Dashboard</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page" i18n="@@account">
                        Account Details )
                    </li>
                </ol>
            </div>


            <div class="search-and-btns d-flex">
                <div class="row export-and-add-btns">
                    <button type="button" class="btn btn-md btn-primary mt-2 mr-1" (click)="exportAsXLSX()"><i
                            class="fa fa-file-excel-o"></i> Export</button>

                    <button type="button" class="btn btn-md btn-success mt-2" (click)="addAccount()"><i
                            class="fa fa-plus-circle"></i> Add</button>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="tab-content">
            <div class="tab-pane active">
                <div class="table-responsive mt-2">
                    <div class="table-responsive card">
                        <table class="table  text-center table-vcenter text-nowrap mb-0">
                            <thead class="thead-dark">
                                <tr>
                                    <th i18n="@@sn">S.N</th>
                                    <th i18n="@@name">Full Name</th>
                                    <th i18n="@@contactPerson">Username</th>
                                    <th i18n="@@contactNumber">Contact Number</th>
                                    <th i18n="@@action">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="accountDetails.length == 0">
                                    <td class="text-center" colspan="6" *ngIf="this.displayLoader">
                                        <div class="spinner-grow text-secondary mt-3" role="status">
                                        </div>
                                        <div class="spinner-grow text-secondary mt-3" role="status">
                                        </div>
                                        <div class="spinner-grow text-secondary mt-3" role="status">
                                        </div>
                                        <div class="spinner-grow text-secondary mt-3" role="status">
                                        </div>
                                        <div class="mt-2">
                                            <h6 scope="col gray-ish">Please Wait</h6>
                                        </div>
                                    </td>

                                    <td class="text-muted text-center" colspan="6" *ngIf="this.dispalyNotFoundData">
                                        No Account Details Found.
                                    </td>
                                </tr>
                                <tr *ngFor="let accountDetailsInfos of accountDetails; index as i">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ accountDetailsInfos.fullname ? accountDetailsInfos.fullname : '----' }}
                                    </td>
                                    <td>{{ accountDetailsInfos.username ? accountDetailsInfos.username :
                                        '----' }}</td>
                                    <td>{{ accountDetailsInfos.contactNumber ?
                                        accountDetailsInfos.contactNumber : '----' }}
                                    </td>
                                    <td>

                                        <button (click)="editAccount(accountDetailsInfos.id)" type="button"
                                            class="btn btn-secondary rounded-circle btn-sm mr-1">
                                            <i class="fa fa-edit"></i>
                                        </button>
                                        <button (click)="deleteAccount(accountDetailsInfos.id)" type="button"
                                            class="btn btn-danger btn-sm rounded-circle js-sweetalert"
                                            data-type="confirm">
                                            <i class="fa fa-trash-o"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- <div class="row">
            <div class="col-md-6" style="margin-right: auto;">
              <pagination-controls (pageChange)="pageChange($event)">
              </pagination-controls>
            </div>
  
            <div class="col-md-3" style="margin-left: auto;">
              <select (change)="noOfRecordsSizeChange()" class="form-control" [(ngModel)]="pageSize">
                <option *ngFor="let size of noOfRecordsSizesArr" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
            </div>
          </div> -->
            </div>
        </div>
    </div>
</div>