import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerApis } from 'src/app/api.constants';
import { TransportService } from '../transport/transport.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private transportService: TransportService, private httpClient: HttpClient) { }

  getAllProductDetails() {
    return this.transportService.Read(`${ServerApis.productDetailsBasicListingUrl}`);
  }

  getProductDetailsByPagination(pageSize: number, pageNumber: number) {
    return this.transportService.Read(`${ServerApis.proudctDetailsUrl + `?size=${pageSize}&page=${pageNumber}`}`);

  }

  getProductById(id: string) {
    return this.transportService.Read(`${ServerApis.proudctDetailsUrl}/${id}`);
  }

  getProductStock(id: string) {
    return this.transportService.Read(`${ServerApis.productStockUrl}/${id}`);
  }

  createProduct(req) {
    return this.transportService.Create(req, ServerApis.proudctDetailsUrl);
  }

  updateProduct(id: string, data) {
    return this.httpClient.patch(`${ServerApis.proudctDetailsUrl}/${id}`, data);
  }



  deleteProduct(id: string) {
    return this.transportService.Delete(`${ServerApis.proudctDetailsUrl}/${id}`);
  }


  searchProduct(pageSize: number, pageNo: number, query: string) {
    return this.transportService.Read(
      ServerApis.proudctDetailsUrl +
      `?size=${pageSize}&page=${pageNo}&search=${query}`
    );
  }

}
