import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  public universityMenuItemsList: Array<any> = [];
  public loggedInRoleData = this.tokenStorageService.getUser().role;

  public businessPartyMenus = [
    {
      title: $localize`:@@businessParty: Business party`,
      icon: 'fa fa-briefcase',
      link: '/pages/businessParty',
    },

    {
      title: $localize`:@@partyPurchase:Party purchase`,
      icon: 'fa fa-file-text',
      link: '/pages/partyPurchase',
    },
  ];

  public bussinessPartyTransactionMenus = [
    {
      title: $localize`:@@partyTransaction: Party transaction`,
      icon: 'fa fa-exchange',
      link: '/pages/partyInvoiceTransaction',
    },
  ];

  public customerMenus = [
    {
      title: $localize`:@@customer:Customer`,
      icon: 'fa fa-users',
      link: '/pages/customers',
    },
    {
      title: $localize`:@@customerInvoice:Customer invoice`,
      icon: 'fa fa-file-text',
      link: '/pages/customerInvoice',
    },
    {
      title: $localize`:@@customerTransaction:Customer transaction`,
      icon: 'fa fa-exchange',
      link: '/pages/customerInvoiceTransaction',
    },
  ];

  public setUpMenus = [
    {
      title: $localize`:@@dashboard: Dashboard`,
      icon: 'fa fa-dashboard',
      link: '/pages/dashboard',
    },
    // {
    //   title: $localize`:@@account: Account`,
    //   icon: 'fa fa-user',
    //   link: '/pages/account',
    // },
    {
      title: $localize`:@@products: Products`,
      icon: 'fa fa-product-hunt',
      link: '/pages/products',
    },
    // {
    //   title: $localize`:@@branch: Branch`,
    //   icon: 'fa fa-code-fork',
    //   link: '/pages/branch',
    // },
  ];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private tokenStorageService: TokenStorageService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.loggedInRoleData === 'staff')
      this.universityMenuItemsList.push(
        ...this.setUpMenus,
        ...this.businessPartyMenus,
        ...this.customerMenus,
      );
    else if (this.loggedInRoleData === 'admin')
      this.universityMenuItemsList.push(
        ...this.setUpMenus,
        ...this.businessPartyMenus,
        ...this.bussinessPartyTransactionMenus,
        ...this.customerMenus,
      );
    else this.router.navigateByUrl('404-not-found');
  }

  hideSidebar() {
    this.document.body.classList.remove('offcanvas-active');
  }
}
