<div class="modal-section">
    <div class="modal-header bg-custom">
        <h4 class="modal-title text-white"><i class="fa fa-briefcase"></i>
            <span *ngIf="isAddMode">
                Add Business Party
            </span>
            <span *ngIf="isEditMode">
                Edit Business Party
            </span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="addEditAccount" (ngSubmit)="onSubmit()">
            <div class="card-body">
                <div class="clearfix row">

                    <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold"> Full Name:<span
                                class="text-danger">*</span></span>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-user" aria-hidden="true"></i>
                                </div>
                            </div>
                            <input type="text" class="form-control" i18n-placeholder="@@code"
                                placeholder="Enter full name" name="fullname" formControlName="fullname" required>
                        </div>
                        <small class="form-text text-danger"
                            *ngIf="addEditAccount.controls.fullname.hasError('required') && addEditAccount.controls.fullname.touched"
                            style="margin-top: 0rem;">
                            Full name is required!
                        </small>
                    </div>

                    <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold"> Username:<span
                                class="text-danger">*</span></span>

                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-map-marker" aria-hidden="true"></i>
                                </div>
                            </div>
                            <input type="text" class="form-control" i18n-placeholder="@@username"
                                placeholder="Enter username" name="username" formControlName="username" required>
                        </div>
                        <small class="form-text text-danger"
                            *ngIf="addEditAccount.controls.username.hasError('required') && addEditAccount.controls.username.touched"
                            style="margin-top: 0rem;">
                            Username is required!
                        </small>
                    </div>

                    <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold"> Password:<span
                                class="text-danger">*</span></span>

                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="{{ hide ? 'fa fa-eye' : 'fa fa-eye-slash' }}"
                                        aria-hidden="true" (click)="hide = !hide"></i>
                                </div>
                            </div>
                            <input type="text" class="form-control" i18n-placeholder="@@password"
                                placeholder="Enter password" name="password" formControlName="password"
                                [type]="hide ? 'password': 'text' " required>
                        </div>
                        <small class="form-text text-danger"
                            *ngIf="addEditAccount.controls.password.hasError('required') && addEditAccount.controls.password.touched"
                            style="margin-top: 0rem;">
                            Password is required!
                        </small>

                        <small class="form-text text-danger"
                            *ngIf="addEditAccount.controls.password.hasError('minlength') && addEditAccount.controls.password.touched"
                            style="margin-top: 0rem;">
                            Password must be at leat 4 characters long!
                        </small>

                        <small class="form-text text-danger"
                            *ngIf="addEditAccount.controls.password.hasError('maxlength') && addEditAccount.controls.password.touched"
                            style="margin-top: 0rem;">
                            Password must not be more than 20 characters long!
                        </small>
                    </div>

                    <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold"> Confirm Password:<span
                                class="text-danger">*</span></span>

                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="{{ hide ? 'fa fa-eye' : 'fa fa-eye-slash' }}"
                                        aria-hidden="true" (click)="hide = !hide"></i>
                                </div>
                            </div>
                            <input type="text" class="form-control" i18n-placeholder="@@confirmPassword"
                                placeholder="Enter confirmPassword" name="confirmPassword"
                                formControlName="confirmPassword" [type]="hide ? 'confirmPassword': 'text' " required>
                        </div>
                        <small class="form-text text-danger"
                            *ngIf="addEditAccount.controls.confirmPassword.hasError('required') && addEditAccount.controls.confirmPassword.touched"
                            style="margin-top: 0rem;">
                            ConfirmPassword is required!
                        </small>

                        <small class="form-text text-danger"
                            *ngIf="addEditAccount.controls.confirmPassword.hasError('minlength') && addEditAccount.controls.confirmPassword.touched"
                            style="margin-top: 0rem;">
                            Confirm password must be at leat 4 characters long!
                        </small>

                        <small class="form-text text-danger"
                            *ngIf="addEditAccount.controls.confirmPassword.hasError('maxlength') && addEditAccount.controls.confirmPassword.touched"
                            style="margin-top: 0rem;">
                            Confirm password must not be more than 20 characters long!
                        </small>
                    </div>

                    <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold"> Contact Number:<span
                                class="text-danger">*</span></span>

                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-user" aria-hidden="true"></i>
                                </div>
                            </div>
                            <input type="text" class="form-control" i18n-placeholder="@@contactNumber"
                                placeholder="Enter contact person" name="contactNumber" formControlName="contactNumber"
                                required>
                        </div>


                        <small class="form-text text-danger"
                            *ngIf="addEditAccount.controls.contactNumber.hasError('required') && addEditAccount.controls.contactNumber.touched"
                            style="margin-top: 0rem;">
                            Contact number is required!
                        </small>
                    </div>

                    <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold"> Roles:<span
                                class="text-danger">*</span></span>

                        <div *ngFor="let rolesDataInfo of rolesData; let i=index">
                            <label>
                                <input type="checkbox" formControlName="roles" [value]="rolesDataInfo.id" />
                                {{rolesDataInfo.name}}
                            </label>
                        </div>

                    </div>
                    <div class="col-md-12 m-0 mb-3"><span class="font-weight-bold"> Branch:<span
                                class="text-danger">*</span></span>

                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-user" aria-hidden="true"></i>
                                </div>
                            </div>
                            <select i18n-placeholder="@@branch" class="form-control" name="branch"
                                formControlName="branch">
                                <option value="" disabled="true">Select Branch</option>
                                <option *ngFor="let branchDetailsInfos of branchDetails"
                                    [value]="branchDetailsInfos.id">
                                    {{ branchDetailsInfos.code }}
                                </option>
                            </select>
                        </div>


                        <small class="form-text text-danger"
                            *ngIf="addEditAccount.controls.branch.hasError('required') && addEditAccount.controls.branch.touched"
                            style="margin-top: 0rem;">
                            Branch is required!
                        </small>
                    </div>




                </div>
            </div>
            <div class="modal-footer">
                <div class="col-sm-6">
                    <button *ngIf="isAddMode" type="submit" class="w-100 mt-2 btn btn-success" i18n="@@submit"
                        [disabled]="submitted || !addEditAccount.valid">Submit</button>
                    <button *ngIf="isEditMode" type="submit" class="w-100 mt-2 btn btn-success" i18n="@@update"
                        [disabled]="submitted || !addEditAccount.valid">Update</button>
                </div>

                <div class="col-sm-6">
                    <button type="reset" class="w-100 btn btn-danger mt-2" i18n="@@Close"
                        (click)="closeModal()">Close</button>
                </div>
            </div>
        </form>
    </div>

</div>