<div id="header_top" class="header_top">
    <div class="container">
        <div class="hleft">
            <!-- <a class="header-brand" href="index.html"><i class="fa fa-list brand-logo" aria-hidden="true"></i></a> -->
            <div class="dropdown">
                <!-- <a href="page-search.html" class="nav-link icon"><i class="fe fe-search" data-toggle="tooltip" data-placement="right" title="Search..."></i></a>
                <a href="app-email.html"  class="nav-link icon app_inbox"><i class="fe fe-inbox" data-toggle="tooltip" data-placement="right" title="Inbox"></i></a>
                <a href="app-filemanager.html"  class="nav-link icon app_file xs-hide"><i class="fe fe-folder" data-toggle="tooltip" data-placement="right" title="File Manager"></i></a>
                <a href="app-social.html"  class="nav-link icon xs-hide"><i class="fe fe-share-2" data-toggle="tooltip" data-placement="right" title="Social Media"></i></a>
                <a href="javascript:void(0)" class="nav-link icon theme_btn"><i class="fe fe-feather"></i></a>
                <a href="javascript:void(0)" class="nav-link icon settingbar"><i class="fe fe-settings"></i></a> -->
            </div>
        </div>
        <!-- <div class="hright">
            <a [routerLink]="" (click)="logout()" class="nav-link icon settingbar"><i class="fe fe-power"></i></a>                
            <a href="javascript:void(0)" class="nav-link icon menu_toggle"><i class="fe fe-align-center"></i></a>
        </div> -->
    </div>
</div>