import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RouterModule } from '@angular/router';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { HeaderComponent } from '../core/components/header/header.component';
import { FooterComponent } from '../core/components/footer/footer.component';
import { HeadertopComponent } from '../core/components/headertop/headertop.component';
import { SidenavComponent } from '../core/components/sidenav/sidenav.component';
import { PagesRoutingModule } from './pages-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProductComponent } from './product/product.component';
import { AddEditProductComponent } from './product/add-edit-product/add-edit-product.component';
import { BranchComponent } from './branch/branch.component';
import { AddEditBranchComponent } from './branch/add-edit-branch/add-edit-branch.component';
import { BusinessPartyComponent } from './business-party/business-party.component';
import { AddEditBusinessPartyComponent } from './business-party/add-edit-business-party/add-edit-business-party.component';
import { PartyPurchaseComponent } from './party-purchase/party-purchase.component';
import { AddEditPartyPurchaseComponent } from './party-purchase/add-edit-party-purchase/add-edit-party-purchase.component';
import { ViewPartyPurchaseInvoiceComponent } from './party-purchase/view-party-purchase-invoice/view-party-purchase-invoice.component';
import { ViewBusinessPartyTransactionsComponent } from './business-party/view-business-party-transactions/view-business-party-transactions.component';
import { AddPartyTransactionComponent } from './business-party/add-party-transaction/add-party-transaction.component';
import { CustomerComponent } from './customer/customer.component';
import { AddEditCustomerComponent } from './customer/add-edit-customer/add-edit-customer.component';
import { AddCustomerInvoiceComponent } from './customer/add-customer-invoice/add-customer-invoice.component';
import { CustomerInvoiceComponent } from './customer/customer-invoice/customer-invoice.component';
import { ViewCustomerInvoiceComponent } from './customer/customer-invoice/view-customer-invoice/view-customer-invoice.component';
import { ViewCustomerTransactionsComponent } from './customer/customer-invoice/view-customer-transactions/view-customer-transactions.component';
import { AddEditAccountComponent } from './account/add-edit-account/add-edit-account.component';
import { AccountComponent } from './account/account.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddCustomerTransactionComponent } from './customer/add-customer-transaction/add-customer-transaction.component';
import { CustomerInvoiceTransactionComponent } from './customer/customer-transaction/customer-invoice-transaction/customer-invoice-transaction.component';
import { PartyPurchaseTransactionComponent } from './business-party/business-party-transaction/party-purchase-transaction/party-purchase-transaction.component';
import { ViewPartyTransactionByIdComponent } from './business-party/view-party-transaction-by-id/view-party-transaction-by-id.component';
import { ViewCustomerInvoiceTransactionByIdComponent } from './customer/customer-transaction/view-customer-invoice-transaction-by-id/view-customer-invoice-transaction-by-id.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AddModifyPayableComponent } from './customer/add-modify-payable/add-modify-payable.component';
import { ViewProductPriceComponent } from './product/view-product-price/view-product-price.component';

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  HeadertopComponent,
  SidenavComponent,
  DashboardComponent,
  DashboardLayoutComponent,
  ProductComponent,
  AddEditProductComponent,
  BranchComponent,
  AddEditBranchComponent,
  BusinessPartyComponent, AddEditBusinessPartyComponent,
  PartyPurchaseComponent,
  AddEditPartyPurchaseComponent,
  ViewPartyPurchaseInvoiceComponent,
  ViewBusinessPartyTransactionsComponent,
  AddPartyTransactionComponent, CustomerComponent,
  AddEditCustomerComponent, AddCustomerInvoiceComponent,
  CustomerInvoiceComponent, ViewCustomerInvoiceComponent,
  ViewCustomerTransactionsComponent,
  AccountComponent, AddEditAccountComponent
];

const MODULES = [
  CommonModule,
  RouterModule,
  PagesRoutingModule,
  FormsModule,
  ReactiveFormsModule,
  NgxPaginationModule,
  NgMultiSelectDropDownModule.forRoot(),
];



@NgModule({
  declarations: [...COMPONENTS, AddCustomerTransactionComponent, CustomerInvoiceTransactionComponent, PartyPurchaseTransactionComponent, ViewPartyTransactionByIdComponent, ViewCustomerInvoiceTransactionByIdComponent, PageNotFoundComponent, AddModifyPayableComponent, ViewProductPriceComponent],
  imports: [...MODULES],
})
export class PagesModule { }
