import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PartyPurchase } from 'src/app/models/PartyPurchas';
import { ExportTableDataService } from 'src/app/services/export-table-data/export-table-data.service';
import { PartyPurchaseTransactionService } from 'src/app/services/party-purchase-transactions/party-purchase-transaction.service';
import { AddPartyTransactionComponent } from '../../add-party-transaction/add-party-transaction.component';
import { ViewPartyTransactionByIdComponent } from '../../view-party-transaction-by-id/view-party-transaction-by-id.component';

@Component({
  selector: 'app-party-purchase-transaction',
  templateUrl: './party-purchase-transaction.component.html',
  styleUrls: ['./party-purchase-transaction.component.scss']
})
export class PartyPurchaseTransactionComponent implements OnInit {

  public partyTransactionDetails: PartyPurchaseTransactionService[] = [];
  page: number = 1;
  noOfRecordsSizesArr = [10, 20, 30];
  pageNo = 0;
  pageSize = 10;
  totalCount = 0;
  public searchedValues: string;
  displayLoader: boolean = true;
  dispalyNotFoundData: boolean = false;

  constructor(private partyPurchaseTransactionService: PartyPurchaseTransactionService, private exportTableDataService: ExportTableDataService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }



  ngOnInit(): void {
    this.getAllPartyTransaction();

  }

  getAllPartyTransaction() {
    this.partyPurchaseTransactionService.searchPartyPurchaseTransactions(this.pageSize, this.pageNo).subscribe((customerTransactionDatas: any) => {
      const paginationDetails = customerTransactionDatas.pagination;
      this.page = paginationDetails.page + 1;
      this.totalCount = this.pageSize * paginationDetails.lastPage;
      this.partyTransactionDetails = customerTransactionDatas.purchaseTransactions;
      if (this.partyTransactionDetails?.length === 0) {
        this.displayLoader = false;
        this.dispalyNotFoundData = true;

      }

    }, (error) => {
      this.toastr.error(error.error.message || "Something went wrong!", 'Error', {
        progressBar: true,
      });
      this.displayLoader = false;
      this.dispalyNotFoundData = true;
    });
  }

  addPartyTransaction() {
    const partyTransactionRef = this.modalService.open(AddPartyTransactionComponent, { scrollable: true });
    partyTransactionRef.componentInstance.partyTransactionId = null
    partyTransactionRef.result.then(() => {
      this.getAllPartyTransaction();
    })

  }

  viewBusinessPartyTransactionById(id: string) {
    const viewBusinessPartyTransactionByIdRef = this.modalService.open(ViewPartyTransactionByIdComponent);
    viewBusinessPartyTransactionByIdRef.componentInstance.businessPartyTransactionId = id;
  }

  exportAsXLSX(): void {
    this.exportTableDataService.exportAsExcelFile(this.partyTransactionDetails, 'party-transaction-details');
  }

  getValueOfPartyTransaction(event: any) {
    this.searchedValues = event.target.value;
    if (this.searchedValues.length >= 2) {
      this.partyPurchaseTransactionService.searchPartyPurchaseTransactions(this.pageSize, this.pageNo, this.searchedValues).subscribe((data: any) => {
        const paginationDetails = data.pagination;
        this.page = paginationDetails.page + 1;
        this.totalCount = this.pageSize * paginationDetails.lastPage;
        this.partyTransactionDetails = data.purchaseTransactions;
        if (this.partyTransactionDetails?.length === 0) {
          this.displayLoader = false;
          this.dispalyNotFoundData = true;
        }
      })
    } else if (this.searchedValues == '') {
      this.getAllPartyTransaction();
    }
  }
  noOfRecordsSizeChange(): void {
    this.getAllPartyTransaction();
  }

  pageChange(event) {
    this.page = event;
    this.pageNo = this.page - 1;
    this.getAllPartyTransaction();
  }




}
