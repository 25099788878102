import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/models/Customer';
import { CustomerService } from 'src/app/services/customer/customer.service';

@Component({
  selector: 'app-add-modify-payable',
  templateUrl: './add-modify-payable.component.html',
  styleUrls: ['./add-modify-payable.component.scss']
})
export class AddModifyPayableComponent implements OnInit {

  @Input() payableCustomerId: string;
  submitted: boolean = false;
  loading: boolean = false;
  modifyPayableAmountForm: FormGroup;
  getAllCustomer: Customer[] = [];

  constructor(private activeModalService: NgbActiveModal,
    private customerService: CustomerService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.modifyPayableAmountForm = this.formBuilder.group({
      customer: new FormControl(this.payableCustomerId),
      amount: new FormControl('', [Validators.required]),
      action: new FormControl('debit', Validators.required),
    })
  }

  public async getAllCusotmerDetails(): Promise<void> {
    this.customerService.getAllCustomerDetails().subscribe((getCusomterDatas: any) => {
      this.getAllCustomer = getCusomterDatas;
    })
  }


  closeModal() {
    this.activeModalService.close();
  }

  onSubmit() {
    this.loading = true;
    this.customerService.modifyCustomerPayable(this.modifyPayableAmountForm.value).subscribe((_) => {
      this.toastrService.success('Successfully modified!', 'Success', {
        progressBar: true,
      });
      this.closeModal();
      this.loading = false;
    }, (error) => {
      this.toastrService.error(error.error.message || 'Something went wrong!', 'Error', {
      });
      this.loading = false
    })
  }


}
