import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerInvoice } from 'src/app/models/CusotmerInvoice';
import { Customer } from 'src/app/models/Customer';
import { CustomerInvoiceService } from 'src/app/services/customer-invoice.service';
import { CustomerService } from 'src/app/services/customer/customer.service';

@Component({
  selector: 'app-view-customer-invoice',
  templateUrl: './view-customer-invoice.component.html',
  styleUrls: ['./view-customer-invoice.component.scss']
})
export class ViewCustomerInvoiceComponent implements OnInit {
  id: string;
  public cusotmerInvoiceData: CustomerInvoice;
  public customerData: Customer;

  constructor(private toastr: ToastrService,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private customerInvoiceService: CustomerInvoiceService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getCustomerInvoiceById().then(null);
    this.getCustomerById().then(null);
  }

  public async getCustomerInvoiceById(): Promise<void> {
    this.customerInvoiceService.getCustomerInvoiceById(this.id).subscribe((customerInvoiceData: any) => {
      this.cusotmerInvoiceData = customerInvoiceData;
    }, (error) => {
      this.toastr.error('Error', error.error.message || 'Something went wronng!', {
        progressBar: true,
      });
    })
  }

  public async getCustomerById(): Promise<void> {
    this.customerService.getCustomerById(this.id).subscribe(() => {
    }, (error) => {
      this.toastr.error('Error', error.error.message || 'Something went wronng!', {
        progressBar: true,
      });
    })
  }

  printPage() {
    window.print();
  }
}
