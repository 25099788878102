import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerApis } from 'src/app/api.constants';
import { TransportService } from '../transport/transport.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private transportService: TransportService, private httpClient: HttpClient) { }

  getCustomerByPagination(pageSize: number, pageNumber: number) {
    return this.transportService.Read(`${ServerApis.customerUrl + `?size=${pageSize}&page=${pageNumber}`}`);

  }


  getAllCustomerDetails() {
    return this.transportService.Read(`${ServerApis.customersDetailsBasicListingUrl}`);
  }
  getCustomerById(id: string) {
    return this.transportService.Read(`${ServerApis.customerUrl}/${id}`);
  }

  createCustomer(req) {
    return this.transportService.Create(req, ServerApis.customerUrl);
  }

  modifyCustomerPayable(payableData) {
    return this.transportService.Create(payableData, ServerApis.modifyCustomerPayable)
  }

  updateCustomer(id: string, data) {
    return this.httpClient.patch(`${ServerApis.customerUrl}/${id}`, data);
  }



  deleteCustomer(id: string) {
    return this.transportService.Delete(`${ServerApis.customerUrl}/${id}`);
  }


  searchCustomerData(pageSize: number, pageNo: number, query: string = '', paymentMethod: string) {
    return this.transportService.Read(
      ServerApis.customerUrl +
      `?size=${pageSize}&page=${pageNo}&search=${query}&paymentStaus=${paymentMethod}`
    );
  }



}
