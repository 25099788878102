import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/auth/model/User';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  public saveUser(user: User): void {

    localStorage.setItem('loggedUser', JSON.stringify(user));
  }

  public getToken(): string | null {

    if (localStorage.getItem('loggedUser') !== null) {
      let user: User = JSON.parse(localStorage.getItem('loggedUser'))
      return user.token;
    }

    return null;
  }

  public getPrivileges(): string[] {

    if (localStorage.getItem('loggedUser') !== null) {
      let user: User = JSON.parse(localStorage.getItem('loggedUser'))
      // return user.privileges;
    }

    return [];
  }

  public getName(): string | null {

    if (localStorage.getItem('loggedUser') !== null) {
      let loggedInUser: User = JSON.parse(localStorage.getItem('loggedUser'))
      return loggedInUser.loggedInUserInfo;
    }

    return null;
  }

  public getUser(): User | null {

    if (localStorage.getItem('loggedUser') !== null) {
      let user: User = JSON.parse(localStorage.getItem('loggedUser'))
      return user;
    }

    return null;
  }

  public removeUser(): void {

    if (localStorage.getItem('loggedUser') !== null) {
      localStorage.removeItem('loggedUser');
    }

  }

  public remember(email: string) {
    if (localStorage.getItem('remember') !== null) {
      localStorage.removeItem('remember');
      localStorage.setItem('remember', email)
    } else {
      localStorage.setItem('remember', email)
    }
  }

  public getRemember(): string | null {
    return localStorage.getItem('remember');
  }

}
