<div class="modal-section">
    <div class="modal-header bg-custom">
        <h4 class="modal-title text-white"><i class="fa fa-user"></i>
            <span>
                Profile
            </span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="card-body">
            <div class="clearfix row">

                <div class="form-group col-sm-12 mb-4 text-center">
                    <img src="../../../assets/images/dummy-user.png" alt="" height="100" width="100">
                    <!-- <hr> -->

                </div>



                <div class="form-group col-sm-6 mb-3">
                    <label class="mr-1" i18n="@@name"><b>Full Name: </b></label>
                    <span>{{ this.loggedInUserDetail?.fullname ? this.loggedInUserDetail?.fullname : 'N/A' }}</span>
                </div>

                <div class="form-group col-sm-6 mb-3">
                    <label class="mr-1" i18n="@@name"><b>User Name: </b></label>
                    <span>{{ this.loggedInUserDetail?.username ? this.loggedInUserDetail?.username : 'N/A' }}</span>
                </div>

                <div class="form-group col-sm-6 mb-3">
                    <label class="mr-1" i18n="@@name"><b>Contact Number: </b></label>
                    <span>{{ this.loggedInUserDetail?.contactNumber ? this.loggedInUserDetail?.contactNumber : 'N/A'
                        }}</span>
                </div>

                <div class="form-group col-sm-6 mb-3">
                    <label class="mr-1" i18n="@@name"><b>Branch: </b></label>
                    <span>{{ this.loggedInUserDetail.branch?.code ? this.loggedInUserDetail.branch?.code : 'N/A'
                        }}
                        ({{ this.loggedInUserDetail.branch?.location ? this.loggedInUserDetail.branch?.location : '- - -
                        -' }})
                    </span>
                </div>
            </div>
            <div class="modal-footer">
                <div class="col-sm-12">
                    <button type="reset" class="w-100 btn btn-danger mt-2" i18n="@@Close"
                        (click)="closeModal()">Close</button>
                </div>
            </div>
        </div>

    </div>