import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { first } from 'rxjs/operators';
import { BusinessParty } from 'src/app/models/BusinessParty';
import { BusinessPartyService } from 'src/app/services/business-party/business-party.service';

@Component({
  selector: 'app-add-edit-business-party',
  templateUrl: './add-edit-business-party.component.html',
  styleUrls: ['./add-edit-business-party.component.scss']
})
export class AddEditBusinessPartyComponent implements OnInit {

  submitted: boolean = false;
  loading: boolean = false;
  @Input() isEditMode: boolean;
  @Input() isAddMode: boolean;
  addEditBusinessParty: FormGroup;
  totalRow: number;
  @Input() businessPartyId: string;
  public businessParty: BusinessParty[] = [];

  public codeErrorValidation = [
    {
      errType: 'required',
      errorMsg: 'Name is Required!'
    },
  ]

  public locationErrorValidation = [
    {
      errType: 'required',
      errorMsg: 'Location is Required!'
    },
    {
      errType: 'minlength',
      errorMsg: 'Location must be more than or equal to 5 characters long!'
    },
    {
      errType: 'maxlength',
      errorMsg: 'Location must be less than or equal to 30 characters long!'
    }
  ]

  constructor(
    private formBuilder: FormBuilder,
    private businessPartyService: BusinessPartyService,
    private activeModalService: NgbActiveModal,
    private toastr: ToastrService) {
  }


  ngOnInit(): void {
    this.getAllBusinessParty().then(null);
    this.addEditBusinessParty = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      contactPerson: new FormControl('', [Validators.required]),
      contactNumber: new FormControl('', [Validators.required]),
      location: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(30)]),
    })

    if (this.isEditMode === true) {
      this.businessPartyService
        .getBusinessPartyById(this.businessPartyId)
        .pipe(first())
        .subscribe((data: any) => {
          this.addEditBusinessParty.patchValue(data)
        }

        );

    }
  }

  public async getAllBusinessParty(): Promise<void> {
    this.businessPartyService.getBusinessPartyDetails().subscribe((businessPartService: any) => {
      this.businessParty = businessPartService;
    });
  }

  onSubmit() {
    this.loading = true;
    if (this.addEditBusinessParty.invalid) {
      return;
    }
    if (this.isAddMode === true) {
      this.createBusinessPart();
    } else if (this.isEditMode === true) {
      this.updateBusinessParty();
    } else {
      exit(0);
    }
  }

  private createBusinessPart() {
    this.businessPartyService.createBusinessParty(this.addEditBusinessParty.value).pipe(first()).subscribe(({
      next: (_) => {
        this.getAllBusinessParty().then(() => {
          this.toastr.success('Business Party created successfully!', 'Success', {
            progressBar: true,
          });
          this.closeModal();
          this.loading = false;
        })
      },
      error: (error) => {
        this.toastr.error(error.error.message || "Something went wrong!", 'Error', {
          progressBar: true,
        });
        this.loading = false;

      },
    }))
  }

  private updateBusinessParty() {
    this.businessPartyService
      .updateBusinessParty(this.businessPartyId, this.addEditBusinessParty.value)
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          this.toastr.success(res.message, 'Success', {
            progressBar: true,
          });
          this.getAllBusinessParty();
          this.closeModal();
          this.loading = false;

        },
        error: (error) => {
          this.toastr.error(error.error.message || "Something went wrong!", 'Error', {
            progressBar: true,
          });
          this.loading = false;

        },
      });

  }

  closeModal() {
    this.activeModalService.close();
  }


}
