<div class="section-body main-no-table">
  <div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center" id="breadcumb-section-part">
      <div class="header-action d-flex">
        <h1 class="page-title" i18n="@@productDetails">Products</h1>
        <ol class="breadcrumb page-breadcrumb mt-2">
          <li class="breadcrumb-item">
            <a [routerLink]="['/pages/dashboard']">( Dashboard</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page" i18n="@@productDetails">
            Product Details )
          </li>
        </ol>
      </div>

      <div class="search-and-btns d-flex">
        <ul class="nav nav-tabs page-header-tab" id="page-header-update">
          <div class="row">
            <div class="input-group border mt-2" id="search-bar">
              <div class="input-group-prepend">
                <button id="button-addon4" type="button" class="btn btn-link text-info border-0"><i
                    class="fa fa-search"></i></button>
              </div>
              <input type="search" placeholder="Search here" class="form-control bg-none border-0"
                (keyup)="getValue($event)">
            </div>
          </div>
        </ul>

        <div class="row export-and-add-btns">
          <button type="button" class="btn btn-md btn-primary mt-2 mr-1" (click)="exportAsXLSX()"><i
              class="fa fa-file-excel-o"></i> Export</button>

          <button type="button" class="btn btn-md btn-success mt-2" (click)="addProductDetail()"><i
              class="fa fa-plus-circle"></i> Add</button>
        </div>


      </div>

    </div>
  </div>

  <div class="container-fluid">
    <div class="tab-content">
      <div class="tab-pane active" id="productsDetails-all">
        <div class="table-responsive mt-2">
          <div class="table-responsive card">
            <table class="table  text-center text-nowrap mb-0">
              <thead class="thead-dark">
                <tr>
                  <th i18n="@@sn">S.N</th>
                  <th i18n="@@code">Code</th>
                  <th i18n="@@name">Name</th>
                  <th i18n="@@availableStock">Stock</th>
                  <th i18n="@@isVatApplicable">Is Vat Applicable</th>
                  <th i18n="@@action">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="productDetails.length == 0">
                  <td class="text-center" colspan="5" *ngIf="this.displayLoader">
                    <div class="spinner-grow text-secondary mt-3" role="status">
                    </div>
                    <div class="spinner-grow text-secondary mt-3" role="status">
                    </div>
                    <div class="spinner-grow text-secondary mt-3" role="status">
                    </div>
                    <div class="spinner-grow text-secondary mt-3" role="status">
                    </div>
                    <div class="mt-2">
                      <h6 scope="col gray-ish">Please Wait</h6>
                    </div>
                  </td>

                  <td class="text-muted text-center" colspan="6" *ngIf="this.dispalyNotFoundData">
                    No Product Details Found.
                  </td>
                </tr>
                <tr *ngFor="let productDetailsDatas of productDetails | paginate : {
                  itemsPerPage: pageSize,
                  currentPage: page,totalItems:totalCount
                  }; index as i">
                  <td>{{ (pageNo * pageSize) + i + 1 }}</td>
                  <td>{{ productDetailsDatas.code ? productDetailsDatas.code : '----' }}</td>
                  <td>{{ productDetailsDatas.name ? productDetailsDatas.name : '----' }}</td>
                  <td>{{ productDetailsDatas.id_stockInfo?.availableCount ?
                    productDetailsDatas.id_stockInfo.availableCount : '0' }}</td>
                  <td>
                    <span
                      class="badge badge-{{ productDetailsDatas.isVatApplicable === true ? 'success' : 'danger'}}">{{
                      productDetailsDatas.isVatApplicable === true ? 'YES' : 'NO'}}</span>
                  </td>
                  <td>
                    <button (click)="viewProductPrice(productDetailsDatas.id)" type="button"
                      class="btn btn-primary rounded-circle btn-sm mr-1">
                      <i class="fa fa-info-circle"></i>
                    </button>

                    <button (click)="editProductDetail(productDetailsDatas.id)" type="button"
                      class="btn btn-secondary rounded-circle btn-sm mr-1">
                      <i class="fa fa-edit"></i>
                    </button>
                    <button (click)="deleteProduct(productDetailsDatas.id)" type="button"
                      class="btn btn-danger rounded-circle btn-sm js-sweetalert" data-type="confirm">
                      <i class="fa fa-trash-o"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6" style="margin-right: auto;">
            <pagination-controls (pageChange)="pageChange($event)">
            </pagination-controls>
          </div>

          <div class="col-md-3" style="margin-left: auto;">
            <select (change)="noOfRecordsSizeChange()" class="form-control" [(ngModel)]="pageSize">
              <option *ngFor="let size of noOfRecordsSizesArr" [ngValue]="size">
                {{ size }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>