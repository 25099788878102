import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { ServerApis } from 'src/app/api.constants';
import { LoginResponse } from 'src/app/auth/model/LoginResponse';
import { User } from 'src/app/auth/model/User';
import { JwtTokenService } from '../jwt-token.service';
import { TokenStorageService } from '../token/token-storage.service';
import { TransportService } from '../transport/transport.service';

const AUTH_API = ServerApis.userLoginURL;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = new BehaviorSubject<User>(null);
  loggedInUserFirstName: string;
  loggedInUserRole: string;
  decodedTokenInfo: any;
  expirationDate: any;
  clearTimeOut: any;

  constructor(private transportService: TransportService,
    private storageService: TokenStorageService,
    private jwtTokenServcie: JwtTokenService,
    private toastr: ToastrService, private router: Router) { }


  login(username: string, password: string) {
    this.transportService.CreateRaw({ username, password }, AUTH_API).subscribe((res: LoginResponse) => {
      this.afterSuccessfullLogin(res);
    }, error => {
      if (error.status === 401) {
        this.toastr.error("Username/Password doesn't match!", "Invalid Credentials", {
          progressBar: true
        })
      } else if (error.status === 400) {
        this.toastr.error("Please fill required fields!", "Error", {
          progressBar: true
        })
      } else {
        this.toastr.error("An error occured, please try agian later!", "Error", {
          progressBar: true
        })
      }

    });
  }

  private afterSuccessfullLogin(data) {

    this.decodedTokenInfo = this.jwtTokenServcie.getDecodedAccessToken(data.access_token);
    this.loggedInUserFirstName = this.decodedTokenInfo.firstname;
    this.loggedInUserRole = this.decodedTokenInfo.role;
    let tokenExpirationDate = (this.decodedTokenInfo.exp) * 1000;
    this.expirationDate = new Date(tokenExpirationDate);
    const currentDate: any = new Date();

    const user = new User(data.access_token, this.loggedInUserFirstName, this.loggedInUserRole, this.expirationDate);
    this.user.next(user);
    this.storageService.saveUser(user);
    this.router.navigate(['/pages/dashboard']);
    this.autoLogout(this.expirationDate - currentDate)
  }

  public autoLogin() {
    const user = this.storageService.getUser();

    if (user == null) {
      return
    }
    this.user.next(user);
    let date = new Date().getTime();
    let expirationDateAfterAutoLogin = new Date(user.tokenExpirationDate).getTime();
    this.router.navigate(['/pages']);
    this.autoLogout(expirationDateAfterAutoLogin - date);

  }


  public autoLogout(expirationDateData: number) {
    this.clearTimeOut = setTimeout(() => {
      this.logout();
    }, expirationDateData)
  }

  public logout() {
    this.user.next(null);
    this.storageService.removeUser();
    if (this.clearTimeOut) {
      clearTimeout(this.clearTimeOut)
    }
    this.router.navigate(['/login']);
  }

  public loggedInUserDetails() {
    return this.transportService.Read(`${ServerApis.loggedInProfileUrl}`);
  }
}

